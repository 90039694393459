import "./SettingsIcon.scss";

import React from "react";
import { VscSettings } from "react-icons/vsc";

const SettingsIcon: React.FC = () => {
    return <VscSettings className="icon-settings" />;
};

export default SettingsIcon;
