import "./HomeIcon.scss";

import { HiHome } from "react-icons/hi2";

import classNames from "classnames";

interface IFolderIconProps {
    className?: string;
}

const HomeIcon = (props: IFolderIconProps) => {
    const className = classNames("folder-icon", props.className);

    return <HiHome className={className} />;
};

export default HomeIcon;
