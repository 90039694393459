import "./DecisionNodeIcon.scss";

import React from "react";

import Icon from "./assets/decision-node-icon.svg";

interface IProps {}

const DecisionNodeIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return <Icon />;
};

export default DecisionNodeIcon;
