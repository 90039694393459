import "./SortingIcon.scss";

import React from "react";
import { TbArrowsSort } from "react-icons/tb";

const SettingsIcon: React.FC = () => {
    return <TbArrowsSort className="icon-sorting" />;
};

export default SettingsIcon;
