import { ReactNode, RefObject, useEffect } from "react";

import { useDropdownToggle } from "react-overlays";

import classNames from "classnames";

import AccordionButton from "../../accordion-button/AccordionButton";
import DefaultMenuIcon from "./DefaultMenuIcon";

interface IProps {
    id?: string;
    testId?: string;
    disabled?: boolean;
    className?: string;

    wrapperRef?: RefObject<HTMLDivElement>;

    label?: ReactNode;
    icon?: ReactNode;
}

const Toggle = (props: IProps) => {
    const { id, testId, disabled, wrapperRef, label, icon } = props;

    const [toggleProps, { show, toggle }] = useDropdownToggle();

    const handleToggle = (e: any) => {
        toggle(!show, e);
    };

    useEffect(() => {
        const handleDocumentClick = (e: MouseEvent) => {
            if (!e.target || !wrapperRef?.current?.contains(e.target as Node)) {
                toggle(false);
            }
        };

        if (show && wrapperRef?.current) {
            document.addEventListener("click", handleDocumentClick, true);
        }

        return () => {
            document.removeEventListener("click", handleDocumentClick, true);
        };
    }, [show, wrapperRef]);

    const className = classNames(props.className, {
        "toggle--active": show,
        "toggle--disabled": disabled,
    });

    return (
        <div
            {...toggleProps}
            data-testid={testId + "-button"}
            className={className}
            onClick={disabled ? undefined : handleToggle}
        >
            {id && <input className="hidden" type="checkbox" id={id} />}

            {label}

            {icon ? (
                icon
            ) : label ? (
                <AccordionButton isOpen={show} />
            ) : (
                <DefaultMenuIcon />
            )}
        </div>
    );
};

export default Toggle;
