// prettier-ignore
// This rule was added to make future sorting easier by preventing line splitting

// DO NOT USE LINE BREAK FOR LONG LINES

/* Rules
    All Keys should only have capital letters
    Add _ at end of Key if a translation does not start with a capital letter
    Add _P if you need to distinguish plural form of translation
    Add _A if you need to distinguish action form of translation
    Add _S if the translation is a short version. Ex DAY_S_ for d instead of day
    Add _T if the translation have keys as <strong>
    Try to name Keys as close to the translation. If you change a translation or find a Key that doesn't match and you need it, rename the off Key
*/
export default {
    A_CHECKLIST_CANT_BE_UPGRADED_IF_IT_HAS: "A_CHECKLIST_CANT_BE_UPGRADED_IF_IT_HAS",
    A_FIXED_DATE: "A_FIXED_DATE",
    ABOVE_OR_EQUAL_TO: "ABOVE_OR_EQUAL_TO",
    ABOVE_OR_EQUAL: "ABOVE_OR_EQUAL",
    ABOVE: "ABOVE",
    ACCEPT_A_SINGLE_BARCODE: "ACCEPT_A_SINGLE_BARCODE",
    ACCEPT_MULTIPLE_BARCODES: "ACCEPT_MULTIPLE_BARCODES",
    ACCEPT_MULTIPLE_OPTIONS: "ACCEPT_MULTIPLE_OPTIONS",
    ACCEPT_SINGLE_OPTION: "ACCEPT_SINGLE_OPTION",
    ACCESS_LIMITING_DESCRIPTION: "ACCESS_LIMITING_DESCRIPTION",
    ACCESS_LIMITING: "ACCESS_LIMITING",
    ACT: "ACT",
    ACTING: "ACTING",
    ACTION_PLAN: "ACTION_PLAN",
    ACTION: "ACTION",
    ACTIONS: "ACTIONS",
    ACTIVATE_USER_LINE_1: "ACTIVATE_USER_LINE_1",
    ACTIVATE_USER_TITLE: "ACTIVATE_USER_TITLE",
    ACTIVATE: "ACTIVATE",
    ACTIVE_CHECKLISTS: "ACTIVE_CHECKLISTS",
    ACTIVE_L: "ACTIVE_L",
    ACTIVE: "ACTIVE",
    ACTIVITIES_: "ACTIVITIES_",
    ACTIVITIES_EXISTS_THAT_ARE_NOT_DONE: "ACTIVITIES_EXISTS_THAT_ARE_NOT_DONE",
    ACTIVITIES_REQUIRED: "ACTIVITIES_REQUIRED",
    ACTIVITIES_WERENT_COMPLETED: "ACTIVITIES_WERENT_COMPLETED",
    ACTIVITIES: "ACTIVITIES",
    ACTIVITY_HEADER: "ACTIVITY_HEADER",
    ACTIVITY_LABEL: "ACTIVITY_LABEL",
    ACTIVITY_MUST_BE_COMPLETED_TO_FINALIZE_STEP: "ACTIVITY_MUST_BE_COMPLETED_TO_FINALIZE_STEP",
    ACTIVITY: "ACTIVITY",
    ADD_ABSOLUTE_PROGRESS: "ADD_ABSOLUTE_PROGRESS",
    ADD_ACTIVITIES: "ADD_ACTIVITIES",
    ADD_ATTACHMENT: "ADD_ATTACHMENT",
    ADD_BARCODES_LABEL: "ADD_BARCODES_LABEL",
    ADD_CHECKBOX_LABEL: "ADD_CHECKBOX_LABEL",
    ADD_CHECKBOX: "ADD_CHECKBOX",
    ADD_COMMENT_TO_PEER: "ADD_COMMENT_TO_PEER",
    ADD_COMMENT: "ADD_COMMENT",
    ADD_COMPETENCY: "ADD_COMPETENCY",
    ADD_CONTACT: "ADD_CONTACT",
    ADD_CUSTOM_LIST: "ADD_CUSTOM_LIST",
    ADD_CUSTOMER_DROPDOWN_LABEL: "ADD_CUSTOMER_DROPDOWN_LABEL",
    ADD_CUSTOMER_DROPDOWN: "ADD_CUSTOMER_DROPDOWN",
    ADD_CUSTOMER_INDUSTRY: "ADD_CUSTOMER_INDUSTRY",
    ADD_CUSTOMER_REQUIREMENT: "ADD_CUSTOMER_REQUIREMENT",
    ADD_CUSTOMER: "ADD_CUSTOMER",
    ADD_DATA_SOURCE: "ADD_DATA_SOURCE",
    ADD_DATE_TIME: "ADD_DATE_TIME",
    ADD_DROPDOWN_LABEL: "ADD_DROPDOWN_LABEL",
    ADD_DROPDOWN: "ADD_DROPDOWN",
    ADD_ELEMENTS: "ADD_ELEMENTS",
    ADD_ENVIRONMENTAL_ASPECT: "ADD_ENVIRONMENTAL_ASPECT",
    ADD_EXISTING_GOAL: "ADD_EXISTING_GOAL",
    ADD_FROM_LIBRARY: "ADD_FROM_LIBRARY",
    ADD_GOAL: "ADD_GOAL",
    ADD_IMPROVEMENT_FORM: "ADD_IMPROVEMENT_FORM",
    ADD_INCREMENTAL_PROGRESS: "ADD_INCREMENTAL_PROGRESS",
    ADD_ITEM_DROPDOWN_LABEL: "ADD_ITEM_DROPDOWN_LABEL",
    ADD_ITEM_DROPDOWN: "ADD_ITEM_DROPDOWN",
    ADD_ITEM: "ADD_ITEM",
    ADD_KPI: "ADD_KPI",
    ADD_LEGISLATION: "ADD_LEGISLATION",
    ADD_LOCAL_STEP: "ADD_LOCAL_STEP",
    ADD_MEASUREMENT: "ADD_MEASUREMENT",
    ADD_MEASUREMENTS_TO_MEASURE_PROGRESS: "ADD_MEASUREMENTS_TO_MEASURE_PROGRESS",
    ADD_MULTI_LINE_TEXTFIELD_LABEL: "ADD_MULTI_LINE_TEXTFIELD_LABEL",
    ADD_MULTI_LINE_TEXTFIELD: "ADD_MULTI_LINE_TEXTFIELD",
    ADD_NEW_CUSTOM_LIST_DESCRIPTION: "ADD_NEW_CUSTOM_LIST_DESCRIPTION",
    ADD_NEW_CUSTOM_LIST: "ADD_NEW_CUSTOM_LIST",
    ADD_NEW_CUSTOMER_SUBTITLE: "ADD_NEW_CUSTOMER_SUBTITLE",
    ADD_NEW_CUSTOMER: "ADD_NEW_CUSTOMER",
    ADD_NEW_EQUIPMENT_SUBTITLE: "ADD_NEW_EQUIPMENT_SUBTITLE",
    ADD_NEW_EQUIPMENT: "ADD_NEW_EQUIPMENT",
    ADD_NEW_PROCESS: "ADD_NEW_PROCESS",
    ADD_NEW_PURCHASE_ORDER_SUBTITLE: "ADD_NEW_PURCHASE_ORDER_SUBTITLE",
    ADD_NEW_ROW: "ADD_NEW_ROW",
    ADD_NEW_SUPPLIER_SUBTITLE: "ADD_NEW_SUPPLIER_SUBTITLE",
    ADD_NEW_SUPPLIER: "ADD_NEW_SUPPLIER",
    ADD_NEW_TASK_SUBTITLE: `ADD_NEW_TASK_SUBTITLE`,
    ADD_NEW_TASK: `ADD_NEW_TASK`,
    ADD_NEW_TEAM_SUBTITLE: "ADD_NEW_TEAM_SUBTITLE",
    ADD_NEW_TEAM: "ADD_NEW_TEAM",
    ADD_NEW_USER_SUBTITLE: "ADD_NEW_USER_SUBTITLE",
    ADD_NEW_USER: "ADD_NEW_USER",
    ADD_NEW: "ADD_NEW",
    ADD_NOTES: "ADD_NOTES",
    ADD_PHOTO_LABEL: "ADD_PHOTO_LABEL",
    ADD_PHOTO: "ADD_PHOTO",
    ADD_POSITION: "ADD_POSITION",
    ADD_PROGRESS: "ADD_PROGRESS",
    ADD_PROPERTY: "ADD_PROPERTY",
    ADD_PURCHASE_ORDER: "ADD_PURCHASE_ORDER",
    ADD_RECURRING_CHECKLIST: "ADD_RECURRING_CHECKLIST",
    ADD_SHARED_STEP: "ADD_SHARED_STEP",
    ADD_STAKEHOLDER: "ADD_STAKEHOLDER",
    ADD_STATIC_ACTION_PLAN: "ADD_STATIC_ACTION_PLAN",
    ADD_STEP: "ADD_STEP",
    ADD_SUPPLIER_DROPDOWN_LABEL: "ADD_SUPPLIER_DROPDOWN_LABEL",
    ADD_SUPPLIER_DROPDOWN: "ADD_SUPPLIER_DROPDOWN",
    ADD_SUPPLIER: "ADD_SUPPLIER",
    ADD_TASK_LIST: "ADD_TASK_LIST",
    ADD_TASK: "ADD_TASK",
    ADD_TEAM: "ADD_TEAM",
    ADD_TEXTFIELD_LABEL: "ADD_TEXTFIELD_LABEL",
    ADD_TEXTFIELD: "ADD_TEXTFIELD",
    ADD_USER: "ADD_USER",
    ADD: "ADD",
    ADDED_AS_INVOLVED_TO: "ADDED_AS_INVOLVED_TO",
    ADDED: "ADDED",
    ADDRESS_BILING: "ADDRESS_BILING",
    ADDRESS_SHIP_VISIT: "ADDRESS_SHIP_VISIT",
    ADDRESS_SHIP: "ADDRESS_SHIP",
    ADDRESS_VISIT: "ADDRESS_VISIT",
    ADDRESS: "ADDRESS",
    ADMINISTRATOR: "ADMINISTRATOR",
    ADMINISTRATORS: "ADMINISTRATORS",
    ADVANCED_CONFIG: "ADVANCED_CONFIG",
    AFFECTED_PROCESS_STEPS: "AFFECTED_PROCESS_STEPS",
    AFFECTED_SUB_PROCESS: "AFFECTED_SUB_PROCESS",
    AFTER: "AFTER",
    AI_API_KEY_DESCRIPTION_PART1: "AI_API_KEY_DESCRIPTION",
    AI_FEATURE_DESCRIPTION_PART1: "AI_FEATURE_DESCRIPTION_PART1",
    AI_FEATURE_DESCRIPTION_PART2: "AI_FEATURE_DESCRIPTION_PART2",
    AI_FEATURES_BETA: "AI_FEATURES_BETA",
    AI_KEY: "AI_KEY",
    AI: "AI",
    AID_NEEDS: "AID_NEEDS",
    ALERT: "ALERT",
    ALL_: "ALL_",
    ALL_CATEGORIES: "ALL_CATEGORIES",
    ALL_IMPROVEMENTS_DESCRIPTION: "ALL_IMPROVEMENTS_DESCRIPTION",
    ALL_IMPROVEMENTS: "ALL_IMPROVEMENTS",
    ALL_USERS_IN_SYSTEM: "ALL_USERS_IN_SYSTEM",
    ALL: "ALL",
    ALLOW_CREATE_USER_ON_SSO_SIGNIN_IF_THE_USER_IS_MISSING_DESCRIPTION: "ALLOW_CREATE_USER_ON_SSO_SIGNIN_IF_THE_USER_IS_MISSING_DESCRIPTION",
    ALLOW_CREATE_USER_ON_SSO_SIGNIN_IF_THE_USER_IS_MISSING: "ALLOW_CREATE_USER_ON_SSO_SIGNIN_IF_THE_USER_IS_MISSING",
    ALLOW_MULTI_VALUE: "ALLOW_MULTI_VALUE",
    ALLOW_USERS_TO_ADD_AND_ASSIGN_OWN_TASKS_IN_RUNNING_CHECKLISTS: "ALLOW_USERS_TO_ADD_AND_ASSIGN_OWN_TASKS_IN_RUNNING_CHECKLISTS",
    AMOUNT: "AMOUNT",
    AN_ERROR_OCCURED: "AN_ERROR_OCCURED",
    ANALYSING: "ANALYSING",
    ANALYSIS: "ANALYSIS",
    ANALYZE: "ANALYZE",
    AND_: "AND_",
    AND_REPEAT_IT_ON_THE_SAME_WEEKDAY_EVERY: "AND_REPEAT_IT_ON_THE_SAME_WEEKDAY_EVERY",
    AND: "AND",
    ANOTHER_KPI: "ANOTHER_KPI",
    ANSWERS: "ANSWERS",
    API_ENDPOINTS: "API_ENDPOINTS",
    API_KEY_CHECKLIST: "API_KEY_CHECKLIST",
    API_KEY: "API_KEY",
    API_KEYS_DESCRIPTION: "API_KEYS_DESCRIPTION",
    API_KEYS_SUBTITLE: "API_KEYS_SUBTITLE",
    API_KEYS: "API_KEYS",
    APPLICABLE_TO_OUR_ORGANIZATION: "APPLICABLE_TO_OUR_ORGANIZATION",
    APPLY: "APPLY",
    APPRAISING_MANAGER: "APPRAISING_MANAGER",
    APR: "APR",
    APRIL: "APRIL",
    ARCHIVE_IMPACT_GRADING_1: "ARCHIVE_IMPACT_GRADING_1",
    ARCHIVE_IMPACT_GRADING_2: "ARCHIVE_IMPACT_GRADING_2",
    ARCHIVE_IMPACT_GRADING_3: "ARCHIVE_IMPACT_GRADING_3",
    ARCHIVE_MESSAGE_GENERIC_1: "ARCHIVE_EQUIPMENT_MESSAGE_1",
    ARCHIVE_MESSAGE_GENERIC_2: "ARCHIVE_EQUIPMENT_MESSAGE_2",
    ARCHIVE_POSITION_1: "ARCHIVE_POSITION_1",
    ARCHIVE_POSITION_2: "ARCHIVE_POSITION_2",
    ARCHIVE_POSITION_3: "ARCHIVE_POSITION_3",
    ARCHIVE_PURCHASE_ORDER_MESSAGE_1: "ARCHIVE_PURCHASE_ORDER_MESSAGE_1",
    ARCHIVE_PURCHASE_ORDER_MESSAGE_2: "ARCHIVE_PURCHASE_ORDER_MESSAGE_2",
    ARCHIVE_PURCHASE_ORDER_TITLE: "ARCHIVE_PURCHASE_ORDER_TITLE",
    ARCHIVE_THIS_AREA: "ARCHIVE_THIS_AREA",
    ARCHIVE_THIS_GOAL: "ARCHIVE_THIS_GOAL",
    ARCHIVE_THIS_KPI_LINE_1: "ARCHIVE_THIS_KPI_LINE_1",
    ARCHIVE_THIS_KPI_LINE_2: "ARCHIVE_THIS_KPI_LINE_2",
    ARCHIVE_THIS_KPI: "ARCHIVE_THIS_KPI",
    ARCHIVE_THIS_MEASUREMENT: "ARCHIVE_THIS_MEASUREMENT",
    ARCHIVE_THIS_POSITION: "ARCHIVE_THIS_POSITION",
    ARCHIVE_THIS_PROGRESS_VALUE: "ARCHIVE_THIS_PROGRESS_VALUE",
    ARCHIVE_THIS_TEAM: "ARCHIVE_THIS_TEAM",
    ARCHIVE: "ARCHIVE",
    ARCHIVED_: "ARCHIVED_",
    ARCHIVED_AT: "ARCHIVED_AT",
    ARCHIVED_BY: "ARCHIVED_BY",
    ARCHIVED_CUSTOM_LISTS: "ARCHIVED_CUSTOM_LISTS",
    ARCHIVED_P: "ARCHIVED_P",
    ARCHIVED_POSITION: "ARCHIVED_POSITION",
    ARCHIVED: "ARCHIVED",
    ARE_: "ARE_",
    ARE_YOU_SURE_ARCHIVE_IMPROVEMENT_FORM_CONTENT: "ARE_YOU_SURE_ARCHIVE_IMPROVEMENT_FORM_CONTENT",
    ARE_YOU_SURE_ARCHIVE_IMPROVEMENT_FORM_INFO: "ARE_YOU_SURE_ARCHIVE_IMPROVEMENT_FORM_INFO",
    ARE_YOU_SURE_ARCHIVE_IMPROVEMENT_FORM: "ARE_YOU_SURE_ARCHIVE_IMPROVEMENT_FORM",
    ARE_YOU_SURE_ARCHIVE_RUN_CONTENT: "ARE_YOU_SURE_ARCHIVE_RUN_CONTENT",
    ARE_YOU_SURE_ARCHIVE_RUN_INFO: "ARE_YOU_SURE_ARCHIVE_RUN_INFO",
    ARE_YOU_SURE_ARCHIVE_RUN: "ARE_YOU_SURE_ARCHIVE_RUN",
    ARE_YOU_SURE_ARCHIVE_SCHEDULE_CONTENT: "ARE_YOU_SURE_ARCHIVE_SCHEDULE_CONTENT",
    ARE_YOU_SURE_ARCHIVE_SCHEDULE_INFO: "ARE_YOU_SURE_ARCHIVE_SCHEDULE_INFO",
    ARE_YOU_SURE_ARCHIVE_SCHEDULE: "ARE_YOU_SURE_ARCHIVE_SCHEDULE",
    ARE_YOU_SURE_ARCHIVE_TEMPLATE_CONTENT: "ARE_YOU_SURE_ARCHIVE_TEMPLATE_CONTENT",
    ARE_YOU_SURE_ARCHIVE_TEMPLATE_INFO: "ARE_YOU_SURE_ARCHIVE_TEMPLATE_INFO",
    ARE_YOU_SURE_ARCHIVE_TEMPLATE: "ARE_YOU_SURE_ARCHIVE_TEMPLATE",
    ARE_YOU_SURE_RESET: "ARE_YOU_SURE_RESET",
    ARE_YOU_SURE_RESUME_RUN_CONTENT: "ARE_YOU_SURE_RESUME_RUN_CONTENT",
    ARE_YOU_SURE_RESUME_RUN_INFO: "ARE_YOU_SURE_RESUME_RUN_INFO",
    ARE_YOU_SURE_RESUME_RUN: "ARE_YOU_SURE_RESUME_RUN",
    ARE_YOU_SURE_RESUME_SCHEDULE_CONTENT: "ARE_YOU_SURE_RESUME_SCHEDULE_CONTENT",
    ARE_YOU_SURE_RESUME_SCHEDULE_INFO: "ARE_YOU_SURE_RESUME_SCHEDULE_INFO",
    ARE_YOU_SURE_RESUME_SCHEDULE: "ARE_YOU_SURE_RESUME_SCHEDULE",
    ARE_YOU_SURE_STOP_RUN_CONTENT: "ARE_YOU_SURE_STOP_RUN_CONTENT",
    ARE_YOU_SURE_STOP_RUN_INFO: "ARE_YOU_SURE_STOP_RUN_INFO",
    ARE_YOU_SURE_STOP_RUN: "ARE_YOU_SURE_STOP_RUN",
    ARE_YOU_SURE_STOP_SCHEDULE_CONTENT: "ARE_YOU_SURE_STOP_SCHEDULE_CONTENT",
    ARE_YOU_SURE_STOP_SCHEDULE_INFO: "ARE_YOU_SURE_STOP_SCHEDULE_INFO",
    ARE_YOU_SURE_STOP_SCHEDULE: "ARE_YOU_SURE_STOP_SCHEDULE",
    ARE_YOU_SURE_UN_ARCHIVE_IMPROVEMENT_FORM_CONTENT: "ARE_YOU_SURE_UN_ARCHIVE_IMPROVEMENT_FORM_CONTENT",
    ARE_YOU_SURE_UN_ARCHIVE_IMPROVEMENT_FORM_INFO: "ARE_YOU_SURE_UN_ARCHIVE_IMPROVEMENT_FORM_INFO",
    ARE_YOU_SURE_UN_ARCHIVE_IMPROVEMENT_FORM: "ARE_YOU_SURE_UN_ARCHIVE_IMPROVEMENT_FORM",
    ARE_YOU_SURE_UN_ARCHIVE_RUN_CONTENT: "ARE_YOU_SURE_UN_ARCHIVE_RUN_CONTENT",
    ARE_YOU_SURE_UN_ARCHIVE_RUN_INFO: "ARE_YOU_SURE_UN_ARCHIVE_RUN_INFO",
    ARE_YOU_SURE_UN_ARCHIVE_RUN: "ARE_YOU_SURE_UN_ARCHIVE_RUN",
    ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE_CONTENT: "ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE_CONTENT",
    ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE_INFO: "ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE_INFO",
    ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE: "ARE_YOU_SURE_UN_ARCHIVE_SCHEDULE",
    ARE_YOU_SURE_UN_ARCHIVE_TEMPLATE_CONTENT: "ARE_YOU_SURE_UN_ARCHIVE_TEMPLATE_CONTENT",
    ARE_YOU_SURE_UN_ARCHIVE_TEMPLATE_INFO: "ARE_YOU_SURE_UN_ARCHIVE_TEMPLATE_INFO",
    ARE_YOU_SURE_UN_ARCHIVE_TEMPLATE: "ARE_YOU_SURE_UN_ARCHIVE_TEMPLATE",
    ARE_YOU_SURE_UNSAVED_CHANGES_INFO: "ARE_YOU_SURE_UNSAVED_CHANGES_INFO",
    ARE_YOU_SURE_UNSAVED_CHANGES: "ARE_YOU_SURE_UNSAVED_CHANGES",
    ARE_YOU_SURE_YOU_WANT_TO_ARCHIVE_THIS_EQUIPMENT: "ARE_YOU_SURE_YOU_WANT_TO_ARCHIVE_THIS_EQUIPMENT",
    ARE_YOU_SURE_YOU_WANT_TO_ARCHIVE_THIS_TEAM: "ARE_YOU_SURE_YOU_WANT_TO_ARCHIVE_THIS_TEAM",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_CHECKLIST: "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_CHECKLIST",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_IMPROVEMENT: "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_IMPROVEMENT",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_RECURRING_CHECKLIST: "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_RECURRING_CHECKLIST",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TEMPLATE: "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THE_TEMPLATE",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_FOLDER: "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_FOLDER",
    ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PAGE: "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_PAGE",
    ARE_YOU_SURE_YOU_WANT_TO_DISABLE_THIS_FEATURE: "ARE_YOU_SURE_YOU_WANT_TO_DISABLE_THIS_FEATURE",
    ARE_YOU_SURE_YOU_WANT_TO_DISABLE: "ARE_YOU_SURE_YOU_WANT_TO_DISABLE",
    ARE_YOU_SURE_YOU_WANT_TO_ENABLE_THIS_FEATURE: "ARE_YOU_SURE_YOU_WANT_TO_ENABLE_THIS_FEATURE",
    ARE_YOU_SURE_YOU_WANT_TO_LEAVE: "ARE_YOU_SURE_YOU_WANT_TO_LEAVE",
    ARE_YOU_SURE_YOU_WANT_TO_PROCEED: "ARE_YOU_SURE_YOU_WANT_TO_PROCEED",
    ARE_YOU_SURE: "ARE_YOU_SURE",
    AREA_WILL_BE_REMOVED: "AREA_WILL_BE_REMOVED",
    AS_: "AS_",
    ASSIGN_IMPACTED_PROCESS_TO_IMPROVEMENT_FEATURE_DESCRIPTION: "ASSIGN_IMPACTED_PROCESS_TO_IMPROVEMENT_FEATURE_DESCRIPTION",
    ASSIGN_IMPACTED_PROCESS_TO_IMPROVEMENT: "ASSIGN_IMPACTED_PROCESS_TO_IMPROVEMENT",
    ASSIGNED_TO: "ASSIGNED_TO",
    ASSIGNED: "ASSIGNED",
    ASSIGNEE: "ASSIGNEE",
    ASSIGNMENT: "ASSIGNMENT",
    AT_A_GLANCE: "AT_A_GLANCE",
    AT_LEAST_ONE_ACTIVITY_IS_REQUIRED: "AT_LEAST_ONE_ACTIVITY_IS_REQUIRED",
    AT_LEAST_ONE_INVOLVED_USER_REQUIRED_IN_ADDITIONAL_INVOLVED_USERS_CONFIGURATION: "AT_LEAST_ONE_INVOLVED_USER_REQUIRED_IN_ADDITIONAL_INVOLVED_USERS_CONFIGURATION",
    AT_LEAST_ONE_PROP_VISIBLE: "AT_LEAST_ONE_PROP_VISIBLE",
    AT_LEAST_ONE_RESPONSIBLE_USER_TYPE_REQUIRED: "AT_LEAST_ONE_RESPONSIBLE_USER_TYPE_REQUIRED",
    AT_LEAST_ONE_TASK_IS_REQUIRED: "AT_LEAST_ONE_TASK_IS_REQUIRED",
    AT_RISK: "AT_RISK",
    ATTACHMENTS: "ATTACHMENTS",
    AUG: "AUG",
    AUGUST: "AUGUST",
    AVAILABLE_USERS: "AVAILABLE_USERS",
    BACK_TO_CHECKLISTS: "BACK_TO_CHECKLISTS",
    BACK_TO_CUSTOMER_LIST: "BACK_TO_CUSTOMER_LIST",
    BACK_TO_EQUIPMENT_LIST: "BACK_TO_EQUIPMENT_LIST",
    BACK_TO_PREVIOUS_PAGE: "BACK_TO_PREVIOUS_PAGE",
    BACK_TO_PURCHASE_ORDER_LIST: "BACK_TO_PURCHASE_ORDER_LIST",
    BACK_TO_SUPPLIER_LIST: "BACK_TO_SUPPLIER_LIST",
    BACK_TO_TEAM_LIST: "BACK_TO_TEAM_LIST",
    BACK_TO_USER_LIST: "BACK_TO_USER_LIST",
    BACK: "BACK",
    BARCODE_ACTIVITY_DESCRIPTION: "BARCODE_ACTIVITY_DESCRIPTION",
    BARCODE_ACTIVITY: "BARCODE_ACTIVITY",
    BARCODE: "BARCODE",
    BARCODES: "BARCODES",
    BEHIND_: "BEHIND_",
    BEHIND: "BEHIND",
    BELOW_IS_A_CALENDAR_FOR_THE_SCHEDULE: "BELOW_IS_A_CALENDAR_FOR_THE_SCHEDULE",
    BELOW_OR_EQUAL_TO: "BELOW_OR_EQUAL_TO",
    BELOW_OR_EQUAL: "BELOW_OR_EQUAL",
    BELOW: "BELOW",
    BETA: "BETA",
    BETWEEN: "BETWEEN",
    BILLING_COUNTRY: "BILLING_COUNTRY",
    BILLING_STATE_OR_PROVINCE: "BILLING_STATE_OR_PROVINCE",
    BIO: "BIO",
    BLOCK_FOLLOWING_STEPS_TOOLTIP: "BLOCK_FOLLOWING_STEPS_TOOLTIP",
    BLOCK_FOLLOWING_STEPS: "BLOCK_FOLLOWING_STEPS",
    BLOCKED_: "BLOCKED_",
    BLOCKED_P: "BLOCKED_P",
    BLOCKED: "BLOCKED",
    BLOCKING_STEPS: "BLOCKING_STEPS",
    BLOCKING: "BLOCKING",
    BOTH: "BOTH",
    BOUGHT_IN: "BOUGHT_IN",
    BROWSE_AND_IMPORT_CLOUD_TEMPLATES: "BROWSE_AND_IMPORT_CLOUD_TEMPLATES",
    BULK_IMPORT_FEATURE_DESCRIPTION: "BULK_IMPORT_FEATURE_DESCRIPTION",
    BUSINESS_NAME: "BUSINESS_NAME",
    BY_: "BY_",
    CAN_NOT_ARCHIVE: "CAN_NOT_ARCHIVE",
    CAN_VIEW: "CAN_VIEW",
    CANCEL: "CANCEL",
    CANNOT_ARCHIVE_TEAM_MESSAGE_RUNS_FIRST: "CANNOT_ARCHIVE_TEAM_MESSAGE_RUNS_FIRST",
    CANNOT_ARCHIVE_TEAM_MESSAGE_RUNS_SECOND: "CANNOT_ARCHIVE_TEAM_MESSAGE_RUNS_SECOND",
    CANNOT_ARCHIVE_TEAM_MESSAGE_STEPS_FIRST: "CANNOT_ARCHIVE_TEAM_MESSAGE_STEPS_FIRST",
    CANNOT_ARCHIVE_TEAM_MESSAGE_STEPS_SECOND: "CANNOT_ARCHIVE_TEAM_MESSAGE_STEPS_SECOND",
    CANNOT_ARCHIVE_TEAM_MESSAGE_TEMPLATES_FIRST: "CANNOT_ARCHIVE_TEAM_MESSAGE_TEMPLATES_FIRST",
    CANNOT_ARCHIVE_TEAM_MESSAGE_TEMPLATES_SECOND: "CANNOT_ARCHIVE_TEAM_MESSAGE_TEMPLATES_SECOND",
    CANNOT_ASSIGN_ARCHIVED_USER_POSITION: "CANNOT_ASSIGN_ARCHIVED_USER_POSITION",
    CANNOT_DELETE_THE_POSITION: "CANNOT_DELETE_THE_POSITION",
    CANNOT_DELETE_THE_TEAM: "CANNOT_DELETE_THE_TEAM",
    CANNOT_EDIT_ARCHIVED_USER_POSITION: "CANNOT_EDIT_ARCHIVED_USER_POSITION",
    CANNOT_UNARCHIVE_CHECKLIST_SCHEDULE_ARCHIVED: "CANNOT_UNARCHIVE_CHECKLIST_SCHEDULE_ARCHIVED",
    CANNOT_UNARCHIVE_CHECKLIST_TEMPLATE_ARCHIVED: "CANNOT_UNARCHIVE_CHECKLIST_TEMPLATE_ARCHIVED",
    CANT_BE_UPGRADED: "CANT_BE_UPGRADED",
    CAPTURE_PHOTO: "CAPTURE_PHOTO",
    CARDS_VIEW: "CARDS_VIEW",
    CATEGORY: "CATEGORY",
    CHANGE_END_DATE_FOR_THE_SCHEDULE: "CHANGE_END_DATE_FOR_THE_SCHEDULE",
    CHANGE_TENANT_NAME_MESSAGE: "CHANGE_TENANT_NAME_MESSAGE",
    CHANGE_TENANT_NAME: "CHANGE_TENANT_NAME",
    CHANGED_TEAMS_IN_STEPS: "CHANGED_TEAMS_IN_STEPS",
    CHANGED: "CHANGED",
    CHANGES_APPLY_AFTER_SAVE: "CHANGES_APPLY_AFTER_SAVE",
    CHANGING_SUPPLIER_CONFIRM_MESSAGE: "CHANGING_SUPPLIER_CONFIRM_MESSAGE",
    CHECKBOX: "CHECKBOX",
    CHECKLIST_: "CHECKLIST_",
    CHECKLIST_ARCHIVED: "CHECKLIST_ARCHIVED",
    CHECKLIST_DELETE_DESCRIPTION: "CHECKLIST_DELETE_DESCRIPTION",
    CHECKLIST_DETAILS: "CHECKLIST_DETAILS",
    CHECKLIST_DURATION: "CHECKLIST_DURATION",
    CHECKLIST_HAS_BEEN_MODIFIED_AND_DOES_NOT_MATCH_SCHEDULE: "CHECKLIST_HAS_BEEN_MODIFIED_AND_DOES_NOT_MATCH_SCHEDULE",
    CHECKLIST_PAUSED: "CHECKLIST_PAUSED",
    CHECKLIST_PREVIEW: "CHECKLIST_PREVIEW",
    CHECKLIST_REPORT_FEATURE_DESCRIPTION: "CHECKLIST_REPORT_FEATURE_DESCRIPTION",
    CHECKLIST_REPORT_OWNER_INFO: 'CHECKLIST_REPORT_OWNER_INFO',
    CHECKLIST_REPORT_OWNER: 'CHECKLIST_REPORT_OWNER',
    CHECKLIST_REPORT_SUBTITLE: "SHOWING_RUNS_AND_REPORTED_VALUES_MATCHING_YOUR_QUERY",
    CHECKLIST_REPORT_VIEWER_INFO: 'CHECKLIST_REPORT_VIEWER_INFO',
    CHECKLIST_REPORT_VIEWER: 'CHECKLIST_REPORT_VIEWER',
    CHECKLIST_RESUMED: "CHECKLIST_RESUMED",
    CHECKLIST_STARTED: "CHECKLIST_STARTED",
    CHECKLIST_STEPS: "CHECKLIST_STEPS",
    CHECKLIST_STOPPED: "CHECKLIST_STOPPED",
    CHECKLIST_TEMPLATE: "CHECKLIST_TEMPLATE",
    CHECKLIST_TEMPLATES: "CHECKLIST_TEMPLATES",
    CHECKLIST_TIMELINE_VIEW_PER_TEMPLATE: "CHECKLIST_TIMELINE_VIEW_PER_TEMPLATE",
    CHECKLIST_TIMELINE_VIEW: "CHECKLIST_TIMELINE_VIEW",
    CHECKLIST_UNARCHIVED: "CHECKLIST_UNARCHIVED",
    CHECKLIST_UNPAUSED: "CHECKLIST_UNPAUSED",
    CHECKLIST: "CHECKLIST",
    CHECKLISTS_: "CHECKLISTS_",
    CHECKLISTS_REPORT_TIMELINE_VIEW_FEATURE_DESCRIPTION: "CHECKLISTS_REPORT_TIMELINE_VIEW_FEATURE_DESCRIPTION",
    CHECKLISTS_RUN: "CHECKLISTS_RUN",
    CHECKLISTS_SEARCH_SUBTITLE: "CHECKLISTS_SEARCH_SUBTITLE",
    CHECKLISTS_SEARCH: "CHECKLISTS_SEARCH",
    CHECKLISTS_SUBTITLE: "CHECKLISTS_SUBTITLE",
    CHECKLISTS_WILL_BE_UPGRADED_AND: "CHECKLISTS_WILL_BE_UPGRADED_AND",
    CHECKLISTS: "CHECKLISTS",
    CHOOSE_IMPACTED_AREAS: "CHOOSE_IMPACTED_AREAS",
    CHOOSE_IMPACTED_PROCESSES: "CHOOSE_IMPACTED_PROCESSES",
    CHOOSE_REASON_FOR_CLOSURE: "CHOOSE_REASON_FOR_CLOSURE",
    CHOOSE: "CHOOSE",
    CITY: "CITY",
    CLEAR: "CLEAR",
    CLICK_HERE: "CLICK_HERE",
    CLICK_THE_BUTTON_BELLOW_TO_MARK_THIS_STEP_AS_COMPLETED: "CLICK_THE_BUTTON_BELLOW_TO_MARK_THIS_STEP_AS_COMPLETED",
    CLICK_TO_ADD_LABEL: "CLICK_TO_ADD_LABEL",
    CLICK_TO_PREVIEW: "CLICK_TO_PREVIEW",
    CLICK_TO_SHOW_MORE: "CLICK_TO_SHOW_MORE",
    CLONE_MESSAGE: "CLONE_MESSAGE",
    CLOSE_IMPROVEMENT: "CLOSE_IMPROVEMENT",
    CLOSE_POSITION_BEFORE_SAVE: "CLOSE_POSITION_BEFORE_SAVE",
    CLOSE_WITH_SELECTED_REASON: "CLOSE_WITH_SELECTED_REASON",
    CLOSE: "CLOSE",
    CLOSED: "CLOSED",
    CLOSEDs: "CLOSEDs",
    CLOSURE_REASON_REQUIRED: "CLOSURE_REASON_REQUIRED",
    CLOSURE_REASON: "CLOSURE_REASON",
    CLOSURE: "CLOSURE",
    CLOUD_LIBRARY_FEATURE_DESCRIPTION: "CLOUD_LIBRARY_FEATURE_DESCRIPTION",
    CLOUD_LIBRARY: "CLOUD_LIBRARY",
    CLOUD_TEMPLATE_LIBRARY: "CLOUD_TEMPLATE_LIBRARY",
    CM_CREATED: "CM_CREATED",
    CM_EDITOR_INFO: "CM_EDITOR_INFO",
    CM_EDITOR: "CM_EDITOR",
    CM_OWNER_INFO: "CM_OWNER_INFO",
    CM_OWNER: "CM_OWNER",
    CM_USER_INFO: "CM_USER_INFO",
    CM_USER: "CM_USER",
    CM_VIEWER_INFO: "CM_VIEWER_INFO",
    CM_VIEWER: "CM_VIEWER",
    COGNIT_GPT_API_KEY_IS_ACTIVE: "COGNIT_GPT_API_KEY_IS_ACTIVE",
    COGNIT_GPT_PAGE_TITLE: "COGNIT_GPT_PAGE_TITLE",
    COLOR: "COLOR",
    COMES_IN: "COMES_IN",
    COMMENT_LEFT_BY: "COMMENT_LEFT_BY",
    COMMENT: "COMMENT",
    COMMENTS_PROBABILITY: "COMMENTS_PROBABILITY",
    COMMENTS: "COMMENTS",
    COMPANY_INDUSTRY_DESCRIPTION: "COMPANY_INDUSTRY_DESCRIPTION",
    COMPANY_INDUSTRY_EXAMPLE: "COMPANY_INDUSTRY_EXAMPLE",
    COMPANY_INDUSTRY: "COMPANY_INDUSTRY",
    COMPANY_NAME_DESCRIPTION: "COMPANY_NAME_DESCRIPTION",
    COMPANY_NAME_EXAMPLE: "COMPANY_NAME_EXAMPLE",
    COMPANY_NAME: "COMPANY_NAME",
    COMPANY_OVERVIEW_DESCRIPTION: "COMPANY_OVERVIEW_DESCRIPTION",
    COMPANY_OVERVIEW_EXAMPLE: "ABOUT_COMPANY_EXAMPLE",
    COMPANY_OVERVIEW: "COMPANY_OVERVIEW",
    COMPETENCIES_EDITOR_INFO: "COMPETENCIES_EDITOR_INFO",
    COMPETENCIES_EDITOR: "COMPETENCIES_EDITOR",
    COMPETENCIES_FEATURE_DESCRIPTION: "COMPETENCIES_FEATURE_DESCRIPTION",
    COMPETENCIES_OWNER_INFO: "COMPETENCIES_OWNER_INFO",
    COMPETENCIES_OWNER: "COMPETENCIES_OWNER",
    COMPETENCIES_VIEWER_INFO: "COMPETENCIES_VIEWER_INFO",
    COMPETENCIES_VIEWER: "COMPETENCIES_VIEWER",
    COMPETENCIES: "COMPETENCIES",
    COMPETENCY_MATRIX_FEATURE_DESCRIPTION: "COMPETENCY_MATRIX_FEATURE_DESCRIPTION",
    COMPETENCY_MATRIX_PAGE_SUBTITLE: "COMPETENCY_MATRIX_PAGE_SUBTITLE",
    COMPETENCY_MATRIX: "COMPETENCY_MATRIX",
    COMPETENCY_PAGE_TITLE: "COMPETENCY_PAGE_TITLE",
    COMPETENCY_POSITION: "COMPETENCY_POSITION",
    COMPETENCY_TEAM: "COMPETENCY_TEAM",
    COMPETENCY: "COMPETENCY",
    COMPLETE_THE_ACTIVITIES: "COMPLETE_THE_ACTIVITIES",
    COMPLETE_THE: "COMPLETE_THE",
    COMPLETED_: "COMPLETED_",
    COMPLETED_A_: "COMPLETED_A_",
    COMPLETED_A: "COMPLETED_A",
    COMPLETED_ACTIVITIES: "COMPLETED_ACTIVITIES",
    COMPLETED_AT: "COMPLETED_AT",
    COMPLETED_BY: "COMPLETED_BY",
    COMPLETED_COURSES: "COMPLETED_COURSES",
    COMPLETED_P_: "COMPLETED_P_",
    COMPLETED_P: "COMPLETED_P",
    COMPLETED: "COMPLETED",
    CONFIGURATION: "CONFIGURATION",
    CONFIGURE_THIS_LIST: "CONFIGURE_THIS_LIST",
    CONFIRM_FINALIZE: "CONFIRM_FINALIZE",
    CONFIRM_NEW_PASSWORD: "CONFIRM_NEW_PASSWORD",
    CONFIRM: "CONFIRM",
    CONTACT_DETAILS: "CONTACT_DETAILS",
    CONTACTS: "CONTACTS",
    CONTENT_MATCH: "CONTENT_MATCH",
    COPIED: "COPIED",
    COPY_A: "COPY_A",
    COPY_MESSAGE: "COPY_MESSAGE",
    COPY_RESPONSE: "COPY_RESPONSE",
    COPY_STEP_MESSAGE: "COPY_STEP_MESSAGE",
    COPY_STEP: "COPY_STEP",
    COPY_WITH_METADATA: "COPY_WITH_METADATA",
    COUNTRY: "COUNTRY",
    COURSES: "COURSES",
    CREATE_A_NEW_CHECKLIST_OR_TRY_DIFFERENT_FILTER_KEYWORDS: "CREATE_A_NEW_CHECKLIST_OR_TRY_DIFFERENT_FILTER_KEYWORDS",
    CREATE_A_NEW_CONTACT_OR_TRY_DIFFERENT_FILETER_KEYWORDS: "CREATE_A_NEW_CONTACT_OR_TRY_DIFFERENT_FILETER_KEYWORDS",
    CREATE_A_NEW_CUSTOMER_OR_TRY_DIFFERENT_FILETER_KEYWORDS: "CREATE_A_NEW_CUSTOMER_OR_TRY_DIFFERENT_FILETER_KEYWORDS",
    CREATE_A_NEW_EQUIPMENT_OR_TRY_DIFFERENT_FILETER_KEYWORDS: "CREATE_A_NEW_EQUIPMENT_OR_TRY_DIFFERENT_FILETER_KEYWORDS",
    CREATE_A_NEW_GOAL_OR_TRY_DIFFERENT_FILTER_KEYWORDS: "CREATE_A_NEW_GOAL_OR_TRY_DIFFERENT_FILTER_KEYWORDS",
    CREATE_A_NEW_IMPROVEMENT_FORM_OR_TRY_DIFFERENT_FILTER_KEYWORDS: "CREATE_A_NEW_IMPROVEMENT_FORM_OR_TRY_DIFFERENT_FILTER_KEYWORDS",
    CREATE_A_NEW_KPI_OR_TRY_DIFFERENT_FILTER_KEYWORDS: "CREATE_A_NEW_KPI_OR_TRY_DIFFERENT_FILTER_KEYWORDS",
    CREATE_A_NEW_ONE_OR_ADD_ANY_EXISTING_GOAL: "CREATE_A_NEW_ONE_OR_ADD_ANY_EXISTING_GOAL",
    CREATE_A_NEW_PURCHASE_ORDER_OR_TRY_DIFFERENT_FILTER_KEYWORDS: "CREATE_A_NEW_PURCHASE_ORDER_OR_TRY_DIFFERENT_FILTER_KEYWORDS",
    CREATE_A_NEW_SUPPLIER_OR_TRY_DIFFERENT_FILTER_KEYWORDS: "CREATE_A_NEW_SUPPLIER_OR_TRY_DIFFERENT_FILTER_KEYWORDS",
    CREATE_A_NEW_TASK_OR_TRY_DIFFERENT_FILETER_KEYWORDS: "CREATE_A_NEW_TASK_OR_TRY_DIFFERENT_FILETER_KEYWORDS",
    CREATE_A_NEW_TEAM_OR_TRY_DIFFERENT_FILETER_KEYWORDS: "CREATE_A_NEW_TEAM_OR_TRY_DIFFERENT_FILETER_KEYWORDS",
    CREATE_A_NEW_TEMPLATE_OR_TRY_DIFFERENT_FILETER_KEYWORDS: "CREATE_A_NEW_TEMPLATE_OR_TRY_DIFFERENT_FILETER_KEYWORDS",
    CREATE_A_NEW_USER_OR_TRY_DIFFERENT_FILETER_KEYWORDS: "CREATE_A_NEW_USER_OR_TRY_DIFFERENT_FILETER_KEYWORDS",
    CREATE_A_NEW_WORKSHEET_OR_TRY_DIFFERENT_FILETER_KEYWORDS: "CREATE_A_NEW_WORKSHEET_OR_TRY_DIFFERENT_FILETER_KEYWORDS",
    CREATE_A_SCHEDULE_FOR: "CREATE_A_SCHEDULE_FOR",
    CREATE_KPI: "CREATE_KPI",
    CREATE_NEW_STEP: "CREATE_NEW_STEP",
    CREATE_RECURRENCE_OF: "CREATE_RECURRENCE_FOR",
    CREATE_RECURRING_CHECKLIST: "CREATE_RECURRING_CHECKLIST",
    CREATE_RUN_TRACK_IMPROVE: "CREATE_RUN_TRACK_IMPROVE",
    CREATE: "CREATE",
    CREATED_AT: "CREATED_AT",
    CREATED_BY: "CREATED_BY",
    CREATED: "CREATED",
    CURRENT_AND_PREVIOUS_YEAR: "CURRENT_AND_PREVIOUS_YEAR",
    CURRENT_PASSWORD_MESSAGE: "CURRENT_PASSWORD_MESSAGE",
    CURRENT_PASSWORD: "CURRENT_PASSWORD",
    CURRENT_POSITION: "CURRENT_POSITION",
    CURRENT_VALUE_AT_DATE: "CURRENT_VALUE_AT_DATE",
    CURRENT_VALUE: "CURRENT_VALUE",
    CURRENTLY_THERE_ARE: "CURRENTLY_THERE_ARE",
    CUSTOM_KPI: "CUSTOM_KPI",
    CUSTOM_LIST_ITEM_EDITOR_INFO: "CUSTOM_LIST_ITEM_EDITOR_INFO",
    CUSTOM_LIST_ITEM_EDITOR: "CUSTOM_LIST_ITEM_EDITOR",
    CUSTOM_LIST_ITEM_OWNER_INFO: "CUSTOM_LIST_ITEM_OWNER_INFO",
    CUSTOM_LIST_ITEM_OWNER: "CUSTOM_LIST_ITEM_OWNER",
    CUSTOM_LIST_ITEM_USER_DISABLED_INFO: "CUSTOM_LIST_ITEM_USER_DISABLED_INFO",
    CUSTOM_LIST_ITEM_USER_INFO: "CUSTOM_LIST_ITEM_USER_INFO",
    CUSTOM_LIST_ITEM_USER: "CUSTOM_LIST_ITEM_USER",
    CUSTOM_LIST_ITEM_VIEWER_INFO: "CUSTOM_LIST_ITEM_VIEWER_INFO",
    CUSTOM_LIST_ITEM_VIEWER: "CUSTOM_LIST_ITEM_VIEWER",
    CUSTOM_LIST_OPTIONS_IN_CHECKLIST_WARNING: "CUSTOM_LIST_OPTIONS_IN_CHECKLIST_WARNING",
    CUSTOM_LIST_PAGE_SUBTITLE: "CUSTOM_LIST_PAGE_SUBTITLE",
    CUSTOM_LIST_PAGE_TITLE: "CUSTOM_LIST_PAGE_TITLE",
    CUSTOM_LIST_PERMISSION_DESCRIPTION: "CUSTOM_LIST_PERMISSION_DESCRIPTION",
    CUSTOM_LIST: "CUSTOM_LIST",
    CUSTOM_LISTS_FEATURE_DESCRIPTION: "CUSTOM_LISTS_FEATURE_DESCRIPTION",
    CUSTOM_LISTS: "CUSTOM_LISTS",
    CUSTOM_PROPERTIES: "CUSTOM_PROPERTIES",
    CUSTOMER_DETAILS_SUBTITLE: "CUSTOMER_DETAILS_SUBTITLE",
    CUSTOMER_DETAILS: "CUSTOMER_DETAILS",
    CUSTOMER_ID: "CUSTOMER_ID",
    CUSTOMER_INDUSTRY_PAGE_SUBTITLE: "CUSTOMER_INDUSTRY_PAGE_SUBTITLE",
    CUSTOMER_INDUSTRY_PAGE_TITLE: "CUSTOMER_INDUSTRY_PAGE_TITLE",
    CUSTOMER_LABEL: "CUSTOMER_LABEL",
    CUSTOMER_NEEDS_EXPECTATIONS: "CUSTOMER_NEEDS_EXPECTATIONS",
    CUSTOMER_PLACEHOLDER: "CUSTOMER_PLACEHOLDER",
    CUSTOMER_REQUIREMENT_PAGE_SUBTITLE: "CUSTOMER_REQUIREMENT_PAGE_SUBTITLE",
    CUSTOMER_REQUIREMENT_PAGE_TITLE: "CUSTOMER_REQUIREMENT_PAGE_TITLE",
    CUSTOMER_REQUIREMENTS_COMMENT_PLACEHOLDER: "CUSTOMER_REQUIREMENTS_COMMENT_PLACEHOLDER",
    CUSTOMER_REQUIREMENTS_EDITOR_INFO: "CUSTOMER_REQUIREMENTS_EDITOR_INFO",
    CUSTOMER_REQUIREMENTS_EDITOR: "CUSTOMER_REQUIREMENTS_EDITOR",
    CUSTOMER_REQUIREMENTS_FEATURE_DESCRIPTION: "CUSTOMER_REQUIREMENTS_FEATURE_DESCRIPTION",
    CUSTOMER_REQUIREMENTS_OWNER_INFO: "CUSTOMER_REQUIREMENTS_OWNER_INFO",
    CUSTOMER_REQUIREMENTS_OWNER: "CUSTOMER_REQUIREMENTS_OWNER",
    CUSTOMER_REQUIREMENTS_VIEWER_INFO: "CUSTOMER_REQUIREMENTS_VIEWER_INFO",
    CUSTOMER_REQUIREMENTS_VIEWER: "CUSTOMER_REQUIREMENTS_VIEWER",
    CUSTOMER_REQUIREMENTS: "CUSTOMER_REQUIREMENTS",
    CUSTOMER_SATISFACTION: "CUSTOMER_SATISFACTION",
    CUSTOMER: "CUSTOMER",
    CUSTOMERS_EDITOR_INFO: "CUSTOMERS_EDITOR_INFO",
    CUSTOMERS_EDITOR: "CUSTOMERS_EDITOR",
    CUSTOMERS_FEATURE_DESCRIPTION: "CUSTOMERS_FEATURE_DESCRIPTION",
    CUSTOMERS_OWNER_INFO: "CUSTOMERS_OWNER_INFO",
    CUSTOMERS_OWNER: "CUSTOMERS_OWNER",
    CUSTOMERS_SUBTITLE: "CUSTOMERS_SUBTITLE",
    CUSTOMERS_VIEWER_INFO: "CUSTOMERS_VIEWER_INFO",
    CUSTOMERS_VIEWER: "CUSTOMERS_VIEWER",
    CUSTOMIZE_THRESHOLD_VALUES: "CUSTOMIZE_THRESHOLD_VALUES",
    D_AYS: "D_AYS",
    DASHBOARD: "DASHBOARD",
    DATA_SOURCE_NAME: "DATA_SOURCE_NAME",
    DATA_SOURCE_REQUIRED: "DATA_SOURCE_REQUIRED",
    DATA_SOURCE_TIP1: "DATA_SOURCE_TIP1",
    DATA_SOURCE: "DATA_SOURCE",
    DATA_WILL_BE_FILTERABLE_LIST: "DATA_WILL_BE_FILTERABLE_LIST",
    DATA_WILL_BE_SHOWN_LIST: "DATA_WILL_BE_SHOWN_LIST",
    DATA_WILL_BE_SORTABLE_LIST: "DATA_WILL_BE_SORTABLE_LIST",
    DATA_WILL_BE: "DATA_WILL_BE",
    DATASOURCE_KPI: "DATASOURCE_KPI",
    DATASOURCE_TYPE: "DATASOURCE_TYPE",
    DATE_COMPLETED: "DATE_COMPLETED",
    DATE_END_AFTER_START: "DATE_END_AFTER_START",
    DATE_EVALUATION: "DATE_EVALUATION",
    DATE_OF_ADDITION: "DATE_OF_ADDITION",
    DATE_PRESETS: "DATE_PRESETS",
    DATE_START_BEFORE_END: "DATE_START_BEFORE_END",
    DATE: "DATE",
    DAY_: "DAY_",
    DAY_S_: "DAY_S_",
    DAYS_: "DAYS_",
    DAYS: "DAYS",
    DEADLINE: "DEADLINE",
    DEC: "DEC",
    DECEMBER: "DECEMBER",
    DECIMAL_PLACES_INVALID_NUMBER: "DECIMAL_PLACES_INVALID_NUMBER",
    DECIMAL_PLACES: "DECIMAL_PLACES",
    DECIMAL: "DECIMAL",
    DECISION_POINT: "DECISION_POINT",
    DEFAULT_IMPROVEMENT: "DEFAULT_IMPROVEMENT",
    DEFAULT_PROCESS_ALERT_MESSAGE: "DEFAULT_PROCESS_ALERT_MESSAGE",
    DEFAULT_PROCESS_DESCRIPTION_2: "DEFAULT_PROCESS_DESCRIPTION_2",
    DEFAULT_PROCESS_DESCRIPTION: "DEFAULT_PROCESS_DESCRIPTION",
    DEFAULT_PROCESS: "DEFAULT_PROCESS",
    DELETE_DRAFT: "DELETE_DRAFT",
    DELETE_IMPACT_GRADING_1: "DELETE_IMPACT_GRADING_1",
    DELETE_IMPACT_GRADING_2: "DELETE_IMPACT_GRADING_2",
    DELETE_IMPROVEMENT_CONFIRM_TITLE: "DELETE_IMPROVEMENT_CONFIRM_TITLE",
    DELETE_IMPROVEMENT_PERMANENTLY_MESSAGE: "DELETE_IMPROVEMENT_PERMANENTLY_MESSAGE",
    DELETE_POSITION_1: "DELETE_POSITION_1",
    DELETE_POSITION_2: "DELETE_POSITION_2",
    DELETE_PURCHASE_ORDER_MESSAGE_1: "DELETE_PURCHASE_ORDER_MESSAGE_1",
    DELETE_PURCHASE_ORDER_MESSAGE_2: "DELETE_PURCHASE_ORDER_MESSAGE_2",
    DELETE_TEAM_1: "DELETE_TEAM_1",
    DELETE_TEAM_2: "DELETE_TEAM_2",
    DELETE_THESE_NOTES: "DELETE_THESE_NOTES",
    DELETE_THIS_BARCODE_MESSAGE_LINE1: "DELETE_THIS_BARCODE_MESSAGE_LINE1",
    DELETE_THIS_BARCODE_MESSAGE_LINE2: "DELETE_THIS_BARCODE_MESSAGE_LINE2",
    DELETE_THIS_BARCODE_QUESTION: "DELETE_THIS_BARCODE_QUESTION",
    DELETE_THIS_GENERATED_BARCODE_MESSAGE_LINE1: "DELETE_THIS_GENERATED_BARCODE_MESSAGE_LINE1",
    DELETE_THIS_GOAL: "DELETE_THIS_GOAL",
    DELETE_THIS_KPI_LINE_1: "DELETE_THIS_KPI_LINE_1",
    DELETE_THIS_KPI_LINE_2: "DELETE_THIS_KPI_LINE_2",
    DELETE_THIS_KPI: "DELETE_THIS_KPI",
    DELETE_THIS_MEASUREMENT: "DELETE_THIS_MEASUREMENT",
    DELETE_THIS_PROGRESS_VALUE: "DELETE_THIS_PROGRESS_VALUE",
    DELETE_THIS_TASK_LIST: "DELETE_THIS_TASK_LIST",
    DELETE_THIS_TASK: "DELETE_THIS_TASK",
    DELETE_THIS_TEMPLATE: "DELETE_THIS_TEMPLATE",
    DELETE: "DELETE",
    DELETED: "DELETED",
    DELETING_A_RECURRENCE_WILL1: "DELETING_A_RECURRENCE_WILL1",
    DELETING_A_RECURRENCE_WILL2: "DELETING_A_RECURRENCE_WILL2",
    DELETING_A_RUN_WILL: "DELETING_A_RUN_WILL",
    DELETING_AN_IMPROVEMENT_WILL: "DELETING_AN_IMPROVEMENT_WILL",
    DESCRIBE_IMPOVEMENT_HERE: "DESCRIBE_IMPOVEMENT_HERE",
    DESCRIPTION_PHOTO: "DESCRIPTION_PHOTO",
    DESCRIPTION: "DESCRIPTION",
    DESCRIPTIVE_RECURRANCE_NAME: "DESCRIPTIVE_RECURRANCE_NAME",
    DETAILS: "DETAILS",
    DEVIATION_MANAGER: "DEVIATION_MANAGER",
    DEVIATION_MANAGERS: "DEVIATION_MANAGERS",
    DIRECT_FINANCIAL_CONSEQUENCES_PERCENT: "DIRECT_FINANCIAL_CONSEQUENCES_PERCENT",
    DIRECT_FINANCIAL_CONSEQUENCES: "DIRECT_FINANCIAL_CONSEQUENCES",
    DIRECT_REPORTS: "DIRECT_REPORTS",
    DISABLE_FEATURE_CONFIRM_MESSAGE: "DISABLE_FEATURE_CONFIRM_MESSAGE",
    DISABLE_USER_LINE_1: "DISABLE_USER_LINE_1",
    DISABLE_USER_TITLE: "DISABLE_USER_TITLE",
    DISABLE: "DISABLE",
    DISABLED_USER: "DISABLED_USER",
    DISABLED_WHILE_EDITING: "DISABLED_WHILE_EDITING",
    DISABLED: "DISABLED",
    DISCOVERED_IN_PROCESS_FILTER: "DISCOVERED_IN_PROCESS_FILTER",
    DISCOVERED_IN_T: "DISCOVERED_IN_T",
    DISCOVERED_IN: "DISCOVERED_IN",
    DISMISS: "DISMISS",
    DO_WE_MEET_THE_REQUIREMENT: "DO_WE_MEET_THE_REQUIREMENT",
    DO_WORK_THE_RIGHT_WAY_EVERY_TIME: "DO_WORK_THE_RIGHT_WAY_EVERY_TIME",
    DO_YOU_WANT_TO_FINALIZE_THIS_STEP: "DO_YOU_WANT_TO_FINALIZE_THIS_STEP",
    DO_YOU_WANT_TO_PROCEED: "DO_YOU_WANT_TO_PROCEED",
    DO_YOU_WANT_TO_UN_ARCHIVE_THIS_EQUIPMENT: "DO_YOU_WANT_TO_UN_ARCHIVE_THIS_EQUIPMENT",
    DO_YOU_WANT_TO_UN_ARCHIVE_THIS_TEAM: "DO_YOU_WANT_TO_UN_ARCHIVE_THIS_TEAM",
    DO_YOU_WANT_TO_UPGRADE_EXISTING_CHECKLISTS: "DO_YOU_WANT_TO_UPGRADE_EXISTING_CHECKLISTS",
    DOES_NOT_EXIST_OR_NO_PERMISSION: "DOES_NOT_EXIST_OR_NO_PERMISSION",
    DONE_EARLIER: "DONE_EARLIER",
    DONE_WITH_THIS_STEP: "DONE_WITH_THIS_STEP",
    DONE: "DONE",
    DONT_UPGRADE: "DONT_UPGRADE",
    DOWNLOAD_GENERATED_APP_PACKAGE_DESCRIPTION: "DOWNLOAD_GENERATED_APP_PACKAGE_DESCRIPTION",
    DOWNLOAD_GENERATED_APP_PACKAGE: "DOWNLOAD_GENERATED_APP_PACKAGE",
    DRAFT_SAVED: "DRAFT_SAVED",
    DRAFT: "DRAFT",
    DUE_DATE: "DUE_DATE",
    DUE_IN: "DUE_IN",
    DUE_ON: "DUE_ON",
    DUE: "DUE",
    DURATION: "DURATION",
    EACH_ESTIMATED_TO_TAKE: "EACH_ESTIMATED_TO_TAKE",
    EDIT_CHECKLIST: "EDIT_CHECKLIST",
    EDIT_CUSTOM_LIST: "EDIT_CUSTOM_LIST",
    EDIT_CUSTOMER_CONTACT: "EDIT_CUSTOMER_CONTACT",
    EDIT_CUSTOMER_SUBTITLE: "EDIT_CUSTOMER_SUBTITLE",
    EDIT_CUSTOMER: "EDIT_CUSTOMER",
    EDIT_DATA_SOURCE: "EDIT_DATA_SOURCE",
    EDIT_EQUIPMENT_SUBTITLE: "EDIT_EQUIPMENT_SUBTITLE",
    EDIT_EQUIPMENT: "EDIT_EQUIPMENT",
    EDIT_GOAL: "EDIT_GOAL",
    EDIT_IMPROVEMENT_DRAFT: "EDIT_IMPROVEMENT_DRAFT",
    EDIT_KPI: "EDIT_KPI",
    EDIT_LOCAL_STEP: "EDIT_LOCAL_STEP",
    EDIT_MEASUREMENT: "EDIT_MEASUREMENT",
    EDIT_PERMISSION_SETTINGS: "EDIT_PERMISSION_SETTINGS",
    EDIT_POSITION: "EDIT_POSITION",
    EDIT_PURCHASE_ORDER_SUBTITLE: "EDIT_PURCHASE_ORDER_SUBTITLE",
    EDIT_PURCHASE_ORDER: "EDIT_PURCHASE_ORDER",
    EDIT_RECURRING_CHECKLIST: "EDIT_RECURRING_CHECKLIST",
    EDIT_STEP_DRAFT: "EDIT_STEP_DRAFT",
    EDIT_SUB_GOAL: "EDIT_SUB_GOAL",
    EDIT_SUPPLIER_SUBTITLE: "EDIT_SUPPLIER_SUBTITLE",
    EDIT_SUPPLIER: "EDIT_SUPPLIER",
    EDIT_TARGETS_FOR_THIS_KPI: "EDIT_TARGETS_FOR_THIS_KPI",
    EDIT_TEAM_SUBTITLE: "EDIT_TEAM_SUBTITLE",
    EDIT_TEAM: "EDIT_TEAM",
    EDIT_TEMPLATE_DRAFT: "EDIT_TEMPLATE_DRAFT",
    EDIT_TEMPLATE: "EDIT_TEMPLATE",
    EDIT_TRAINING_PLAN: "EDIT_TRAINING_PLAN",
    EDIT_USER_SUBTITLE: "EDIT_USER_SUBTITLE",
    EDIT_USER: "EDIT_USER",
    EDIT: "EDIT",
    EDITOR: "EDITOR",
    EMAIL_ADDRESS: "EMAIL_ADDRESS",
    EMAIL_PLACEHOLDER: "EMAIL_PLACEHOLDER",
    EMAIL: "EMAIL",
    EMPTY_ACTIVE_CHECKLIST_MESSAGE: "EMPTY_ACTIVE_CHECKLIST_MESSAGE",
    EMPTY_BLOCKED_CHECKLIST_MESSAGE: "EMPTY_BLOCKED_CHECKLIST_MESSAGE",
    EMPTY_FIELD: "EMPTY_FIELD",
    EMPTY_FINALIZED_CHECKLIST_MESSAGE: "EMPTY_FINALIZED_CHECKLIST_MESSAGE",
    EMPTY_IMPACT_GRADING_MESSAGE: "EMPTY_IMPACT_GRADING_MESSAGE",
    EMPTY_STOPPED_CHECKLIST_MESSAGE: "EMPTY_STOPPED_CHECKLIST_MESSAGE",
    ENABLE_THIS_FEATURE: "ENABLE_THIS_FEATURE",
    ENABLE: "ENABLE",
    ENABLED: "ENABLED",
    END_AFTER_START: "END_AFTER_START",
    END_DATE_TIME: "END_DATE_TIME",
    END_DATE: "END_DATE",
    END: "END",
    ENDING_ON_: "ENDING_ON_",
    ENDING_ON: "ENDING_ON",
    ENGLISH_NAME: "ENGLISH_NAME",
    ENGLISH: "ENGLISH",
    ENHANCEMENT: "ENHANCEMENT",
    ENTER_HERE: "ENTER_HERE",
    ENTER_NAME: "ENTER_NAME",
    ENTER_NUMBER: "ENTER_NUMBER",
    ENTRA_TENANT_ID_DESCRIPTION: "ENTRA_TENANT_ID_DESCRIPTION",
    ENTRA_TENANT_ID: "ENTRA_TENANT_ID",
    ENVIRONMENT_ASPECTS_EDITOR_INFO: "ENVIRONMENT_ASPECTS_EDITOR_INFO",
    ENVIRONMENT_ASPECTS_EDITOR: "ENVIRONMENT_ASPECTS_EDITOR",
    ENVIRONMENT_ASPECTS_OWNER_INFO: "ENVIRONMENT_ASPECTS_OWNER_INFO",
    ENVIRONMENT_ASPECTS_OWNER: "ENVIRONMENT_ASPECTS_OWNER",
    ENVIRONMENT_ASPECTS_VIEWER_INFO: "ENVIRONMENT_ASPECTS_VIEWER_INFO",
    ENVIRONMENT_ASPECTS_VIEWER: "ENVIRONMENT_ASPECTS_VIEWER",
    ENVIRONMENT: "ENVIRONMENT",
    ENVIRONMENTAL_AREA: "ENVIRONMENTAL_AREA",
    ENVIRONMENTAL_ASPECTS_FEATURE_DESCRIPTION: "ENVIRONMENTAL_ASPECTS_FEATURE_DESCRIPTION",
    ENVIRONMENTAL_ASPECTS_NAME: "ENVIRONMENTAL_ASPECTS_NAME",
    ENVIRONMENTAL_ASPECTS_PAGE_SUBTITLE: "ENVIRONMENTAL_ASPECTS_PAGE_SUBTITLE",
    ENVIRONMENTAL_ASPECTS_PAGE_TITLE: "ENVIRONMENTAL_ASPECTS_PAGE_TITLE",
    ENVIRONMENTAL_ASPECTS_RELATED_QUESTIONS: "ENVIRONMENTAL_ASPECTS_RELATED_QUESTIONS",
    ENVIRONMENTAL_IMPACT: "ENVIRONMENTAL_IMPACT",
    ENVIRONMENTAL_TARGET: "ENVIRONMENTAL_TARGET",
    EQUAL: "EQUAL",
    EQUIPMENT_DETAILS_SUBTITLE: "EQUIPMENT_DETAILS_SUBTITLE",
    EQUIPMENT_DETAILS: "EQUIPMENT_DETAILS",
    EQUIPMENT_EDITOR_INFO: "EQUIPMENT_EDITOR_INFO",
    EQUIPMENT_EDITOR: "EQUIPMENT_EDITOR",
    EQUIPMENT_FEATURE_DESCRIPTION: "EQUIPMENT_FEATURE_DESCRIPTION",
    EQUIPMENT_OWNER_INFO: "EQUIPMENT_OWNER_INFO",
    EQUIPMENT_OWNER: "EQUIPMENT_OWNER",
    EQUIPMENT_REQUIRED_TO_COMPLETE_STEP: "EQUIPMENT_REQUIRED_TO_COMPLETE_STEP",
    EQUIPMENT_SUBTITLE: "EQUIPMENT_SUBTITLE",
    EQUIPMENT_VIEWER_INFO: "EQUIPMENT_VIEWER_INFO",
    EQUIPMENT_VIEWER: "EQUIPMENT_VIEWER",
    EQUIPMENT: "EQUIPMENT",
    EQUIPMENTS: "EQUIPMENTS",
    ERROR_EMPTY_API_KEY: "ERROR_EMPTY_API_KEY",
    ERROR_POSITION_IN_USE_BY_COMPETENCIES: "ERROR_POSITION_IN_USE_BY_COMPETENCIES",
    ERROR_POSITION_IN_USE_BY_USERS: "ERROR_POSITION_IN_USE_BY_USERS",
    ERROR_POSITION_NOT_ARCHIVED: "ERROR_POSITION_NOT_ARCHIVED",
    ERROR: "ERROR",
    EST_DELIVERY: "EST_DELIVERY",
    ESTIMATED_PROBABILITY_REDUCTION: "ESTIMATED_PROBABILITY_REDUCTION",
    EVALUATION_DATE: "EVALUATION_DATE",
    EVALUATION: "EVALUATION",
    EVENT_DETAILS: "EVENT_DETAILS",
    EVERY_: "EVERY_",
    EVERY: "EVERY",
    EVERYONE: "EVERYONE",
    EXAMPLES_AVAILABILITY: "EXAMPLES_AVAILABILITY",
    EXAMPLES_CONFIDENTIALITY: "EXAMPLES_CONFIDENTIALITY",
    EXAMPLES_DELIVERY_OF_PRODUCT: "EXAMPLES_DELIVERY_OF_PRODUCT",
    EXAMPLES_DELIVERY_OF_SERVICES: "EXAMPLES_DELIVERY_OF_SERVICES",
    EXAMPLES_DIRECT_FINANCIAL_CONSEQUENCES: "EXAMPLES_DIRECT_FINANCIAL_CONSEQUENCES",
    EXAMPLES_ENVIRONMENT: "EXAMPLES_ENVIRONMENT",
    EXAMPLES_HEALTH_SAFETY: "EXAMPLES_HEALTH_SAFETY",
    EXAMPLES_INDIRECT_FINANCIAL_IMPACT: "EXAMPLES_INDIRECT_FINANCIAL_IMPACT",
    EXAMPLES_INTEGRITY: "EXAMPLES_INTEGRITY",
    EXAMPLES_MEDIA_ATTENTION: "EXAMPLES_MEDIA_ATTENTION",
    EXAMPLES_PRODUCT_FUNCTIONALITY: "EXAMPLES_PRODUCT_FUNCTIONALITY",
    EXAMPLES_PRODUCT_SAFETY: "EXAMPLES_PRODUCT_SAFETY",
    EXPECTED_ABOVE_OR_EQUAL: "EXPECTED_ABOVE_OR_EQUAL",
    EXPECTED_ABOVE: "EXPECTED_ABOVE",
    EXPECTED_BELOW_OR_EQUAL: "EXPECTED_BELOW_OR_EQUAL",
    EXPECTED_BELOW: "EXPECTED_BELOW",
    EXPECTED_BETWEEN: "EXPECTED_BETWEEN",
    EXPIRED: "EXPIRED",
    EXPORT_TO_EXCEL: "EXPORT_TO_EXCEL",
    EXTERNAL_ID: "EXTERNAL_ID",
    FAILED_MESSAGE: "FAILED_MESSAGE",
    FEATURE_NOT_FOUND: "FEATURE_NOT_FOUND",
    FEATURE: "FEATURE",
    FEATURES_AND_PERRMISSIONS: "FEATURES_AND_PERRMISSIONS",
    FEB: "FEB",
    FEBRUARY: "FEBRUARY",
    FILE: "FILE",
    FILES_LINKS: "FILES_LINKS",
    FILTER_ALL_WHICH_CONTAINS: "FILTER_ALL_WHICH_CONTAINS",
    FILTER_BY_DISCOVERED_IN_PROCESS: "FILTER_BY_DISCOVERED_IN_PROCESS",
    FILTER_BY_DUE_DATE: "FILTER_BY_DUE_DATE",
    FILTER_BY_ID_UNCHECK_TO_SHOW_ALL: "FILTER_BY_ID_UNCHECK_TO_SHOW_ALL",
    FILTER_BY_IMPACT: "FILTER_BY_IMPACT",
    FILTER_BY_IMPROVEMENT_DESCRIPTION_CONTAINS_TEXT_DESCRIPTION: "FILTER_BY_IMPROVEMENT_DESCRIPTION_CONTAINS_TEXT_DESCRIPTION",
    FILTER_BY_IMPROVEMENT_DESCRIPTION_CONTAINS_TEXT: "FILTER_BY_IMPROVEMENT_DESCRIPTION_CONTAINS_TEXT",
    FILTER_BY_IMPROVEMENT_FORM: "FILTER_BY_IMPROVEMENT_FORM",
    FILTER_BY_IMPROVEMENT_IMPACT_MATRIX_GRADE_IS_HIGHER_THAN: "FILTER_BY_IMPROVEMENT_IMPACT_MATRIX_GRADE_IS_HIGHER_THAN",
    FILTER_BY_IMPROVEMENT_REPORTED_BY_MEMBER_IN_TEAM: "FILTER_BY_IMPROVEMENT_REPORTED_BY_MEMBER_IN_TEAM",
    FILTER_BY_ORIGINATED_IN_PROCESS: "FILTER_BY_ORIGINATED_IN_PROCESS",
    FILTER_BY_POSITION: "FILTER_BY_POSITION",
    FILTER_BY_RESPONSIBLE: "FILTER_BY_RESPONSIBLE",
    FILTER_BY_ROLE: "FILTER_BY_ROLE",
    FILTER_BY_START_DATE: "FILTER_BY_START_DATE",
    FILTER_BY_STATUS: "FILTER_BY_STATUS",
    FILTER_BY_TEAMS: "FILTER_BY_TEAMS",
    FILTER_BY_TEMPLATES: "FILTER_BY_TEMPLATES",
    FILTER: "FILTER",
    FILTERABLE: "FILTERABLE",
    FINALIZE_NOTICE: "FINALIZE_NOTICE",
    FINALIZE_QUESTION: "FINALIZE_QUESTION",
    FINALIZE: "FINALIZE",
    FINALIZED_AND_RUNNING: "FINALIZED_AND_RUNNING",
    FINALIZED_AT: "FINALIZED_AT",
    FINALIZED_BY_MISTAKE: "FINALIZED_BY_MISTAKE",
    FINALIZED_BY: "FINALIZED_BY",
    FINALIZED_CHECKLISTS: "FINALIZED_CHECKLISTS",
    FINALIZED_L: "FINALIZED_L",
    FINALIZED_P: "FINALIZED_P",
    FINALIZED_TODAY: "FINALIZED_TODAY",
    FINALIZED: "FINALIZED",
    FIRST_: "FIRST_",
    FIRST_CHECKLIST: "FIRST_CHECKLIST",
    FIRST: "FIRST",
    FISCAL_YEAR_CONFIG_DESCRIPTION: "FISCAL_YEAR_CONFIG_DESCRIPTION",
    FISCAL_YEAR_CONFIG: "FISCAL_YEAR_CONFIG",
    FIT_CONTENT: "FIT_CONTENT",
    FOLDER_NOT_FOUND: "FOLDER_NOT_FOUND",
    FOLDER: "FOLDER",
    FOR_: "FOR_",
    FOR_IT_AND_DEVELOPERS: "FOR_IT_AND_DEVELOPERS",
    FORM_ERRORS: "FORM_ERRORS",
    FORM_INFORMATION: "FORM_INFORMATION",
    FORM_NAME: "FORM_NAME",
    FORM: "FORM",
    FORMULA_CANNOT_END_WITH_OPERATOR: "FORMULA_CANNOT_END_WITH_OPERATOR",
    FORMULA_CANNOT_START_WITH_OPERATOR: "FORMULA_CANNOT_START_WITH_OPERATOR",
    FORMULA_EXPRESSION: "FORMULA_EXPRESSION",
    FORMULA_ITEM: "FORMULA_ITEM",
    FORMULA_LIBRARY: "FORMULA_LIBRARY",
    FORMULA_TOO_LONG: "FORMULA_TOO_LONG",
    FORMULA_VARIABLES: "FORMULA_VARIABLES",
    FORMULA: "FORMULA",
    FOURTH_: "FOURTH_",
    FOURTH: "FOURTH",
    FRIDAY: "FRIDAY",
    FROM_: "FROM_",
    FROM_CLOUD_LIBRARY: "FROM_CLOUD_LIBRARY",
    FROM: "FROM",
    FUTURE_DATE: "FUTURE_DATE",
    GENERAL: "GENERAL",
    GENERATE_APP_PACKAGE: "GENERATE_APP_PACKAGE",
    GENERATE_BARCODE: "GENERATE_BARCODE",
    GENERATE_PAYLOAD: "GENERATE_PAYLOAD",
    GEOGRAPHICALLY_LINKED_PLACEHOLDER: "GEOGRAPHICALLY_LINKED_PLACEHOLDER",
    GEOGRAPHICALLY_LINKED: "GEOGRAPHICALLY_LINKED",
    GO_TO: "GO_TO",
    GOAL_ARCHIVED: "GOAL_ARCHIVED",
    GOAL_CREATED: "GOAL_CREATED",
    GOAL_DELETED: "GOAL_DELETED",
    GOAL_SAVED: "GOAL_SAVED",
    GOAL_UNARCHIVED: "GOAL_UNARCHIVED",
    GOAL: "GOAL",
    GOALS_EDITOR_INFO: "GOALS_EDITOR_INFO",
    GOALS_EDITOR: "GOALS_EDITOR",
    GOALS_FEATURE_DESCRIPTION: "GOALS_FEATURE_DESCRIPTION",
    GOALS_OWNER_INFO: "GOALS_OWNER_INFO",
    GOALS_OWNER: "GOALS_OWNER",
    GOALS_PAGE_SUBTITLE: "GOALS_PAGE_SUBTITLE",
    GOALS_PAGE_TITLE: "GOALS_PAGE_TITLE",
    GOALS_USER_INFO: "GOALS_USER_INFO",
    GOALS_USER: "GOALS_USER",
    GREEN: "GREEN",
    GROUP_BY: "GROUP_BY",
    GUIDANCE: "GUIDANCE",
    H_OURS: "H_OURS",
    HAVE_BEEN_: "HAVE_BEEN_",
    HAVE_BEEN_COMPLETED: "HAVE_BEEN_COMPLETED",
    HAVE_BEEN: "HAVE_BEEN",
    HEALTH_SAFETY: "HEALTH_SAFETY",
    HELP_AND_SUPPORT: "HELP_AND_SUPPORT",
    HIDE_LIST: "HIDE_LIST",
    HIGHLIGHTED_RISK: "HIGHLIGHTED_RISK",
    HISTORY: `HISTORY`,
    HOME_: "HOME_",
    HOME_TITLE: "HOME_TITLE",
    HOME: "HOME",
    HOUR_: "HOUR_",
    HOURS_: "HOURS_",
    HOURS: "HOURS",
    HOW_LONG_DOES_IT_TAKE_TO_COMPLETE_1_CHECKLIST: "HOW_LONG_DOES_IT_TAKE_TO_COMPLETE_1_CHECKLIST",
    HOW_WE_ARE_AFFECTED_BY_THIS_LEGISLATION: "HOW_WE_ARE_AFFECTED_BY_THIS_LEGISLATION",
    HOW_WE_MEET_THIS_LEGISLATION: "HOW_WE_MEET_THIS_LEGISLATION",
    HOW_WOULD_YOU_LIKE_RUNS_TO_REPEAT: "HOW_WOULD_YOU_LIKE_RUNS_TO_REPEAT",
    HYPERLINK: "HYPERLINK",
    I_AGREE_TO_THE: "I_AGREE_TO_THE",
    I_UNDERSTAND_THE_CONSEQUENCES_DELETE_AREA: "I_UNDERSTAND_THE_CONSEQUENCES_DELETE_AREA",
    I_UNDERSTAND_THE_CONSEQUENCES_DELETE_POSITION: "I_UNDERSTAND_THE_CONSEQUENCES_DELETE_POSITION",
    I_UNDERSTAND_THE_CONSEQUENCES_DELETE_TEAM: "I_UNDERSTAND_THE_CONSEQUENCES_DELETE_TEAM",
    ICONS: "ICONS",
    ID_NUMBER: "ID_NUMBER",
    ID: "ID",
    IDENTIFIED_RISKS: "IDENTIFIED_RISKS",
    IDENTIFIED_TARGETS: "IDENTIFIED_TARGETS",
    IF_YOU_CANCEL_YOU_WILL_LOSE_THE_CHANGES_YOU_JUST_MADE: "IF_YOU_CANCEL_YOU_WILL_LOSE_THE_CHANGES_YOU_JUST_MADE",
    IF_YOU_WANT_TO_STOP_ALL_SCHEDULED_CHECKLISTS_YOU_SET_THE_NEW_END_DATE_BEFORE: "IF_YOU_WANT_TO_STOP_ALL_SCHEDULED_CHECKLISTS_YOU_SET_THE_NEW_END_DATE_BEFORE",
    IMPACT_CLASSIFICATION: "IMPACT_CLASSIFICATION",
    IMPACT_GRADE: "IMPACT_GRADE",
    IMPACT_GRADING_PAGE_SUBTITLE: "IMPACT_GRADING_PAGE_SUBTITLE",
    IMPACT_GRADING_PAGE_TITLE: "IMPACT_GRADING_PAGE_TITLE",
    IMPACT_GRADINGS_EDITOR_INFO: "IMPACT_GRADINGS_EDITOR_INFO",
    IMPACT_GRADINGS_EDITOR: "IMPACT_GRADINGS_EDITOR",
    IMPACT_GRADINGS_FEATURE_DESCRIPTION: "IMPACT_GRADINGS_FEATURE_DESCRIPTION",
    IMPACT_GRADINGS_OWNER_INFO: "IMPACT_GRADINGS_OWNER_INFO",
    IMPACT_GRADINGS_OWNER: "IMPACT_GRADINGS_OWNER",
    IMPACT_GRADINGS_VIEWER_INFO: "IMPACT_GRADINGS_VIEWER_INFO",
    IMPACT_GRADINGS_VIEWER: "IMPACT_GRADINGS_VIEWER",
    IMPACT_RATING: "IMPACT_RATING",
    IMPACT_SCORE1_LABEL: "IMPACT_SCORE1_LABEL",
    IMPACT_SCORE2_LABEL: "IMPACT_SCORE2_LABEL",
    IMPACT_SCORE3_LABEL: "IMPACT_SCORE3_LABEL",
    IMPACT_SCORE4_LABEL: "IMPACT_SCORE4_LABEL",
    IMPACT_SCORE5_LABEL: "IMPACT_SCORE5_LABEL",
    IMPACT: "IMPACT",
    IMPACTED_AREA: "IMPACTED_AREA",
    IMPACTED_AREAS: "IMPACTED_AREAS",
    IMPACTED_PROCESS_DISCOVERED: "IMPACTED_PROCESS_DISCOVERED",
    IMPACTED_PROCESS_ORIGINATED: "IMPACTED_PROCESS_ORIGINATED",
    IMPACTED_PROCESSES: "IMPACTED_PROCESSES",
    IMPORT_FROM_EXCEL_SUCCESS_MESSAGE_WITH_AMOUNT: "IMPORT_FROM_EXCEL_SUCCESS_MESSAGE_WITH_AMOUNT",
    IMPORT_TEMPLATE_FROM_CLOUD: "IMPORT_TEMPLATE_FROM_CLOUD",
    IMPORT: "IMPORT",
    IMPORTANT_ACTIVITIES: "IMPORTANT_ACTIVITIES",
    IMPORTED: "IMPORTED",
    IMPROVEMENT_CLOSED_WITH_REASON: "IMPROVEMENT_CLOSED_WITH_REASON",
    IMPROVEMENT_CONFIGURATION_INVOLVED_TABLE_HEADER: "IMPROVEMENT_CONFIGURATION_INVOLVED_TABLE_HEADER",
    IMPROVEMENT_DASHBOARD_FEATURE_DESCRIPTION: "IMPROVEMENT_DASHBOARD_FEATURE_DESCRIPTION",
    IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPACT: "IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPACT",
    IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPACTED_AREA: "IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPACTED_AREA",
    IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPACTED_PROCESS: "IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPACTED_PROCESS",
    IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPROVEMENT_FORM: "IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_IMPROVEMENT_FORM",
    IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_STATUS: "IMPROVEMENT_DASHBOARD_IMPROVEMENT_BY_STATUS",
    IMPROVEMENT_DASHBOARD_OWNER_INFO: "IMPROVEMENT_DASHBOARD_OWNER_INFO",
    IMPROVEMENT_DASHBOARD_OWNER: "IMPROVEMENT_DASHBOARD_OWNER",
    IMPROVEMENT_DASHBOARD_PAGE_LABEL: "IMPROVEMENT_DASHBOARD_PAGE_LABEL",
    IMPROVEMENT_DASHBOARD_PAGE_SUBHEADING: "IMPROVEMENT_DASHBOARD_PAGE_SUBHEADING",
    IMPROVEMENT_DASHBOARD_VIEWER_INFO: "IMPROVEMENT_DASHBOARD_VIEWER_INFO",
    IMPROVEMENT_DASHBOARD_VIEWER: "IMPROVEMENT_DASHBOARD_VIEWER",
    IMPROVEMENT_DASHBOARD: "IMPROVEMENT_DASHBOARD",
    IMPROVEMENT_DATA: "IMPROVEMENT_DATA",
    IMPROVEMENT_DETAILS_ACTIVITY_IN_STEP: "IMPROVEMENT_DETAILS_ACTIVITY_IN_STEP",
    IMPROVEMENT_DETAILS_IMPACTED_TEMPLATES: "IMPROVEMENT_DETAILS_IMPACTED_TEMPLATES",
    IMPROVEMENT_DETAILS_REPORTED_FROM: "IMPROVEMENT_DETAILS_REPORTED_FROM",
    IMPROVEMENT_DETAILS_SCHEDULE: "IMPROVEMENT_DETAILS_SCHEDULE",
    IMPROVEMENT_DETAILS_STEP: "IMPROVEMENT_DETAILS_STEP",
    IMPROVEMENT_DISCOVERED_IN_T: "IMPROVEMENT_DISCOVERED_IN_T",
    IMPROVEMENT_FORM_DETAILS: "IMPROVEMENT_FORM_DETAILS",
    IMPROVEMENT_FORM_FILTER: "IMPROVEMENT_FORM_FILTER",
    IMPROVEMENT_FORM: "IMPROVEMENT_FORM",
    IMPROVEMENT_IMPACT_FILTER: "IMPROVEMENT_IMPACT_FILTER",
    IMPROVEMENT_KPI: "IMPROVEMENT_KPI",
    IMPROVEMENT_LEFT_BY: "IMPROVEMENT_LEFT_BY",
    IMPROVEMENT_ORIGINATED_IN_T: "IMPROVEMENT_ORIGINATED_IN_T",
    IMPROVEMENT_REGISTERED: "IMPROVEMENT_REGISTERED",
    IMPROVEMENT_STATUS_FILTER: "IMPROVEMENT_STATUS_FILTER",
    IMPROVEMENT_STATUS: "IMPROVEMENT_STATUS",
    IMPROVEMENT_STEPS: "IMPROVEMENT_STEPS",
    IMPROVEMENT: "IMPROVEMENT",
    IMPROVEMENTS_FORMS_PAGE_SUBTITLE: "IMPROVEMENTS_FORMS_PAGE_SUBTITLE",
    IMPROVEMENTS_PAGE_LABEL: "IMPROVEMENTS_PAGE_LABEL",
    IMPROVEMENTS_REPORTED_TO_AN_ACTIVITY: "IMPROVEMENTS_REPORTED_TO_AN_ACTIVITY",
    IMPROVEMENTS: "IMPROVEMENTS",
    IN_: "IN_",
    IN_PROGRESS: "IN_PROGRESS",
    IN_TOTAL_: "IN_TOTAL_",
    INBOX: "INBOX",
    INDIRECT_FINANCIAL_IMPACT_PERCENT: "INDIRECT_FINANCIAL_IMPACT_PERCENT",
    INDIRECT_FINANCIAL_IMPACT: "INDIRECT_FINANCIAL_IMPACT",
    INDUSTRY_RELEVANT: "INDUSTRY_RELEVANT",
    INDUSTRY: "INDUSTRY",
    INFO: "INFO",
    INFORMATION_SECURITY_AVAILABILITY: "INFORMATION_SECURITY_AVAILABILITY",
    INFORMATION_SECURITY_CONFIDENTIALITY: "INFORMATION_SECURITY_CONFIDENTIALITY",
    INFORMATION_SECURITY_INTEGRITY: "INFORMATION_SECURITY_INTEGRITY",
    INFORMATION: "INFORMATION",
    INPUT_FROM: "INPUT_FROM",
    INPUTS: "INPUTS",
    INTERNAL_ID: "INTERNAL_ID",
    INVALID_BARCODE: "INVALID_BARCODE",
    INVALID_CREDENTIALS: "INVALID_CREDENTIALS",
    INVALID_FIELDS: "INVALID_FIELDS",
    INVALID_FUTURE_USER_POSITION_DATES: "INVALID_FUTURE_USER_POSITION_DATES",
    INVALID_OVERLAPPING_USER_POSITION_DATES: "INVALID_OVERLAPPING_USER_POSITION_DATES",
    INVALID_URL: "INVALID_URL",
    INVALID_USER_POSITION_DATES: "INVALID_USER_POSITION_DATES",
    INVALID_VARIABLE: "INVALID_VARIABLE",
    INVALID: "INVALID",
    INVOICING: "INVOICING",
    INVOLVED_TEAMS: "INVOLVED_TEAMS",
    INVOLVED_USERS_HEADER_TOOLTIP: "INVOLVED_USERS_HEADER_TOOLTIP",
    INVOLVED_USERS_TOOLTIP: "INVOLVED_USERS_TOOLTIP",
    INVOLVED_USERS: "INVOLVED_USERS",
    INVOLVED: "INVOLVED",
    INVOLVEMENT: "INVOLVEMENT",
    IS_: "IS_",
    IS_DEFAULT_IMPROVEMENT_STEP: "IS_DEFAULT_IMPROVEMENT_STEP",
    IS_KEY_ACTIVITY: "IS_KEY_ACTIVITY",
    IS_PRIMARY_CONTACT: "IS_PRIMARY_CONTACT",
    IS_PRIMARY: "IS_PRIMARY",
    IS_USER_ROLE: "IS_USER_ROLE",
    IT_AND_DEV: "IT_AND_DEV",
    IT_CONTAINS: "IT_CONTAINS",
    IT_SEEMS_THAT_NOT_ALL_REQUIRED_ACTIVITIES_HAVE_BEEN_COMPLETED: "IT_SEEMS_THAT_NOT_ALL_REQUIRED_ACTIVITIES_HAVE_BEEN_COMPLETED",
    IT_WILL_HAVE: "IT_WILL_HAVE",
    ITEM_CATALOG_SUBTITLE: "ITEM_CATALOG_SUBTITLE",
    ITEM_CATALOG: "ITEM_CATALOG",
    ITEM_LABEL: "ITEM_LABEL",
    ITEM_PLACEHOLDER: "ITEM_PLACEHOLDER",
    ITEM_TYPE_NOT_PROVIDED: "ITEM_TYPE_NOT_PROVIDED",
    ITEM_TYPE: "ITEM_TYPE",
    ITEM_UNIT: "ITEM_UNIT",
    ITEM: "ITEM",
    ITEMS_EDITOR_INFO: "ITEMS_EDITOR_INFO",
    ITEMS_EDITOR: "ITEMS_EDITOR",
    ITEMS_FEATURE_DESCRIPTION: "ITEMS_FEATURE_DESCRIPTION",
    ITEMS_OWNER_INFO: "ITEMS_OWNER_INFO",
    ITEMS_OWNER: "ITEMS_OWNER",
    ITEMS_VIEWER_INFO: "ITEMS_VIEWER_INFO",
    ITEMS_VIEWER: "ITEMS_VIEWER",
    ITEMS: "ITEMS",
    ITS_EMPTY_HERE: "ITS_EMPTY_HERE",
    JAN: "JAN",
    JANUARY: "JANUARY",
    JSON_PAYLOAD_PREVIEW_DESCRIPTION: "JSON_PAYLOAD_PREVIEW_DESCRIPTION",
    JSON_PAYLOAD_PREVIEW: "JSON_PAYLOAD_PREVIEW",
    JUL: "JUL",
    JULY: "JULY",
    JUN: "JUN",
    JUNE: "JUNE",
    JUST_NOW: "JUST_NOW",
    KEY_ACTIVITIES_TOOLTIP: "KEY_ACTIVITES_TOOLTIP",
    KEY_ACTIVITIES: "KEY_ACTIVITIES",
    KPI_CANNOT_REFERENCE_ITSELF: "KPI_CANNOT_REFERENCE_ITSELF",
    KPI_CONTRIBUTOR_INFO: "KPI_CONTRIBUTOR_INFO",
    KPI_CONTRIBUTOR: "KPI_CONTRIBUTOR",
    KPI_CREATED: "KPI_CREATED",
    KPI_DATA_SOURCE_ID_REQUIRED: "KPI_DATA_SOURCE_ID_REQUIRED",
    KPI_DESCRIPTION: "KPI_DESCRIPTION",
    KPI_DETAILS_TIP1: "KPI_DETAILS_TIP1",
    KPI_DETAILS: "KPI_DETAILS",
    KPI_EDITOR_INFO: "KPI_EDITOR_INFO",
    KPI_EDITOR: "KPI_EDITOR",
    KPI_FEATURE_DESCRIPTION: "KPI_FEATURE_DESCRIPTION",
    KPI_FORMULA_TIP1: "KPI_FORMULA_TIP1",
    KPI_FORMULA_TIP2: "KPI_FORMULA_TIP2",
    KPI_FORMULA_TIP3: "KPI_FORMULA_TIP3",
    KPI_FORMULA: "KPI_FORMULA",
    KPI_MANUAL_DATA_SOURCE_INFO: "KPI_MANUAL_DATA_SOURCE_INFO",
    KPI_NAME_REQUIRED: "KPI_NAME_REQUIRED",
    KPI_NAME: "KPI_NAME",
    KPI_OWNER_INFO: "KPI_OWNER_INFO",
    KPI_OWNER: "KPI_OWNER",
    KPI_PAGE_SUBTITLE: "KPI_PAGE_SUBTITLE",
    KPI_PAGE_TITLE: "KPI_PAGE_TITLE",
    KPI_SAVED: "KPI_SAVED",
    KPI_TARGETS_INFO: "KPI_TARGETS_INFO",
    KPI_TARGETS: "KPI_TARGETS",
    KPI_UNIT: "KPI_UNIT",
    KPI_UPDATED: "KPI_UPDATED",
    KPI_VIEWER_INFO: "KPI_VIEWER_INFO",
    KPI_VIEWER: "KPI_VIEWER",
    KPI: "KPI",
    LABEL_: "LABEL_",
    LABEL: "LABEL",
    LANGUAGE: "LANGUAGE",
    LAST_: "LAST_",
    LAST_DAY_: "LAST_DAY_",
    LAST_MODIFIED: "LAST_MODIFIED",
    LAST_MONTH: "LAST_MONTH",
    LAST_WEEK: "LAST_WEEK",
    LAST: "LAST",
    LEAD_TIME_TARGET: "LEAD_TIME_TARGET",
    LEGISLATION_PAGE_SUBTITLE: "LEGISLATION_PAGE_SUBTITLE",
    LEGISLATION_PAGE_TITLE: "LEGISLATION_PAGE_TITLE",
    LEGISLATION_TITLE: "LEGISLATION_TITLE",
    LEGISLATIONS_EDITOR_INFO: "LEGISLATIONS_EDITOR_INFO",
    LEGISLATIONS_EDITOR: "LEGISLATIONS_EDITOR",
    LEGISLATIONS_FEATURE_DESCRIPTION: "LEGISLATIONS_FEATURE_DESCRIPTION",
    LEGISLATIONS_OWNER_INFO: "LEGISLATIONS_OWNER_INFO",
    LEGISLATIONS_OWNER: "LEGISLATIONS_OWNER",
    LEGISLATIONS_VIEWER_INFO: "LEGISLATIONS_VIEWER_INFO",
    LEGISLATIONS_VIEWER: "LEGISLATIONS_VIEWER",
    LETS_ADD_SOME_CONTACT: "LETS_ADD_SOME_CONTACT",
    LEVEL_OF_EDUCATION: "LEVEL_OF_EDUCATION",
    LEVEL_OF_INTEREST: "LEVEL_OF_INTEREST",
    LINK_LABEL: "LINK_LABEL",
    LINK_PLACEHOLDER: "LINK_PLACEHOLDER",
    LINK_SUB_PROCESS: "LINK_SUB_PROCESS",
    LINK_TO_IN_DEPTH_INFORMATION: "LINK_TO_IN_DEPTH_INFORMATION",
    LINK_TO_LEGISLATION: "LINK_TO_LEGISLATION",
    LINK: "LINK",
    LINKS: "LINKS",
    LOAD_EARLIER: "LOAD_EARLIER",
    LOADING: "LOADING",
    LOG_EXECUTED: "LOG_EXECUTED",
    LOGIN_: "LOGIN_",
    LOGIN_PLEASE_WAIT: "LOGIN_PLEASE_WAIT",
    LOGIN_QUICK_TIP_DESCRIPTION: "LOGIN_QUICK_TIP_DESCRIPTION",
    LOGIN_QUICK_TIP: "LOGIN_QUICK_TIP",
    LOGIN_TO: "LOGIN_TO",
    LOGIN: "LOGIN",
    LOGOUT_ALL_USERS_DESCRIPTION: "LOGOUT_ALL_USERS_DESCRIPTION",
    LOGOUT_ALL_USERS: "LOGOUT_ALL_USERS",
    LOGOUT_CONFIRMATION: "LOGOUT_CONFIRMATION",
    M_INUTES: "M_INUTES",
    MAIN_TASK: "MAIN_TASK",
    MAKE_PRIMARY: "MAKE_PRIMARY",
    MANAGE_API_KEYS: "MANAGE_API_KEYS",
    MANAGE_CUSTOM_LISTS: "MANAGE_CUSTOM_LISTS",
    MANAGE_CUSTOMER_INDUSTRIES: "MANAGE_CUSTOMER_INDUSTRIES",
    MANAGE_PROCESS_CHARTS: "MANAGE_PROCESS_CHARTS",
    MANAGE_PURCHASE_ORDER_LINE_STATUS_SUBTITLE: "MANAGE_PURCHASE_ORDER_LINE_STATUS_SUBTITLE",
    MANAGE_SSO: "MANAGE_SSO",
    MANAGE_TYPE_UNIT_SUBTITLE: "MANAGE_TYPE_UNIT_SUBTITLE",
    MANAGE_WEBHOOKS: "MANAGE_WEBHOOKS",
    MANAGED_BY_THESE_PROCESS: "MANAGED_BY_THESE_PROCESS",
    MANAGEMENT: "MANAGEMENT",
    MANAGER: "MANAGER",
    MANAGING_CUSTOM_LISTS_DESCRIPTION: "MANAGING_CUSTOM_LISTS_DESCRIPTION",
    MANAGING_CUSTOM_LISTS: "MANAGING_CUSTOM_LISTS",
    MANAGING_SSO_CONFIG_DESCRIPTION: "MANAGING_SSO_CONFIG_DESCRIPTION",
    MANAGING_SSO_CONFIG: "MANAGING_SSO_CONFIG",
    MANAGING_WEBHOOKS_AND_EVENTS_DESCRIPTION: "MANAGING_WEBHOOKS_AND_EVENTS_DESCRIPTION",
    MANAGING_WEBHOOKS_AND_EVENTS: "MANAGING_WEBHOOKS_AND_EVENTS",
    MANUAL_DATA_SOURCE_DESCRIPTION: "MANUAL_DATA_SOURCE_DESCRIPTION",
    MANUAL_VALUE: "MANUAL_VALUE",
    MANUAL: "MANUAL",
    MAPPER: "MAPPER",
    MAR: "MAR",
    MARCH: "MARCH",
    MARK_AS_ANALYZED: "MARK_AS_ANALYZED",
    MARK_AS_COMPLETED: "MARK_AS_COMPLETED",
    MARK_AS_DONE: "MARK_AS_DONE",
    MARK_AS_INCOMPLETE: "MARK_AS_INCOMPLETE",
    MARK_AS_NOT_ANALYZED: "MARK_AS_NOT_ANALYZED",
    MARK_AS_NOT_DONE: "MARK_AS_NOT_DONE",
    MARK_AS_NOT_REVIEWED: "MARK_AS_NOT_REVIEWED",
    MARK_AS_REVIEWED : "MARK_AS_REVIEWED",
    MARKED_AS_COMPLETE: "MARKED_AS_COMPLETE",
    MARKED_AS_INCOMPLETE: "MARKED_AS_INCOMPLETE",
    MARKED_AS_REQUIRED: "MARKED_AS_REQUIRED",
    MARKED_FOR_REMOVAL: "MARKED_FOR_REMOVAL",
    MARKERS_OF_PROGRESS_LINE: "MARKERS_OF_PROGRESS_LINE",
    MATCHING_CHECKLISTS_: "MATCHING_CHECKLISTS_",
    MATCHING_VALUE_: "MATCHING_VALUE_",
    MATCHING_VALUES_: "MATCHING_VALUES_",
    MAY: "MAY",
    ME: "ME",
    MEASUREMENT_ARCHIVED: "MEASUREMENT_ARCHIVED",
    MEASUREMENT_CREATED: "MEASUREMENT_CREATED",
    MEASUREMENT_INDEX: "MEASUREMENT_INDEX",
    MEASUREMENT_UNARCHIVED: "MEASUREMENT_UNARCHIVED",
    MEASUREMENT: "MEASUREMENT",
    MEASUREMENTS: "MEASUREMENTS",
    MEDIA_ATTENTION: "MEDIA_ATTENTION",
    MESSAGE: "MESSAGE",
    MICROSOFT_TEAMS_APP_FEATURE_DESCRIPTION: "MICROSOFT_TEAMS_APP_FEATURE_DESCRIPTION",
    MIN_ONE: "MIN_ONE",
    MIN_UTES: "MIN_UTES",
    MIN_ZERO: "MIN_ZERO",
    MINUTE_: "MINUTE_",
    MINUTES_: "MINUTES_",
    MINUTES: "MINUTES",
    MISSING_CLOSING_PARENTHESIS: "MISSING_CLOSING_PARENTHESIS",
    MISSING_DATA_TO_CALCULATE: "MISSING_DATA_TO_CALCULATE",
    MISSING_ITEMS: "MISSING_ITEMS",
    MISSING_OPENING_PARENTHESIS: "MISSING_OPENING_PARENTHESIS",
    MISSING_OPERAND: "MISSING_OPERAND",
    MISSING_OPERATOR: "MISSING_OPERATOR",
    MISSING_PARENTHESIS: "MISSING_PARENTHESIS",
    MODIFIED: "MODIFIED",
    MONDAY: "MONDAY",
    MONTH_S_: "MONTH_S_",
    MONTH_S: "MONTH_S",
    MONTHLY: "MONTHLY",
    MONTHS_: "MONTHS_",
    MORE: "MORE",
    MOTIVATION: "MOTIVATION",
    MOVE_ACTIVITY_DOWN: "MOVE_ACTIVITY_DOWN",
    MOVE_ACTIVITY_UP: "MOVE_ACTIVITY_UP",
    MOVE_ON_TO_THE_NEXT_STEP: "MOVE_ON_TO_THE_NEXT_STEP",
    MOVE_STEP_DOWN: "MOVE_STEP_DOWN",
    MOVE_STEP_UP: "MOVE_STEP_UP",
    MOVE: "MOVE",
    MY_CHECKLISTS: "MY_CHECKLISTS",
    MY_IMPROVEMENTS: "MY_IMPROVEMENTS",
    MY_TASKS: "MY_TASKS",
    NAME_CHECKLIST: "NAME_CHECKLIST",
    NAME: "NAME",
    NAV_BUTTON_REGISTER_IMPROVEMENT: "NAV_BUTTON_REGISTER_IMPROVEMENT",
    NAV_BUTTON_RUN_CHECKLIST: "NAV_BUTTON_RUN_CHECKLIST",
    NAV_NODE_ADMINISTRATOR_SETTINGS: "NAV_NODE_ADMINISTRATOR_SETTINGS",
    NAV_NODE_ADMINISTRATOR: "NAV_NODE_ADMINISTRATOR",
    NAV_NODE_ADVANCED_FEATURES: "NAV_NODE_ADVANCED_FEATURES",
    NAV_NODE_ANALYZE: "NAV_NODE_ANALYZE",
    NAV_NODE_BULK_IMPORT: "NAV_NODE_BULK_IMPORT",
    NAV_NODE_CHECKLIST_TEMPLATE: "NAV_NODE_CHECKLIST_TEMPLATE",
    NAV_NODE_CHECKLISTS_REPORT_TIMELINE_VIEW: "NAV_NODE_CHECKLISTS_REPORT_TIMELINE_VIEW",
    NAV_NODE_CHECKLISTS_REPORT: "NAV_NODE_CHECKLISTS_REPORT",
    NAV_NODE_CHECKLISTS_SEARCH: "NAV_NODE_CHECKLISTS_SEARCH",
    NAV_NODE_CHECKLISTS: "NAV_NODE_CHECKLISTS",
    NAV_NODE_COMPETENCY_MATRIX: "NAV_NODE_COMPETENCY_MATRIX",
    NAV_NODE_CUSTOM_LIST_ITEM: "NAV_NODE_CUSTOM_LISTS",
    NAV_NODE_CUSTOM_LISTS: "NAV_NODE_CUSTOM_LISTS",
    NAV_NODE_CUSTOMERS: "NAV_NODE_CUSTOMERS",
    NAV_NODE_DATA_MANAGEMENT: "NAV_NODE_DATA_MANAGEMENT",
    NAV_NODE_ENVIRONMENTAL_ASPECTS: "ENVIRONMENTAL_ASPECTS_PAGE_TITLE",
    NAV_NODE_EQUIPMENT: "NAV_NODE_EQUIPMENT",
    NAV_NODE_GOALS: "NAV_NODE_GOALS",
    NAV_NODE_HR: "NAV_NODE_HR",
    NAV_NODE_HUMAN_RESOURCES: "NAV_NODE_HUMAN_RESOURCES",
    NAV_NODE_IMPACT_GRADINGS: "NAV_NODE_IMPACT_GRADINGS",
    NAV_NODE_IMPROVEMENT_DASHBOARD: "NAV_NODE_IMPROVEMENT_DASHBOARD",
    NAV_NODE_IMPROVEMENT_FORMS: "NAV_NODE_IMPROVEMENT_FORMS",
    NAV_NODE_IMPROVEMENT_SETUP: "NAV_NODE_IMPROVEMENT_SETUP",
    NAV_NODE_IMPROVEMENTS: "NAV_NODE_IMPROVEMENTS",
    NAV_NODE_ITEMS: "NAV_NODE_ITEMS",
    NAV_NODE_KPI: "NAV_NODE_KPI",
    NAV_NODE_LEGISLATIONS: "NAV_NODE_LEGISLATIONS",
    NAV_NODE_LISTS: `NAV_NODE_LISTS`,
    NAV_NODE_LOG_OUT: "NAV_NODE_LOG_OUT",
    NAV_NODE_MANAGE_PURCHASE_ORDER_LINE_STATUS: "NAV_NODE_MANAGE_PURCHASE_ORDER_LINE_STATUS",
    NAV_NODE_MANAGE_TYPE_AND_UNIT: "NAV_NODE_MANAGE_TYPE_AND_UNIT",
    NAV_NODE_MAP: "NAV_NODE_MAP",
    NAV_NODE_MARKETING_SALES: "NAV_NODE_MARKETING_SALES",
    NAV_NODE_MICROSOFT_TEAMS_APP: "NAV_NODE_MICROSOFT_TEAMS_APP",
    NAV_NODE_OPERATIONAL_MANAGEMENT: "NAV_NODE_OPERATIONAL_MANAGEMENT",
    NAV_NODE_OPERATIONAL_RISK_ANALYSIS_GRAPH: "NAV_NODE_OPERATIONAL_RISK_ANALYSIS_GRAPH",
    NAV_NODE_OPERATIONAL_RISK_ANALYSIS: "NAV_NODE_OPERATIONAL_RISK_ANALYSIS",
    NAV_NODE_OTHER_RESOURCES: "NAV_NODE_OTHER_RESOURCES",
    NAV_NODE_PAGES: "NAV_NODE_PAGES",
    NAV_NODE_PERFORMANCE_MANAGEMENT: "NAV_NODE_PERFORMANCE_MANAGEMENT",
    NAV_NODE_POSITIONS: "NAV_NODE_POSITIONS",
    NAV_NODE_PROCESSCHART: "NAV_NODE_PROCESSCHART",
    NAV_NODE_PROCUREMENT_AND_SALES: "NAV_NODE_PROCUREMENT_AND_SALES",
    NAV_NODE_PURCHASE_ORDERS: "NAV_NODE_PURCHASE_ORDERS",
    NAV_NODE_RECURRING_CHECKLIST: "NAV_NODE_RECURRING_CHECKLIST",
    NAV_NODE_REPORTS: "NAV_NODE_REPORTS",
    NAV_NODE_RISK_COMPLIANCE_MANAGEMENT: "NAV_NODE_RISK_COMPLIANCE_MANAGEMENT",
    NAV_NODE_RISK_MANAGEMENT: "NAV_NODE_RISK_MANAGEMENT",
    NAV_NODE_SCHEDULING: "NAV_NODE_SCHEDULING",
    NAV_NODE_SENIOR_MANAGEMENT: "NAV_NODE_SENIOR_MANAGEMENT",
    NAV_NODE_SHARED_TEMPLATE_STEPS: "NAV_NODE_SHARED_TEMPLATE_STEPS",
    NAV_NODE_SINGLE_SIGN_ON: "NAV_NODE_SINGLE_SIGN_ON",
    NAV_NODE_SOURCING_PURCHASE: "NAV_NODE_SOURCING_PURCHASE",
    NAV_NODE_STAFF_APPRAISAL: "STAFF_APPRAISAL_PAGE_TITLE",
    NAV_NODE_STAKEHOLDERS: "NAV_NODE_STAKEHOLDERS",
    NAV_NODE_SUPPLIERS: "NAV_NODE_SUPPLIERS",
    NAV_NODE_TASKS: "NAV_NODE_TASKS",
    NAV_NODE_TEAMS: "NAV_NODE_TEAMS",
    NAV_NODE_TEMPLATES: "NAV_NODE_TEMPLATES",
    NAV_NODE_TENANT_SETTINGS: "NAV_NODE_TENANT_SETTINGS",
    NAV_NODE_TRACEABILITY_REPORT: "NAV_NODE_TRACEABILITY_REPORT",
    NAV_NODE_TRAINING_PLAN: "TRAINING_PLAN_PAGE_TITLE",
    NAV_NODE_USERS: "NAV_NODE_USERS",
    NEED_ATTENTION: "NEED_ATTENTION",
    NEED_FOR_TRAINING: "NEED_FOR_TRAINING",
    NEED_REWORK: "NEED_REWORK",
    NEW_: "NEW_",
    NEW_API_KEY: "NEW_API_KEY",
    NEW_COMPETENCY_MATRIX: "NEW_COMPETENCY_MATRIX",
    NEW_CUSTOM_LIST: "NEW_CUSTOM_LIST",
    NEW_CUSTOMER_CONTACT: "NEW_CUSTOMER_CONTACT",
    NEW_CUSTOMER: "NEW_CUSTOMER",
    NEW_EQUIPMENT: "NEW_EQUIPMENT",
    NEW_EQUIPMENT: "NEW_EQUIPMENT",
    NEW_FILE: "NEW_FILE",
    NEW_FOLDER: "NEW_FOLDER",
    NEW_GOAL: "NEW_GOAL",
    NEW_IMPACT_GRADING: "NEW_IMPACT_GRADING",
    NEW_IMPROVEMENT_FORM: "NEW_IMPROVEMENT_FORM",
    NEW_IMPROVEMENT: "NEW_IMPROVEMENT",
    NEW_ITEM: "NEW_ITEM",
    NEW_LINK: "NEW_LINK",
    NEW_MEASUREMENT: "NEW_MEASUREMENT",
    NEW_OPERATIONAL_RISK_ANALYSIS: "NEW_OPERATIONAL_RISK_ANALYSIS",
    NEW_PAGE: "NEW_PAGE",
    NEW_PASSWORD: "NEW_PASSWORD",
    NEW_PURCHASE_ORDER_LINE_STATUS: "NEW_PURCHASE_ORDER_LINE_STATUS",
    NEW_PURCHASE_ORDER: "NEW_PURCHASE_ORDER",
    NEW_RECURRING_CHECKLIST: "NEW_RECURRING_CHECKLIST",
    NEW_STAFF_APPRAISAL: "NEW_STAFF_APPRAISAL",
    NEW_STATUS: "NEW_STATUS",
    NEW_STEP_VERSION_AVAILABLE: "NEW_STEP_VERSION_AVAILABLE",
    NEW_STEP: "NEW_STEP",
    NEW_SUB_GOAL: "NEW_SUB_GOAL",
    NEW_SUPPLIER: "NEW_SUPPLIER",
    NEW_TASK_ADDED: "NEW_TASK_ADDED",
    NEW_TASK: "NEW TASK",
    NEW_TEAM: "NEW_TEAM",
    NEW_TEMPLATE: "NEW_TEMPLATE",
    NEW_TEMPLATE: "NEW_TEMPLATE",
    NEW_TRAINING_PLAN: "NEW_TRAINING_PLAN",
    NEW_TYPE_UNIT: "NEW_TYPE_UNIT",
    NEW_USER: "NEW_USER",
    NEW_VERSION_AVAILABLE_UPDATING_IN_30_SECONDS: "NEW_VERSION_AVAILABLE_UPDATING_IN_30_SECONDS",
    NEW_WEBHOOK: "NEW_WEBHOOK",
    NEW: "NEW",
    NEXT_CALIBRATION_DATE: "NEXT_CALIBRATION_DATE",
    NEXT_CALIBRATION: "NEXT_CALIBRATION",
    NEXT_MONTH: "NEXT_MONTH",
    NEXT_WEEK: "NEXT_WEEK",
    NEXT_YEAR: "NEXT_YEAR",
    NEXT: "NEXT",
    NO_ACTION_PLAN_ADDED_YET: "NO_ACTION_PLAN_ADDED_YET",
    NO_ACTIONS_TAKEN: "NO_ACTIONS_TAKEN",
    NO_ACTIVITES_IN_THIS_IMPROVEMENT_FORM_YET: "NO_ACTIVITES_IN_THIS_IMPROVEMENT_FORM_YET",
    NO_ACTIVITES_IN_THIS_STEP_YET: "NO_ACTIVITES_IN_THIS_STEP_YET",
    NO_AVAILABLE_CHECKLIST_FOUND_FOR_YOUR_USER_ROLE: "NO_AVAILABLE_CHECKLIST_FOUND_FOR_YOUR_USER_ROLE",
    NO_AVAILABLE_CHECKLISTS_FOUND_FOR_YOUR_USER_ROLE: "NO_AVAILABLE_CHECKLISTS_FOUND_FOR_YOUR_USER_ROLE",
    NO_AVAILABLE_CUSTOMLIST_FOUND_FOR_YOUR_USER_ROLE: "NO_AVAILABLE_CUSTOMLIST_FOUND_FOR_YOUR_USER_ROLE",
    NO_AVAILABLE_CUSTOMLISTITEM_FOUND_FOR_YOUR_USER_ROLE: "NO_AVAILABLE_CUSTOMLISTITEM_FOUND_FOR_YOUR_USER_ROLE",
    NO_AVAILABLE_GOALS_FOUND_FOR_YOUR_USER_ROLE: "NO_AVAILABLE_GOALS_FOUND_FOR_YOUR_USER_ROLE",
    NO_AVAILABLE_IMPROVEMENT_FOUND_FOR_YOUR_USER_ROLE: "NO_AVAILABLE_IMPROVEMENT_FOUND_FOR_YOUR_USER_ROLE",
    NO_AVAILABLE_IMPROVEMENTS_FOUND_FOR_YOUR_USER_ROLE: "NO_AVAILABLE_IMPROVEMENTS_FOUND_FOR_YOUR_USER_ROLE",
    NO_AVAILABLE_KPIS_FOUND_FOR_YOUR_USER_ROLE: "NO_AVAILABLE_KPIS_FOUND_FOR_YOUR_USER_ROLE",
    NO_AVAILABLE_MEASUREMENTS_FOUND_FOR_YOUR_USER_ROLE: "NO_AVAILABLE_MEASUREMENTS_FOUND_FOR_YOUR_USER_ROLE",
    NO_AVAILABLE_TEMPLATES_FOUND_FOR_YOUR_USER_ROLE: "NO_AVAILABLE_TEMPLATES_FOUND_FOR_YOUR_USER_ROLE",
    NO_AVAILABLE_WORKSHEETS_FOUND_FOR_YOUR_USER_ROLE: "NO_AVAILABLE_WORKSHEETS_FOUND_FOR_YOUR_USER_ROLE",
    NO_BIO_AVAILABLE: "NO_BIO_AVAILABLE",
    NO_CHECKLIST_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_YOU_CAN_ADD_YOUR_OWN_TASKS_BY_CLICKING_THE_BUTTON_BELOW: "NO_CHECKLIST_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_YOU_CAN_ADD_YOUR_OWN_TASKS_BY_CLICKING_THE_BUTTON_BELOW",
    NO_CHECKLIST_LEVEL_TASKS_WERE_ADDED_FOR_THE_ACTIVITY: "NO_CHECKLIST_LEVEL_TASKS_WERE_ADDED_FOR_THE_ACTIVITY",
    NO_CHECKLIST_OR_NOT_EXIST: "NO_CHECKLIST_OR_NOT_EXIST",
    NO_CHECKLIST_TO_SHOW: "NO_CHECKLIST_TO_SHOW",
    NO_COMMENT_PROVIDED: "NO_COMMENT_PROVIDED",
    NO_COMPLETED_COURSES: "NO_COMPLETED_COURSES",
    NO_CONTACTS_AVAILABLE_FOR_THIS_CUSTOMER: "NO_CONTACTS_AVAILABLE_FOR_THIS_CUSTOMER",
    NO_CONTACTS_AVAILABLE_FOR_THIS_SUPPLIER: "NO_CONTACTS_AVAILABLE_FOR_THIS_SUPPLIER",
    NO_CUSTOMERS_AVAILABLE: "NO_CUSTOMERS_AVAILABLE",
    NO_DESCRIPTION_PROVIDED: "NO_DESCRIPTION_PROVIDED",
    NO_DEVIATION_MESSAGE_SUBMITTED: "NO_DEVIATION_MESSAGE_SUBMITTED",
    NO_EQUIPMENT_AVAILABLE: "NO_EQUIPMENT_AVAILABLE",
    NO_EQUIPMENT_TO_SHOW: "NO_EQUIPMENT_TO_SHOW",
    NO_EQUIPMENT: "NO_EQUIPMENT",
    NO_GOAL_AVAILABLE: "NO_GOAL_AVAILABLE",
    NO_IMPROVEMENT_FORM_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN_TASKS_IN_IMPROVEMENTS: "NO_IMPROVEMENT_FORM_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN_TASKS_IN_IMPROVEMENTS",
    NO_IMPROVEMENT_FORMS_SUBTITLE:"NO_IMPROVEMENT_FORMS_SUBTITLE",
    NO_IMPROVEMENT_FORMS_TITLE:"NO_IMPROVEMENT_FORMS_TITLE",
    NO_IMPROVEMENT_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_YOU_CAN_ADD_YOUR_OWN_TASKS_BY_CLICKING_THE_BUTTON_BELOW: "NO_IMPROVEMENT_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_YOU_CAN_ADD_YOUR_OWN_TASKS_BY_CLICKING_THE_BUTTON_BELOW",
    NO_IMPROVEMENT_LEVEL_TASKS_WERE_ADDED_FOR_THE_ACTIVITY: "NO_IMPROVEMENT_LEVEL_TASKS_WERE_ADDED_FOR_THE_ACTIVITY",
    NO_IMPROVEMENTS_FOUND: "NO_IMPROVEMENTS_FOUND",
    NO_IMPROVEMENTS_TO_SHOW: "NO_IMPROVEMENTS_TO_SHOW",
    NO_ITEMS_AVAILABLE: "NO_ITEMS_AVAILABLE",
    NO_KPI_AVAILABLE: "NO_KPI_AVAILABLE",
    NO_KPI_UNIT_PROVIDED: "NO_KPI_UNIT_PROVIDED",
    NO_LABEL: "NO_LABEL",
    NO_MATCHES: "NO_MATCHES",
    NO_MEASUREMENTS_ADDED_YET: "NO_MEASUREMENTS_ADDED_YET",
    NO_MORE_RUNS_TO_LOAD: "NO_MORE_RUNS_TO_LOAD",
    NO_NOTES_PROVIDED: "NO_NOTES_PROVIDED",
    NO_ONE_ASSIGNED: "NO_ONE_ASSIGNED",
    NO_ONE_CAN_VIEW: "NO_ONE_CAN_VIEW",
    NO_ONE_HAS_ACCESS_ALERT_MESSAGE: "NO_ONE_HAS_ACCESS_ALERT_MESSAGE",
    NO_ONE_HAS_ACCESS_TO_CUSTOM_LIST_ALERT_MESSAGE: "NO_ONE_HAS_ACCESS_TO_CUSTOM_LIST_ALERT_MESSAGE",
    NO_ONE_IS_HERE: "NO_ONE_IS_HERE",
    NO_PERMISSION_TO_EDIT_TEMPLATE: "NO_PERMISSION_TO_EDIT_TEMPLATE",
    NO_PHOTO: "NO_PHOTO",
    NO_PO_LINE_STATUSES_AVAILABLE: "NO_PO_LINE_STATUSES_AVAILABLE",
    NO_PROCESS_AVAILABLE_RIGHT_NOW: "NO_PROCESS_AVAILABLE_RIGHT_NOW",
    NO_PROGRESS_TO_SHOW: "NO_PROGRESS_TO_SHOW",
    NO_PURCHASE_ORDERS_AVAILABLE: "NO_PURCHASE_ORDERS_AVAILABLE",
    NO_RECURRING_CHECKLISTS_TO_SHOW: "NO_RECURRING_CHECKLISTS_TO_SHOW",
    NO_RECURRING_OR_NOT_EXIST: "NO_RECURRING_OR_NOT_EXIST",
    NO_RESULTS: "NO_RESULTS",
    NO_RISK_UNPUBLISHED_PROCESS_STEP: "NO_RISK_UNPUBLISHED_PROCESS_STEP",
    NO_RUNS_FINALIZED: "NO_RUNS_FINALIZED",
    NO_SCHEDULED_COURSES: "NO_SCHEDULED_COURSES",
    NO_STATUS: "NO_STATUS",
    NO_STEP: "NO_STEPS",
    NO_STEPS_TO_SHOW: "NO_STEPS_TO_SHOW",
    NO_STEPS: "NO_STEPS",
    NO_SUB_GOALS_ADDED_YET: "NO_SUB_GOALS_ADDED_YET",
    NO_SUMMARY_AVAILABLE: "NO_SUMMARY_AVAILABLE",
    NO_SUPPLIERS_AVAILABLE: "NO_SUPPLIERS_AVAILABLE",
    NO_TASKS_ADDED_YET_PLEASE_ADD_YOUR_OWN: "NO_TASKS_ADDED_YET_PLEASE_ADD_YOUR_OWN",
    NO_TASKS_ADDED_YET_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN: "NO_TASKS_ADDED_YET_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN",
    NO_TASKS_AVAILABLE: "NO_TASKS_AVAILABLE",
    NO_TEAM_AVAILABLE: "NO_TEAM_AVAILABLE",
    NO_TEAM_ROLES: "NO_TEAM_ROLES",
    NO_TEAM_TO_SHOW: "NO_TEAM_TO_SHOW",
    NO_TEMPLATE_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN_TASKS_IN_RUNNING_CHECKLISTS: "NO_TEMPLATE_LEVEL_TASKS_ARE_ADDED_FOR_THE_ACTIVITY_USERS_WILL_BE_ABLE_TO_ADD_THEIR_OWN_TASKS_IN_RUNNING_CHECKLISTS",
    NO_TEMPLATES_ASSIGNED: "NO_TEMPLATES_ASSIGNED",
    NO_TEMPLATES_SUBTITLE1: "NO_TEMPLATES_SUBTITLE1",
    NO_TEMPLATES_SUBTITLE2: "NO_TEMPLATES_SUBTITLE2",
    NO_TEMPLATES_TITLE: "NO_TEMPLATES_TITLE",
    NO_TITLE: "NO_TITLE",
    NO_UNIT: "NO_UNIT",
    NO_USERS_ASSIGNED_TO_THIS_GOAL: "NO_USERS_ASSIGNED_TO_THIS_GOAL",
    NO_USERS_ASSIGNED_TO_THIS_MEASUREMENT: "NO_USERS_ASSIGNED_TO_THIS_MEASUREMENT",
    NO_USERS_ASSIGNED: "NO_USERS_ASSIGNED",
    NO_USERS_AVAILABLE: "NO_USERS_AVAILABLE",
    NO_USERS_OR_TEAMS_HAVE_THIS_ROLE_ASSIGNED_TO_THEM_YET: "NO_USERS_OR_TEAMS_HAVE_THIS_ROLE_ASSIGNED_TO_THEM_YET",
    NO_USERS_TO_SHOW: "NO_USERS_TO_SHOW",
    NO_VALUE: "NO_VALUE",
    NO_ZERO_DURATION: "NO_ZERO_DURATION",
    NO: "NO",
    NONE_ASSIGNED: "NONE_ASSIGNED",
    NONE_SELECTED_P: "NONE_SELECTED_P",
    NONE_SELECTED: "NONE_SELECTED",
    NONE: "NONE",
    NOT_ACTIONABLE: "NOT_ACTIONABLE",
    NOT_APPLICABLE: "NOT_APPLICABLE",
    NOT_ARCHIVED: "NOT_ARCHIVED",
    NOT_AVAILABLE_MESSAGE: "NOT_AVAILABLE_MESSAGE",
    NOT_AVAILABLE: "NOT_AVAILABLE",
    NOT_COMPLETED: "NOT_COMPLETED",
    NOT_FOUND: "NOT_FOUND",
    NOT_OK: "NOT_OK",
    NOT_SPECIFIED: "NOT_SPECIFIED",
    NOTE: "NOTE",
    NOTES: "NOTES",
    NOTHING_FOUND: "NOTHING_FOUND",
    NOTIFICATION: "NOTIFICATION",
    NOTIFY: "NOTIFY",
    NOV: "NOV",
    NOVEMBER: "NOVEMBER",
    NUMBER_OF_DECIMAL_PLACES: "NUMBER_OF_DECIMAL_PLACES",
    NUMBER_OF_EMPLOYEES_DESCRIPTION: "NUMBER_OF_EMPLOYEES_DESCRIPTION",
    NUMBER_OF_EMPLOYEES_EXAMPLE: "NUMBER_OF_EMPLOYEES_EXAMPLE",
    NUMBER_OF_EMPLOYEES: "NUMBER_OF_EMPLOYEES",
    NUMBER_OF_IMPROVEMENTS_BROKEN_DOWN_BY_STATUS: "NUMBER_OF_IMPROVEMENTS_BROKEN_DOWN_BY_STATUS",
    NUMBER_OF_RUNS: "NUMBER_OF_RUNS",
    NUMBERS_SHOULD_BE_GREATER_THAN_ZERO: "NUMBERS_SHOULD_BE_GREATER_THAN_ZERO",
    OBSOLETE: "OBSOLETE",
    OCCURENCE: "OCCURENCE",
    OCT: "OCT",
    OCTOBER: "OCTOBER",
    OF_THE_MONTH: "OF_THE_MONTH",
    OF: "OF",
    OK: "OK",
    OLD_VERSION: "OLD_VERSION",
    ON_A_FIXED_DATE: "ON_A_FIXED_DATE",
    ON_THESE_DAYS: "ON_THESE_DAYS",
    ON_THESE_MONTHS: "ON_THESE_MONTHS",
    ON_TRACK_: "ON_TRACK_",
    ON_TRACK: "ON_TRACK",
    ON: "ON",
    OPEN_AMPLIFLOW_IN_BROWSER_TO_PASTE_FROM_CLIPBOARD: "OPEN_AMPLIFLOW_IN_BROWSER_TO_PASTE_FROM_CLIPBOARD",
    OPEN_IMPROVEMENTS_TABLE_HEADER: "OPEN_IMPROVEMENTS_TABLE_HEADER",
    OPEN: "OPEN",
    OPENs: "OPENs",
    OPERATIONAL_RISK_ANALYSIS_FEATURE_DESCRIPTION: "OPERATIONAL_RISK_ANALYSIS_FEATURE_DESCRIPTION",
    OPERATIONAL_RISK_ANALYSIS_GRAPH_FEATURE_DESCRIPTION: "OPERATIONAL_RISK_ANALYSIS_GRAPH_FEATURE_DESCRIPTION",
    OPERATIONAL_RISK_ANALYSIS_GRAPH: "OPERATIONAL_RISK_ANALYSIS_GRAPH",
    OPERATIONAL_RISK_ANALYSIS_PAGE_TITLE: "OPERATIONAL_RISK_ANALYSIS_PAGE_TITLE",
    OPERATORS: "OPERATORS",
    OPTIONAL_PLACEHOLDER: "OPTIONAL_PLACEHOLDER",
    OPTIONAL: "OPTIONAL",
    OPTIONS: "OPTIONS",
    OR_ENTER_BARCODE_HERE: "OR_ENTER_BARCODE_HERE",
    OR: "OR",
    ORA_EDITOR_INFO: "ORA_EDITOR_INFO",
    ORA_EDITOR: "ORA_EDITOR",
    ORA_OWNER_INFO: "ORA_OWNER_INFO",
    ORA_OWNER: "ORA_OWNER",
    ORA_VIEWER_INFO: "ORA_VIEWER_INFO",
    ORA_VIEWER: "ORA_VIEWER",
    ORIGIN: "ORIGIN",
    ORIGINATED_IN_PROCESS_FILTER: "ORIGINATED_IN_PROCESS_FILTER",
    ORIGINATED_IN_T: "ORIGINATED_IN_T",
    ORIGINATED_IN: "ORIGINATED_IN",
    OTHER_NOT_SURE: "OTHER_NOT_SURE",
    OTHER_USERS: "OTHER_USERS",
    OTHER: "OTHER",
    OUR_KNOWLEDGE_GURU: "OUR_KNOWLEDGE_GURU",
    OUT_OF_: "OUT_OF_",
    OUTPUT_TO: "OUTPUT_TO",
    OUTPUTS: "OUTPUTS",
    OVER_TARGET_IS_GOOD: "OVER_TARGET_IS_GOOD",
    OVER_THRESHOLD: "OVER_THRESHOLD",
    OVERALL_TOTALS_FOR_THE_FORM: "OVERALL_TOTALS_FOR_THE_FORM",
    OVERVIEW: "OVERVIEW",
    OWNER_FOR_SELECTED_DISCOVERED_IN_PROCESS: "OWNER_FOR_SELECTED_DISCOVERED_IN_PROCESS",
    OWNER_FOR_SELECTED_ORIGINATED_IN_PROCESS: "OWNER_FOR_SELECTED_ORIGINATED_IN_PROCESS",
    OWNER: "OWNER",
    PAGE_: "PAGE_",
    PAGE_NOT_FOUND: "PAGE_NOT_FOUND",
    PAGE: "PAGE",
    PAGES: "PAGES",
    PARENT_GOAL: "PARENT_GOAL",
    PARENT_GOALS: "PARENT_GOALS",
    PARENTHESES: "PARENTHESES",
    PART_OF_SCHEDULE: "PART_OF_SCHEDULE",
    PASSWORD_PLACEHOLDER: "PASSWORD_PLACEHOLDER",
    PASSWORD: "PASSWORD",
    PASTE_FAILED: "PASTE_FAILED",
    PASTE_FROM_CLIPBOARD: "PASTE_FROM_CLIPBOARD",
    PASTE: "PASTE",
    PAUSE: "PAUSE",
    PAUSED_BY: "PAUSED_BY",
    PAUSED: "PAUSED",
    PAYLOAD: "PAYLOAD",
    PEOPLE_TO_BE_TRAINED: "PEOPLE_TO_BE_TRAINED",
    PERCENTAGE_OF_QUANTITY_TARGET: "PERCENTAGE_OF_QUANTITY_TARGET",
    PERCENTAGE: "PERCENTAGE",
    PERFORMANCE_EVALUATION: "PERFORMANCE_EVALUATION",
    PERMANENTLY_REMOVE: "PERMANENTLY_REMOVE",
    PERMISSION_DESCRIPTION: "PERMISSION_DESCRIPTION",
    PERMISSION_SETTINGS_FOR: "PERMISSION_SETTINGS_FOR",
    PERMISSION_SETTINGS: "PERMISSION_SETTINGS",
    PERSONAL_AMBITION: "PERSONAL_AMBITION",
    PHONE_MOBILE: "PHONE_MOBILE",
    PHONE: "PHONE",
    PHOTO_PREVIEW: "PHOTO_PREVIEW",
    PHOTO: "PHOTO",
    PLACEHOLDER_: "PLACEHOLDER_",
    PLACEHOLDER_FOR_INPUT_FIELD: "PLACEHOLDER_FOR_INPUT_FIELD",
    PLACEHOLDER: "PLACEHOLDER",
    PLANNED_FOR: "PLANNED_FOR",
    PLANNED_SCHEDULE_FOR: "PLANNED_SCHEDULE_FOR",
    PLANNER: "PLANNER",
    PLEASE_CONFIRM: "PLEASE_CONFIRM",
    PLEASE_FORWARD_LOGIN_DETAILS_TO_USER_AFTER_SAVING: "PLEASE_FORWARD_LOGIN_DETAILS_TO_USER_AFTER_SAVING",
    PLEASE_REMOVE_THE_POSITION_FROM_THE_COMPETENCIES_FIRST_AND_TRY_AGAIN: "PLEASE_REMOVE_THE_POSITION_FROM_THE_COMPETENCIES_FIRST_AND_TRY_AGAIN",
    PLEASE_REMOVE_THE_POSITION_FROM_THE_USERS_FIRST_AND_TRY_AGAIN: "PLEASE_REMOVE_THE_POSITION_FROM_THE_USERS_FIRST_AND_TRY_AGAIN",
    PLEASE_REMOVE_THE_TEAM_FROM_THE_CHECKLISTS_FIRST_AND_TRY_AGAIN: "PLEASE_REMOVE_THE_TEAM_FROM_THE_CHECKLISTS_FIRST_AND_TRY_AGAIN",
    PLEASE_REMOVE_THE_TEAM_FROM_THE_GOAL_FIRST_AND_TRY_AGAIN: "PLEASE_REMOVE_THE_TEAM_FROM_THE_GOAL_FIRST_AND_TRY_AGAIN",
    PLEASE_REMOVE_THE_TEAM_FROM_THE_KPI_FIRST_AND_TRY_AGAIN: "PLEASE_REMOVE_THE_TEAM_FROM_THE_KPI_FIRST_AND_TRY_AGAIN",
    PLEASE_REMOVE_THE_TEAM_FROM_THE_MEASUREMENT_FIRST_AND_TRY_AGAIN: "PLEASE_REMOVE_THE_TEAM_FROM_THE_MEASUREMENT_FIRST_AND_TRY_AGAIN",
    PLEASE_REMOVE_THE_TEAM_FROM_THE_PROCESS_FIRST_AND_TRY_AGAIN: "PLEASE_REMOVE_THE_TEAM_FROM_THE_PROCESS_FIRST_AND_TRY_AGAIN",
    PLEASE_REMOVE_THE_TEAM_FROM_THE_STEP_FIRST_AND_TRY_AGAIN: "PLEASE_REMOVE_THE_TEAM_FROM_THE_STEP_FIRST_AND_TRY_AGAIN",
    PLEASE_REMOVE_THE_TEAM_FROM_THE_SUBPROCESS_FIRST_AND_TRY_AGAIN: "PLEASE_REMOVE_THE_TEAM_FROM_THE_SUBPROCESS_FIRST_AND_TRY_AGAIN",
    PLEASE_REMOVE_THE_TEAM_FROM_THE_TEMPLATE_FIRST_AND_TRY_AGAIN: "PLEASE_REMOVE_THE_TEAM_FROM_THE_TEMPLATE_FIRST_AND_TRY_AGAIN",
    POSITION_CANNOT_BE_DELETED_1: "POSITION_CANNOT_BE_DELETED_1",
    POSITION_CANNOT_BE_DELETED_2: "POSITION_CANNOT_BE_DELETED_2",
    POSITION_DELETED: "POSITION_DELETED",
    POSITION_EDITOR_INFO: "POSITION_EDITOR_INFO",
    POSITION_EDITOR: "POSITION_EDITOR",
    POSITION_IS_USED_FOR_COMPETENCIES: "POSITION_IS_USED_FOR_COMPETENCIES",
    POSITION_IS_USED_FOR_USERS: "POSITION_IS_USED_FOR_USERS",
    POSITION_OWNER_INFO: "POSITION_OWNER_INFO",
    POSITION_OWNER: "POSITION_OWNER",
    POSITION_VIEWER_INFO: "POSITION_VIEWER_INFO",
    POSITION_VIEWER: "POSITION_VIEWER",
    POSITION_WILL_BE_REMOVED: "POSITION_WILL_BE_REMOVED",
    POSITION: "POSITION",
    POSITIONS_FEATURE_DESCRIPTION: "POSITIONS_FEATURE_DESCRIPTION",
    POSITIONS_PAGE_SUBTITLE: "POSITIONS_PAGE_SUBTITLE",
    POSITIONS_PAGE_TITLE: "POSITIONS_PAGE_TITLE",
    POSITIONS: "POSITIONS",
    POSTAL_CODE: "POSTAL_CODE",
    POSTPONED: "POSTPONED",
    POTENTIAL_CONSEQUENCES: "POTENTIAL_CONSEQUENCES",
    PRESENT: "PRESENT",
    PREVIEW: "PREVIEW",
    PREVIOUS_YEAR: "PREVIOUS_YEAR",
    PREWRITTEN_REASONS: "PREWRITTEN_REASONS",
    PRIMARY_CONTACT_PHONE: "PRIMARY_CONTACT_PHONE",
    PRINT: "PRINT",
    PRIORITY: "PRIORITY",
    PRIVACY_POLICY: "PRIVACY_POLICY",
    PROBABILITY_REDUCTION: "PROBABILITY_REDUCTION",
    PROBABILITY: "PROBABILITY",
    PROCESS_CHART: "PROCESS_CHART",
    PROCESS_CHERT_FEATURE_DESCRIPTION: "PROCESS_CHERT_FEATURE_DESCRIPTION",
    PROCESS_STEP: "PROCESS_STEP",
    PROCESS: "PROCESS",
    PROCESSES: "PROCESSES",
    PROFESSIONAL_EXPERIENCE: "PROFESSIONAL_EXPERIENCE",
    PROFILE_SAVED: "PROFILE_SAVED",
    PROFILE: "PROFILE",
    PROGRESS_ARCHIVED: "PROGRESS_ARCHIVED",
    PROGRESS_DATE: "PROGRESS_DATE",
    PROGRESS_OVER_TIME: "PROGRESS_OVER_TIME",
    PROGRESS_UNARCHIVED: "PROGRESS_UNARCHIVED",
    PROGRESS_VALUE_CREATED: "PROGRESS_VALUE_CREATED",
    PROGRESS_VALUE_DELETED: "PROGRESS_VALUE_DELETED",
    PROGRESS_VALUE: "PRGOGRESS_VALUE",
    PROGRESS: "PROGRESS",
    PROMILLE: "PROMILLE",
    PROPERTIES: "PROPERTIES",
    PROPERTY_REQUIRED: "PROPERTY_REQUIRED",
    PROPERTY_WILL_BE_VISIBLE_IN_PARENT_LIST: "PROPERTY_WILL_BE_VISIBLE_IN_PARENT_LIST",
    PROVESSLA: "PROVESSLA",
    PUBLISH: "PUBLISH",
    PUBLISHED_SHARED_STEPS: "PUBLISHED_SHARED_STEPS",
    PUBLISHED_TEMPLATES: "PUBLISHED_TEMPLATES",
    PUBLISHED_WORKFLOWS: "PUBLISHED_WORKFLOWS",
    PUBLISHED: "PUBLISHED",
    PURCHASE_ORDER_ROW_STATUS_QUICK_INFORMATION: "PURCHASE_ORDER_ROW_STATUS_QUICK_INFORMATION",
    PURCHASE_ORDERS_EDITOR_INFO: "PURCHASE_ORDERS_EDITOR_INFO",
    PURCHASE_ORDERS_EDITOR: "PURCHASE_ORDERS_EDITOR",
    PURCHASE_ORDERS_FEATURE_DESCRIPTION: "PURCHASE_ORDERS_FEATURE_DESCRIPTION",
    PURCHASE_ORDERS_OWNER_INFO: "PURCHASE_ORDERS_OWNER_INFO",
    PURCHASE_ORDERS_OWNER: "PURCHASE_ORDERS_OWNER",
    PURCHASE_ORDERS_SUBTITLE: "PURCHASE_ORDERS_SUBTITLE",
    PURCHASE_ORDERS_VIEWER_INFO: "PURCHASE_ORDERS_VIEWER_INFO",
    PURCHASE_ORDERS_VIEWER: "PURCHASE_ORDERS_VIEWER",
    PURPOSE: "PURPOSE",
    QTY: "QTY",
    QUALITY_DELIVERY_OF_PRODUCT: "QUALITY_DELIVERY_OF_PRODUCT",
    QUALITY_DELIVERY_OF_SERVICES: "QUALITY_DELIVERY_OF_SERVICES",
    QUALITY_PRODUCT_FUNCTIONALITY: "QUALITY_PRODUCT_FUNCTIONALITY",
    QUALITY_PRODUCT_SAFETY: "QUALITY_PRODUCT_SAFETY",
    QUANTITY_TARGET: "QUANTITY_TARGET",
    QUARTER_S: "QUARTER_S",
    QUARTERLY: "QUARTERLY",
    QUICK_INFORMATION: "QUICK_INFORMATION",
    QUICK_RUN_NAME_PLACEHOLDER: "QUICK_RUN_NAME_PLACEHOLDER",
    RATING: "RATING",
    RE_OPEN: "RE_OPEN",
    READ_LESS: "READ_LESS",
    READ_MORE: "READ_MORE",
    READER: "READER",
    READY_P: "READY_P",
    READY: "READY",
    REALISTIC: "REALISTIC",
    RECORD: "RECORD",
    RECUR_EVERY: "RECUR_EVERY",
    RECURRENCE_SHOULD_HAPPEN: "RECURRENCE_SHOULD_HAPPEN",
    RECURRENCE_SUMMARY1: "RECURRENCE_SUMMARY1",
    RECURRENCE_SUMMARY2: "RECURRENCE_SUMMARY2",
    RECURRENCE_SUMMARY3: "RECURRENCE_SUMMARY3",
    RECURRENCE_SUMMARY4: "RECURRENCE_SUMMARY4",
    RECURRENCE_SUMMARY5: "RECURRENCE_SUMMARY5",
    RECURRENCE_SUMMARY6: "RECURRENCE_SUMMARY6",
    RECURRENCE_SUMMARY7: "RECURRENCE_SUMMARY7",
    RECURRENCE_SUMMARY8: "RECURRENCE_SUMMARY8",
    RECURRING_CHECKLIST_DELETE_DESCRIPTION: "RECURRING_CHECKLIST_DELETE_DESCRIPTION",
    RECURRING_CHECKLIST_DETAILS_SUBTITLE: "RECURRING_CHECKLIST_DETAILS_SUBTITLE",
    RECURRING_CHECKLIST_DETAILS: "RECURRING_CHECKLIST_DETAILS",
    RECURRING_CHECKLIST_PAGE_SUBTITLE: "RECURRING_CHECKLIST_PAGE_SUBTITLE",
    RECURRING_CHECKLIST: "RECURRING_CHECKLIST",
    RED: "RED",
    REDIRECT: "REDIRECT",
    REDIRECTING_TO_HOME_PAGE: "REDIRECTING_TO_HOME_PAGE",
    REDUDANT_OPERATOR: "REDUDANT_OPERATOR",
    REF_PERSON: "REF_PERSON",
    REFERENCE_LINK: "REFERENCE_LINK",
    REFERENCE: "REFERENCE",
    REFRESH: "REFRESH",
    REGISTER_AND_REVIEW: "REGISTER_AND_REVIEW",
    REGISTER_IMPROVEMENT_FOR: "REGISTER_IMPROVEMENT_FOR",
    REGISTER_IMPROVEMENT: "REGISTER_IMPROVEMENT",
    REGISTER_NEW_IMPROVEMENT: "REGISTER_NEW_IMPROVEMENT",
    REGISTER: "REGISTER",
    REGISTERED: "REGISTERED",
    REGULAR_IMPROVEMENTS: "REGULAR_IMPROVEMENTS",
    REJECTED: "REJECTED",
    RELEVANT_FOR_SUBPROCESS_ES: "RELEVANT_FOR_SUBPROCESS_ES",
    REMEMBER_ME: "REMEMBER_ME",
    REMOVE_FROM_TEMPLATE: "REMOVE_FROM_TEMPLATE",
    REMOVE_SELF_STEP_INSTANCE_BODY1: "REMOVE_SELF_STEP_INSTANCE_BODY1",
    REMOVE_SELF_STEP_INSTANCE_BODY2: "REMOVE_SELF_STEP_INSTANCE_BODY2",
    REMOVE_SELF_STEP_INSTANCE_TITLE: "REMOVE_SELF_STEP_INSTANCE_TITLE",
    REMOVE_SELF_STEP_INSTANCE_YES: "REMOVE_SELF_STEP_INSTANCE_YES",
    REMOVE: "REMOVE",
    REMOVED_AS_INVOLVED_FROM: "REMOVED_AS_INVOLVED_FROM",
    REMOVED: "REMOVED",
    RENAME: "RENAME",
    REORDER: "REORDER",
    REPEAT_UNTIL: "REPEAT_UNTIL",
    REPETITION_OF_OPERATORS: "REPETITION_OF_OPERATORS",
    REPLACE_FILE: "REPLACE_FILE...",
    REPLACE: "REPLACE",
    REPORT_NEW_IMPROVEMENT_OR_TRY_DIFFERENT_SEARCH_KEYWORDS: "REPORT_NEW_IMPROVEMENT_OR_TRY_DIFFERENT_SEARCH_KEYWORDS",
    REPORTED_BY: "REPORTED_BY",
    REPORTED_FROM: "REPORTED_FROM",
    REPORTED_IMPROVEMENT: "REPORTED_IMPROVEMENT",
    REPORTED_IMPROVEMENTS: "REPORTED_IMPROVEMENTS",
    REPORTED: "REPORTED",
    REPORTING_USERS_MANAGER: "REPORTING_USERS_MANAGER",
    REQUEST_TRANSLATION_MESSAGE: "REQUEST_TRANSLATION_MESSAGE",
    REQUEST_TRANSLATION: "REQUEST_TRANSLATION",
    REQUIRED: "REQUIRED",
    RESET_WONT_BE_SAVE_UNTIL_YOU_SAVE_SUB_PROCESS_PAGE: "RESET_WONT_BE_SAVE_UNTIL_YOU_SAVE_SUB_PROCESS_PAGE",
    RESET_ZOOM: "RESET_ZOOM",
    RESET: "RESET",
    RESOLVED_FULLY: "RESOLVED_FULLY",
    RESOLVED_PARTIALLY: "RESOLVED_PARTIALLY",
    RESOURCES: "RESOURCES",
    RESPONSE_DETAILS: "RESPONSE_DETAILS",
    RESPONSIBLE_MANAGER: "RESPONSIBLE_MANAGER",
    RESPONSIBLE_USERS: "RESPONSIBLE_USERS",
    RESPONSIBLE: "RESPONSIBLE",
    RESTORE: "RESTORE",
    RESTRICTED: "RESTRICTED",
    RESULT: "RESULT",
    RESULTS: "RESULTS",
    RESUME: "RESUME",
    RETRY_MESSAGE: "RETRY_MESSAGE",
    RETURN_TO: "RETURN_TO",
    REVIEW_AND_SAVE_TIP1: "REVIEW_AND_SAVE_TIP1",
    REVIEW_AND_SAVE: "REVIEW_AND_SAVE",
    REVIEW_REPORTED_INFORMATION: "REVIEW_REPORTED_INFORMATION",
    REVIEW: "REVIEW",
    RISK_CLASSIFICATION_ENVIRONMENT: "RISK_CLASSIFICATION_ENVIRONMENT",
    RISK_NO: "RISK_NO",
    RISK_REDUCTION: "RISK_REDUCTION",
    RISK_SCENARIO: "RISK_SCENARIO",
    RISK_SCORE: "RISK_SCORE",
    RISKS: "RISKS",
    ROLE_FROM_TEAM: "ROLE_FROM_TEAM",
    ROLE: "ROLE",
    ROLES: "ROLES",
    RUN_EVERY: "RUN_EVERY",
    RUN_ID: "RUN_ID",
    RUN_TEMPLATE_ON: "RUN_TEMPLATE_ON",
    RUN: "RUN",
    RUNS_DONE: "RUNS_DONE",
    RUNS_OF_THE_TEMPLATE: "RUNS_OF_THE_TEMPLATE",
    SA_EDITOR_INFO: "SA_EDITOR_INFO",
    SA_EDITOR: "SA_EDITOR",
    SA_OWNER_INFO: "SA_OWNER_INFO",
    SA_OWNER: "SA_OWNER",
    SA_USER_INFO: "SA_USER_INFO",
    SA_USER: "SA_USER",
    SALES_: "SALES_",
    SATURDAY: "SATURDAY",
    SAVE_ACTIVITY: "SAVE_ACTIVITY",
    SAVE_AND_CLOSE: "SAVE_AND_CLOSE",
    SAVE_AND_PUBLISH: "SAVE_AND_PUBLISH",
    SAVE_AS_DRAFT: "SAVE_AS_DRAFT",
    SAVE_DRAFT: "SAVE_DRAFT",
    SAVE_FAILED: "SAVE_FAILED",
    SAVE: "SAVE",
    SAVED_VALUES: "SAVED_VALUES",
    SAVING: "SAVING",
    SCAN_BARCODE: "SCAN_BARCODE",
    SCAN_OR_ENTER_BARCODE: "SCAN_OR_ENTER_BARCODE",
    SCHEDULE_: "SCHEDULE_",
    SCHEDULE_COMMENT: "SCHEDULE_COMMENT",
    SCHEDULE_ENDS: "SCHEDULE_ENDS",
    SCHEDULE_INFORMATION: "SCHEDULE_INFORMATION",
    SCHEDULE_OPTIONAL_COMMENT_MESSAGE: "SCHEDULE_OPTIONAL_COMMENT_MESSAGE",
    SCHEDULE_PAUSED_BY: "SCHEDULE_PAUSED_BY",
    SCHEDULE_RUNS_ON: "SCHEDULE_RUNS_ON",
    SCHEDULE_START: "SCHEDULE_START",
    SCHEDULE_TYPE: "SCHEDULE_TYPE",
    SCHEDULE: "SCHEDULE",
    SCHEDULED_: "SCHEDULED_",
    SCHEDULED_COURSES: "SCHEDULED_COURSES",
    SCHEDULED: "SCHEDULED",
    SCOPE_OF_MANAGEMENT: "SCOPE_OF_MANAGEMENT",
    SCORE_EXPLANATION: "SCORE_EXPLANATION",
    SEARCH_CHECKLIST_TEMPLATE: "SEARCH_CHECKLIST_TEMPLATE",
    SEARCH_CHECKLIST_TITLE: "SEARCH_CHECKLIST_TITLE",
    SEARCH_HERE: "SEARCH_HERE",
    SEARCH_IMPROVEMENT_FORM: "SEARCH_IMPROVEMENT_FORM",
    SEARCH_MESSAGE: "SEARCH_MESSAGE",
    SEARCH_NAME_AND_STATUS: "SEARCH_NAME_AND_STATUS",
    SEARCH_NAME_OR_TEMPLATE: "SEARCH_NAME_OR_TEMPLATE",
    SEARCH_TEXTFIELDS: "SEARCH_TEXTFIELDS",
    SEARCH: "SEARCH",
    SECOND_: "SECOND_",
    SECOND: "SECOND",
    SEE_ALL: "SEE_ALL",
    SELECT_A_SUPPLIER_FIRST: "SELECT_A_SUPPLIER_FIRST",
    SELECT_BASE_FOR_KPI: "SELECT_BASE_FOR_KPI",
    SELECT_CHECKLIST_TEMPLATE: "SELECT_CHECKLIST_TEMPLATE",
    SELECT_FILE: "SELECT_FILE...",
    SELECT_FROM_LIBRARY: "SELECT_FROM_LIBRARY",
    SELECT_IMPROVEMENT_FORM: "SELECT_IMPROVEMENT_FORM",
    SELECT_ITEM_TO_VIEW_THIS_FIELD: "SELECT_ITEM_TO_VIEW_THIS_FIELD",
    SELECT_KPI_TYPE: "SELECT_KPI_TYPE",
    SELECT_KPI: "SELECT_KPI",
    SELECT_LATEST_VERSION: "SELECT_LATEST_VERSION",
    SELECT_STEPS_FOR_TEMPLATE: "SELECT_STEPS_FOR_TEMPLATE",
    SELECT_TEAMS: "SELECT_TEAMS",
    SELECT_TEMPLATE: "SELECT_TEMPLATE",
    SELECT_USER_TO_SHOW_PROFILE: "SELECT_WHO_TO_SHOW_PROFILE",
    SELECT_USERS: "SELECT_USERS",
    SELECT: "SELECT",
    SELECTED_OPTION_WAS_DELETED: "SELECTED_OPTION_WAS_DELETED",
    SELECTED_SCHEDULE_PREVIEW: "SELECTED_SCHEDULE_PREVIEW",
    SELECTED_WEEK_DAYS_: "SELECTED_WEEK_DAYS_",
    SEND_TEST_REQUEST: "SEND_TEST_REQUEST",
    SEND: "SEND",
    SEP: "SEP",
    SEPTEMBER: "SEPTEMBER",
    SET_RESPONSIBLE_TIP1: "SET_RESPONSIBLE_TIP1",
    SET_RESPONSIBLE: "SET_RESPONSIBLE",
    SET_TARGETS_TIP1: "SET_TARGETS_TIP1",
    SET_TARGETS_TIP2: "SET_TARGETS_TIP2",
    SET_TARGETS: "SET_TARGETS",
    SETTINGS: "SETTINGS",
    SHARED_STEP: "SHARED_STEP",
    SHARED_TEMPLATE_STEPS_ONCE_FEATURE_DESCRIPTION: "SHARED_TEMPLATE_STEPS_ONCE_FEATURE_DESCRIPTION",
    SHARED: "SHARED",
    SHIPPING_COUNTRY: "SHIPPING_COUNTRY",
    SHIPPING_STATE_OR_PROVINCE: "SHIPPING_STATE_OR_PROVINCE",
    SHIPPING: "SHIPPING",
    SHOW_ALL: "SHOW_ALL",
    SHOW_ARCHIVED: "SHOW_ARCHIVED",
    SHOW_COMPLETED: "SHOW_COMPLETED",
    SHOW_DRAFT: "SHOW_DRAFT",
    SHOW_DRAFTS: "SHOW_DRAFTS",
    SHOW_IN_LIST: "SHOW_IN_LIST",
    SHOW_IN_PROCESS_CHART: "SHOW_IN_PROCESS_CHART",
    SHOW_LIST: "SHOW_LIST",
    SHOW_PUBLISHED: "SHOW_PUBLISHED",
    SHOW_REFERENCED_PROPERTY_IN_PARENT_LIST: "SHOW_REFERENCED_PROPERTY_IN_PARENT_LIST",
    SHOW_SIDEBAR: "SHOW_SIDEBAR",
    SHOWING_ALL_EXCEPT_ARCHIVED_OR_SCHEDULED: "SHOWING_ALL_EXCEPT_ARCHIVED_OR_SCHEDULED",
    SHOWING_ALL_EXCEPT_SCHEDULED: "SHOWING_ALL_EXCEPT_SCHEDULED",
    SHOWING_IMPROVEMENTS_FOR: "SHOWING_IMPROVEMENTS_FOR",
    SHOWING_RECORDS: "SHOWING_RECORDS",
    SHOWING: "SHOWING",
    SIGNIFICANT_ENVIRONMENTAL_ASPECT: "SIGNIFICANT_ENVIRONMENTAL_ASPECT",
    SINGLE_AD_HOC_CHECKLIST: "SINGLE_AD_HOC_CHECKLIST",
    SO_FAR: "SO_FAR",
    SORTABLE: "SORTABLE",
    SPECIFIC_CUSTOMER_S_WHO_REQUIRE_THIS: "SPECIFIC_CUSTOMER_S_WHO_REQUIRE_THIS",
    SPLIT: "SPLIT",
    SSO_FEATURE_DESCRIPTION: "SSO_FEATURE_DESCRIPTION",
    SSO_PAGE_TITLE: "SSO_PAGE_TITLE",
    SSO: "SSO",
    STAFF_APPRAISAL_FEATURE_DESCRIPTION: "STAFF_APPRAISAL_FEATURE_DESCRIPTION",
    STAFF_APPRAISAL_PAGE_SUBTITLE: "STAFF_APPRAISAL_PAGE_SUBTITLE",
    STAFF_APPRAISAL_PAGE_TITLE: "STAFF_APPRAISAL_PAGE_TITLE",
    STAKEHOLDERS_EDITOR_INFO: "STAKEHOLDERS_EDITOR_INFO",
    STAKEHOLDERS_EDITOR: "STAKEHOLDERS_EDITOR",
    STAKEHOLDERS_FEATURE_DESCRIPTION: "STAKEHOLDERS_FEATURE_DESCRIPTION",
    STAKEHOLDERS_OWNER_INFO: "STAKEHOLDERS_OWNER_INFO",
    STAKEHOLDERS_OWNER: "STAKEHOLDERS_OWNER",
    STAKEHOLDERS_SUBTITLE: "STAKEHOLDERS_SUBTITLE",
    STAKEHOLDERS_VIEWER_INFO: "STAKEHOLDERS_VIEWER_INFO",
    STAKEHOLDERS_VIEWER: "STAKEHOLDERS_VIEWER",
    STAKEHOLDERS: "STAKEHOLDERS",
    STANDARD: "STANDARD",
    START_CHECKLIST: "START_CHECKLIST",
    START_DATE_GREATER_THAN_END_DATE: "START_DATE_GREATER_THAN_END_DATE",
    START_DATE_TIME: "START_DATE_TIME",
    START_DATE: "START_DATE",
    START_FIRST_RUN_AT_HINT_MESSAGE: "START_FIRST_RUN_AT_HINT_MESSAGE",
    START_FIRST_RUN_AT: "START_FIRST_RUN_AT",
    START_MY_FREE_TRAIL: "START_MY_FREE_TRAIL",
    START_THIS: "START_THIS",
    START_TYPING_TO_SELECT_OR_CREATE: "START_TYPING_TO_SELECT_OR_CREATE",
    START_VALUE: "START_VALUE",
    START_YOUR_FREE_14DAY_TRAIL: "START_YOUR_FREE_14DAY_TRAIL",
    START: "START",
    STARTED_AT: "STARTED_AT",
    STARTED_BY: "STARTED_BY",
    STARTED: "STARTED",
    STARTING_: "STARTING_",
    STARTING: "STARTING",
    STARTS_AT: "STARTS_AT",
    STATE_OR_PROVINCE: "STATE_OR_PROVINCE",
    STATIC_ACTION_PLAN_INDEX: "STATIC_ACTION_PLAN_INDEX",
    STATIC_ACTION_PLAN_LABEL: "STATIC_ACTION_PLAN_LABEL",
    STATIC_ACTION_PLAN: "STATIC_ACTION_PLAN",
    STATIC_NUMBER_VALUE_IS_REQUIRED: "STATIC_NUMBER_VALUE_IS_REQUIRED",
    STATIC_NUMBERS: "STATIC_NUMBERS",
    STATUS_COMMENT: "STATUS_COMMENT",
    STATUS_LABEL: "STATUS_LABEL",
    STATUS_NAME: "STATUS_NAME",
    STATUS: "STATUS",
    STEP_CONFIGURATION: "STEP_CONFIGURATION",
    STEP_DELETED: "STEP_DELETED",
    STEP_DETAILS: "STEP_DETAILS",
    STEP_DRAFT_SAVED: "STEP_DRAFT_SAVED",
    STEP_FINALIZED_BY: "STEP_FINALIZED_BY",
    STEP_HISTORY: "STEP_HISTORY",
    STEP_LABEL: "STEP_LABEL",
    STEP_NAME_REQUIRED: "STEP_NAME_REQUIRED",
    STEP: "STEP",
    STEPS_DONE: "STEPS_DONE",
    STEPS_EDIT: "STEPS_EDIT",
    STEPS_REQUIRED: "STEPS_REQUIRED",
    STEPS_SUBTITLE: "STEPS_SUBTITLE",
    STEPS: "STEPS",
    STOP_EDITING: "STOP_EDITING",
    STOP_MATCHING_CHECKLISTS_LINE1: "STOP_MATCHING_CHECKLISTS_LINE1",
    STOP_MATCHING_CHECKLISTS_LINE2: "STOP_MATCHING_CHECKLISTS_LINE2",
    STOP_MATCHING_CHECKLISTS_QUESTION: "STOP_MATCHING_CHECKLISTS_QUESTION",
    STOP_MATCHING_CHECKLISTS: "STOP_MATCHING_CHECKLISTS",
    STOP_MULTIPLE_CHECKLISTS_AT_ONCE_FEATURE_DESCRIPTION: "STOP_MULTIPLE_CHECKLISTS_AT_ONCE_FEATURE_DESCRIPTION",
    STOP_MULTIPLE_CHECKLISTS_AT_ONCE: "STOP_MULTIPLE_CHECKLISTS_AT_ONCE",
    STOP: "STOP",
    STOPPED_: "STOPPED_",
    STOPPED_AT: "STOPPED_AT",
    STOPPED_BY: "STOPPED_BY",
    STOPPED_IN_THE_LAST_7_DAYS: "STOPPED_IN_THE_LAST_7_DAYS",
    STOPPED_P: "STOPPED_P",
    STOPPED: "STOPPED",
    SUB_GOAL_DELETED: "SUB_GOAL_DELETED",
    SUB_GOAL: "SUB_GOAL",
    SUB_GOALS: "SUB_GOALS",
    SUB_PROCESS_MARKED_DELETE: "SUB_PROCESS_MARKED_DELETE",
    SUB_PROCESS_NOT_FOUND: "SUB_PROCESS_NOT_FOUND",
    SUB_PROCESS: "SUB_PROCESS",
    SUBJECT_AREA: "SUBJECT_AREA",
    SUBMIT_IMPOVEMENT: "SUBMIT_IMPOVEMENT",
    SUBMIT_NOTICE_WITH_USER_TO_NOTIFY: "SUBMIT_NOTICE_WITH_USER_TO_NOTIFY",
    SUBMIT_NOTICE: "SUBMIT_NOTICE",
    SUBMIT: "SUBMIT",
    SUCCESS_MESSAGE: "SUCCESS_MESSAGE",
    SUGGESTED_ENVIRONMENTAL_TARGET: "SUGGESTED_ENVIRONMENTAL_TARGET",
    SUMMARY_OF_HOW_WE_MEET_THE_REQUIREMENT: "SUMMARY_OF_HOW_WE_MEET_THE_REQUIREMENT",
    SUMMARY_OF_LEGISLATION: "SUMMARY_OF_LEGISLATION",
    SUMMARY_OF_THE_REQUIREMENT_AND_OR_MOTIVATION_WHY_IT_IS_NOT_RELEVANT_TO_US: "SUMMARY_OF_THE_REQUIREMENT_AND_OR_MOTIVATION_WHY_IT_IS_NOT_RELEVANT_TO_US",
    SUMMARY: "SUMMARY",
    SUNDAY: "SUNDAY",
    SUPPLIER_DETAILS_SUBTITLE: "SUPPLIER_DETAILS_SUBTITLE",
    SUPPLIER_DETAILS: "SUPPLIER_DETAILS",
    SUPPLIER_ID: "SUPPLIER_ID",
    SUPPLIER_LABEL: "SUPPLIER_LABEL",
    SUPPLIER_PLACEHOLDER: "SUPPLIER_PLACEHOLDER",
    SUPPLIER: "SUPPLIER",
    SUPPLIERS_EDITOR_INFO: "SUPPLIERS_EDITOR_INFO",
    SUPPLIERS_EDITOR: "SUPPLIERS_EDITOR",
    SUPPLIERS_FEATURE_DESCRIPTION: "SUPPLIERS_FEATURE_DESCRIPTION",
    SUPPLIERS_OWNER_INFO: "SUPPLIERS_OWNER_INFO",
    SUPPLIERS_OWNER: "SUPPLIERS_OWNER",
    SUPPLIERS_SUBTITLE: "SUPPLIERS_SUBTITLE",
    SUPPLIERS_VIEWER_INFO: "SUPPLIERS_VIEWER_INFO",
    SUPPLIERS_VIEWER: "SUPPLIERS_VIEWER",
    SWEDISH_NAME: "SWEDISH_NAME",
    SWEDISH: "SWEDISH",
    SYSTEM_PROPERTIES_MESSAGE: "SYSTEM_PROPERTIES_MESSAGE",
    SYSTEM_PROPERTIES: "SYSTEM_PROPERTIES",
    SYSTEM: "SYSTEM",
    TABLE_VIEW_PER_TEMPLATE: "TABLE_VIEW_PER_TEMPLATE",
    TABLE_VIEW: "TABLE_VIEW",
    TAKE_PICTURE: "TAKE_PICTURE",
    TARGET_PREFERENCE: "TARGET_PREFERENCE",
    TARGET_UNIT: "TARGET_UNIT",
    TARGET_URL: "TARGET_URL",
    TARGET_VALUE: "TARGET_VALUE",
    TARGET: "TARGET",
    TARGETS: "TARGETS",
    TASK_COMPLETED: "TASK_COMPLETED",
    TASK_CREATED: "TASK_CREATED",
    TASK_INDEX: "TASK_INDEX",
    TASK_LABEL: "TASK_LABEL",
    TASK_LIST_INDEX: "TASK_LIST_INDEX",
    TASK_LIST_LABEL: "TASK_LIST_LABEL",
    TASK_LIST: "TASK_LIST",
    TASK_SUBTITLE: "TASK_SUBTITLE",
    TASK: "TASK",
    TASKS_ASSIGNED_TO_YOU: "TASKS_ASSIGNED_TO_YOU",
    TASKS_FEATURE_DESCRIPTION: "TASKS_FEATURE_DESCRIPTION",
    TASKS: "TASKS",
    TEAM_CAN_VIEW: "TEAM_CAN_VIEW",
    TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_CHECKLISTS: "TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_FOR_THESE_CHECKLISTS",
    TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_GOALS: "TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_FOR_THESE_GOALS",
    TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_KPIS: "TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_FOR_THESE_KPIS",
    TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_MEASUREMENTS: "TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_FOR_THESE_MEASUREMENTS",
    TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_PROCESSES: "TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_FOR_THESE_PROCESSES",
    TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_STEPS: "TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_FOR_THESE_STEPS",
    TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_SUBPROCESSES: "TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_FOR_THESE_SUBPROCESSES",
    TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_BY_THESE_TEMPLATES: "TEAM_CANNOT_BE_DELETED_AS_IT_IS_CURRENTLY_USED_FOR_THESE_TEMPLATES",
    TEAM_DELETED: "TEAM_DELETED",
    TEAM_DETAILS_SUBTITLE: "TEAM_DETAILS_SUBTITLE",
    TEAM_DETAILS: "TEAM_DETAILS",
    TEAM_IS_USED_BY_THESE_CHECKLISTS: "TEAM_IS_USED_FOR_THESE_CHECKLISTS",
    TEAM_IS_USED_BY_THESE_GOALS: "TEAM_IS_USED_FOR_THESE_GOALS",
    TEAM_IS_USED_BY_THESE_KPIS: "TEAM_IS_USED_FOR_THESE_KPIS",
    TEAM_IS_USED_BY_THESE_MEASUREMENTS: "TEAM_IS_USED_FOR_THESE_MEASUREMENTS",
    TEAM_IS_USED_BY_THESE_PROCESSES: "TEAM_IS_USED_FOR_THESE_PROCESSES",
    TEAM_IS_USED_BY_THESE_STEPS: "TEAM_IS_USED_FOR_THESE_STEPS",
    TEAM_IS_USED_BY_THESE_SUBPROCESSES: "TEAM_IS_USED_FOR_THESE_SUBPROCESSES",
    TEAM_IS_USED_BY_THESE_TEMPLATES: "TEAM_IS_USED_FOR_THESE_TEMPLATES",
    TEAM_REQUIRED: "TEAM_REQUIRED",
    TEAM_ROLES: "TEAM_ROLES",
    TEAM_SUBTITLE: "TEAM_SUBTITLE",
    TEAM_WILL_BE_REMOVED: "TEAM_WILL_BE_REMOVED",
    TEAM: "TEAM",
    TEAMS_EDITOR_INFO: "TEAMS_EDITOR_INFO",
    TEAMS_EDITOR: "TEAMS_EDITOR",
    TEAMS_OWNER_INFO: "TEAMS_OWNER_INFO",
    TEAMS_OWNER: "TEAMS_OWNER",
    TEAMS_VIEWER_INFO: "TEAMS_VIEWER_INFO",
    TEAMS_VIEWER: "TEAMS_VIEWER",
    TEAMS_WHO_CAN_UPDATE_TARGETS: "TEAMS_WHO_CAN_UPDATE_TARGETS",
    TEAMS_WHO_WILL_WORK_ON_THIS_STEP: "TEAMS_WHO_WILL_WORK_ON_THIS_STEP",
    TEAMS: "TEAMS",
    TEMPLATE_DELETE_DESCRIPTION: "TEMPLATE_DELETE_DESCRIPTION",
    TEMPLATE_DELETED: "TEMPLATE_DELETED",
    TEMPLATE_DETAILS: "TEMPLATE_DETAILS",
    TEMPLATE_FORM_ERRORS: "TEMPLATE_FORM_ERRORS",
    TEMPLATE_INFORMATION: "TEMPLATE_INFORMATION",
    TEMPLATE_LANGUAGE: "TEMPLATE_LANGUAGE",
    TEMPLATE_NAME_REQUIRED: "TEMPLATE_NAME_REQUIRED",
    TEMPLATE_NAME: "TEMPLATE_NAME",
    TEMPLATE_TO_SCHEDULE: "TEMPLATE_TO_SCHEDULE",
    TEMPLATE: "TEMPLATE",
    TEMPLATES_SUBTITLE: "TEMPLATES_SUBTITLE",
    TEMPLATES: "TEMPLATES",
    TENANT_NAME_ALREADY_EXISTS: "TENANT_NAME_ALREADY_EXISTS",
    TENANT_NAME_DESCRIPTION_NOTE: "TENANT_NAME_DESCRIPTION_NOTE",
    TENANT_NAME_DESCRIPTION: "TENANT_NAME_DESCRIPTION",
    TENANT_NAME: "TENANT_NAME",
    TENANT_SETTINGS_PAGE_SUBTITLE: "TENANT_SETTINGS_PAGE_SUBTITLE",
    TENANT_SETTINGS: "TENANT_SETTINGS",
    TEST_REQUEST: "TEST_REQUEST",
    TEXT_FILTER: "TEXT_FILTER",
    TEXT: "TEXT",
    THAT_: "THAT_",
    THAT: "THAT",
    THE_: "THE_",
    THE_USER_WONT_BE_NOTIFIED_ABOUT_THEIR_ACCOUNT_INFORMATION: "THE_USER_WONT_BE_NOTIFIED_ABOUT_THEIR_ACCOUNT_INFORMATION",
    THERE_ARE_UNSAVED_CHANGES_ARE_YOU_SURE_YOU_WANT_TO_LEAVE: "THERE_ARE_UNSAVED_CHANGES_ARE_YOU_SURE_YOU_WANT_TO_LEAVE",
    THERE_ARE_UNSAVED_CHANGES: "THERE_ARE_UNSAVED_CHANGES",
    THERE_IS_NO_UNDO: "THERE_IS_NO_UNDO",
    THIRD_: "THIRD_",
    THIRD: "THIRD",
    THIS_ACTIVITY_HAS: "THIS_ACTIVITY_HAS",
    THIS_IMPOVEMENT_IS_CLOSED: "THIS_IMPOVEMENT_IS_CLOSED",
    THIS_IS_CONTENT_OF_MOBILE_SIDEBAR: "THIS_IS_CONTENT_OF_MOBILE_SIDEBAR",
    THIS_MONTH: "THIS_MONTH",
    THIS_REPORT_WAS_GENERATED_BY: "THIS_REPORT_WAS_GENERATED_BY",
    THIS_STEP_IS_BLOCKED_BY_PRECEDING_STEP_YOU_DONT_HAVE_PERMISSION_TO_SEE: "THIS_STEP_IS_BLOCKED_BY_PRECEDING_STEP_YOU_DONT_HAVE_PERMISSION_TO_SEE",
    THIS_STEP_IS_BLOCKED_BY_PRECEDING_STEP: "THIS_STEP_IS_BLOCKED_BY_PRECEDING_STEP",
    THIS_STEP_IS_BLOCKING_ALL_FOLLOWING_STEPS_UNTIL_IT_HAS_BEEN_FINALIZED: "THIS_STEP_IS_BLOCKING_ALL_FOLLOWING_STEPS_UNTIL_IT_HAS_BEEN_FINALIZED",
    THIS_STEP_IS_COMPLETED: "THIS_STEP_IS_COMPLETED",
    THIS_STEP_IS_FINALIZED: "THIS_STEP_IS_FINALIZED",
    THIS_WEEK: "THIS_WEEK",
    THRESHOLD_TYPE: "THRESHOLD_TYPE",
    THRESHOLD: "THRESHOLD",
    THURSDAY: "THURSDAY",
    TIME: "TIME",
    TIMEFRAME_DESCRIPTION: "TIMEFRAME_DESCRIPTION",
    TIMEFRAME: "TIMEFRAME",
    TIMELINE_VIEW_PER_TEMPLATE: "TIMELINE_VIEW_PER_TEMPLATE",
    TIMELINE_VIEW: "TIMELINE_VIEW",
    TIPS: "TIPS",
    TITLE: "TITLE",
    TO_: "TO_",
    TO_ADD_NEW_ONE_: "TO_ADD_NEW_ONE_",
    TO_COMPLETE: "TO_COMPLETE",
    TO_START_CLICK_ON_ADD_NEW_BUTTON: "TO_START_CLICK_ON_ADD_NEW_BUTTON",
    TO: "TO",
    TODAY: "TODAY",
    TOMORROW: "TOMORROW",
    TOS: "TOS",
    TOTAL_IMPACT_RATING: "TOTAL_IMPACT_RATING",
    TOTAL_VALUES_FOR_ALL: "TOTAL_VALUES_FOR_ALL",
    TOTAL: "TOTAL",
    TRAINING_ACTIVITY: "TRAINING_ACTIVITY",
    TRAINING_PLAN_FEATURE_DESCRIPTION: "TRAINING_PLAN_FEATURE_DESCRIPTION",
    TRAINING_PLAN_PAGE_SUBTITLE: "TRAINING_PLAN_PAGE_SUBTITLE",
    TRAINING_PLAN_PAGE_TITLE: "TRAINING_PLAN_PAGE_TITLE",
    TRAINING_PLANS_EDITOR_INFO: "TRAINING_PLANS_EDITOR_INFO",
    TRAINING_PLANS_EDITOR: "TRAINING_PLANS_EDITOR",
    TRAINING_PLANS_OWNER_INFO: "TRAINING_PLANS_OWNER_INFO",
    TRAINING_PLANS_OWNER: "TRAINING_PLANS_OWNER",
    TRAINING_PLANS_VIEWER_INFO: "TRAINING_PLANS_VIEWER_INFO",
    TRAINING_PLANS_VIEWER: "TRAINING_PLANS_VIEWER",
    TRY_ALL_FEATURES_FOR_FREE_NO_CREDIT_CARD_REQUIRED: "TRY_ALL_FEATURES_FOR_FREE_NO_CREDIT_CARD_REQUIRED",
    TRY_DIFFERENT_FILTER_KEYWORDS: "TRY_DIFFERENT_FILTER_KEYWORDS",
    TUESDAY: "TUESDAY",
    TYPE_OF_INTEREST: "TYPE_OF_INTEREST",
    TYPE_OF_LEGISLATION: "TYPE_OF_LEGISLATION",
    TYPE: "TYPE",
    UN_ARCHIVE_IMPACT_GRADING: "UN_ARCHIVE_IMPACT_GRADING",
    UN_ARCHIVE_POSITION: "UN_ARCHIVE_POSITION",
    UN_ARCHIVE: "UN_ARCHIVE",
    UN_ASSIGN_USERS_STEP_INSTANCE_BODY1: "UN_ASSIGN_USERS_STEP_INSTANCE_BODY1",
    UN_ASSIGN_USERS_STEP_INSTANCE_BODY2: "UN_ASSIGN_USERS_STEP_INSTANCE_BODY2",
    UN_ASSIGN_USERS_STEP_INSTANCE_TITLE: "UN_ASSIGN_USERS_STEP_INSTANCE_TITLE",
    UN_ASSIGN_USERS_STEP_INSTANCE_YES: "UN_ASSIGN_USERS_STEP_INSTANCE_YES",
    UNARCHIVE_THIS_AREA: "UNARCHIVE_THIS_AREA",
    UNARCHIVE_THIS_GOAL: "UNARCHIVE_THIS_GOAL",
    UNARCHIVE_THIS_KPI: "UNARCHIVE_THIS_KPI",
    UNARCHIVE_THIS_MEASUREMENT: "UNARCHIVE_THIS_MEASUREMENT",
    UNARCHIVE_THIS_POSITION: "ARCHIVE_THIS_POSITION",
    UNARCHIVE_THIS_PROGRESS_VALUE: "UNARCHIVE_THIS_PROGRESS_VALUE",
    UNASSIGNED: "UNASSIGNED",
    UNCATEGORIESED: "UNCATEGORIESED",
    UNDER_TARGET_IS_GOOD: "UNDER_TARGET_IS_GOOD",
    UNDER_THRESHOLD: "UNDER_THRESHOLD",
    UNDO: "UNDO",
    UNFINALIZATION_OF_STEPS_FEATURE_DESCRIPTION: "UNFINALIZATION_OF_STEPS_FEATURE_DESCRIPTION",
    UNFINALIZATION_OF_STEPS: "UNFINALIZATION_OF_STEPS",
    UNFINALIZE_STEP_CONFIRMATION_MESSAGE: "UNFINALIZE_STEP_CONFIRMATION_MESSAGE",
    UNFINALIZE: "UNFINALIZE",
    UNFINALIZED_STEP_WITH_REASON: "UNFINALIZED_STEP_WITH_REASON",
    UNIT: "UNIT",
    UNKNOWN: "UNKNOWN",
    UNTIL: "UNTIL",
    UPCOMING_STEPS: "UPCOMING_STEPS",
    UPDATE_AVAILABLE: "UPDATE_AVAILABLE",
    UPDATE_END_DATE: "UPDATE_END_DATE",
    UPDATE_PROGRESS: "UPDATE_PROGRESS",
    UPDATE_SUCCESSEDED: "UPDATE_SUCCESSEDED",
    UPDATE_VALUE: "UPDATE_VALUE",
    UPDATED_AT: "UPDATED_AT",
    UPDATED_BY: "UPDATED_BY",
    UPDATED_PROBABILITY: "UPDATED_PROBABILITY",
    UPDATED_RISK_SCORE: "UPDATED_RISK_SCORE",
    UPDATED: "UPDATED",
    UPDATES: "UPDATES",
    UPGRADE_CHECKLISTS_TITLE: "UPGRADE_CHECKLISTS_TITLE",
    UPLOAD_PHOTO: "UPLOAD_PHOTO",
    UPLOAD: "UPLOAD",
    UPLOADED_FILES_FOR_THE_RUN: "UPLOADED_FILES_FOR_THE_RUN",
    URL: "URL",
    USE_SHIPPING_ADDRESS: "USE_SHIPPING_ADDRESS",
    USE: "USE",
    USER_ALLOW_COMPLETE_OTHES_TASKS: "USER_ALLOW_COMPLETE_OTHES_TASKS",
    USER_ALLOW_SELECT_MULTI: "USER_ALLOW_SELECT_MULTI",
    USER_CAN_VIEW: "USER_CAN_VIEW",
    USER_DEFINED: "USER_DEFINED",
    USER_DETAILS_SUBTITLE: "USER_DETAILS_SUBTITLE",
    USER_DETAILS: "USER_DETAILS",
    USER_EDITOR_INFO: "USER_EDITOR_INFO",
    USER_EDITOR: "USER_EDITOR",
    USER_EMAIL: "USER_EMAIL",
    USER_ID: "USER_ID",
    USER_NAME: "USER_NAME",
    USER_OWNER_INFO: "USER_OWNER_INFO",
    USER_OWNER: "USER_OWNER",
    USER_ROLES: "USER_ROLES",
    USER_TASKS_FEATURE_DESCRIPTION: "USER_TASKS_FEATURE_DESCRIPTION",
    USER_TASKS: "USER_TASKS",
    USER_VIEWER_INFO: "USER_VIEWER_INFO",
    USER_VIEWER: "USER_VIEWER",
    USER: "USER",
    USERS_AND_TEAMS_WITH_ACCESS: "USERS_AND_TEAMS_WITH_ACCESS",
    USERS_ASSIGNED_TO_AN_ACTIVITY: "USERS_ASSIGNED_TO_AN_ACTIVITY",
    USERS_CANNOT_BE_NOTIFIED_AND_NOT_NOTIFIED_AT_THE_SAME_TIME: "USERS_CANNOT_BE_NOTIFIED_AND_NOT_NOTIFIED_AT_THE_SAME_TIME",
    USERS_IN_THE_SYSTEM: "USERS_IN_THE_SYSTEM",
    USERS_REPONSIBLE_FOR_THE_SELECTED_DISCOVERED_IN_SUB_PROCESS: "USERS_REPONSIBLE_FOR_THE_SELECTED_DISCOVERED_IN_SUB_PROCESS",
    USERS_REPONSIBLE_FOR_THE_SELECTED_ORIGINATED_IN_PROCESS_STEP: "USERS_REPONSIBLE_FOR_THE_SELECTED_ORIGINATED_IN_PROCESS_STEP",
    USERS_REPONSIBLE_FOR_THE_SELECTED_ORIGINATED_IN_PROCESS: "USERS_REPONSIBLE_FOR_THE_SELECTED_ORIGINATED_IN_PROCESS",
    USERS_REPONSIBLE_FOR_THE_SELECTED_ORIGINATED_IN_SUB_PROCESS: "USERS_REPONSIBLE_FOR_THE_SELECTED_ORIGINATED_IN_SUB_PROCESS",
    USERS_RESPONSIBLE_FOR_THE_SELECTED_DISCOVERED_IN_PROCESS_STEP: "USERS_RESPONSIBLE_FOR_THE_SELECTED_DISCOVERED_IN_PROCESS_STEP",
    USERS_RESPONSIBLE_FOR_THE_SELECTED_DISCOVERED_IN_PROCESS: "USERS_RESPONSIBLE_FOR_THE_SELECTED_DISCOVERED_IN_PROCESS",
    USERS_SUBTITLE: "USERS_SUBTITLE",
    USERS_WHO_CAN_UPDATE_TARGETS: "USERS_WHO_CAN_UPDATE_TARGETS",
    USERS: "USERS",
    VALIDATE: "VALIDATE",
    VALUE_IS_BETWEEN_THRESHOLDS_OF: "VALUE_IS_BETWEEN_THRESHOLDS_OF",
    VALUE_IS_BETWEEN: "VALUE_IS_BETWEEN",
    VALUE_IS_OVER_THE_THRESHOLD_OF: "VALUE_IS_OVER_THE_THRESHOLD_OF",
    VALUE_IS_UNDER_THE_THRESHOLD_OF: "VALUE_IS_UNDER_THE_THRESHOLD_OF",
    VALUE: "VALUE",
    VALUES_COMMA_SEPERATED_LIKE_A_B_C: "VALUES_COMMA_SEPERATED_LIKE_A_B_C",
    VARIABLE_NAME_REQUIRED: "VARIABLE_NAME_REQUIRED",
    VARIABLES_WITH_MANUAL_DATA_SOURCE: "VARIABLES_WITH_MANUAL_DATA_SOURCE",
    VARIABLES: "VARIABLES",
    VAT_NUMBER: "VAT_NUMBER",
    VERIFY_AND_CLOSE: "VERIFY_AND_CLOSE",
    VERIFY: "VERIFY",
    VERIFYING: "VERIFYING",
    VERSION: "VERSION",
    VIEW_ALL_PROGRESSES: "VIEW_ALL_PROGRESSES",
    VIEW_ALL: "VIEW_ALL",
    VIEW_AND_EDIT: "VIEW_AND_EDIT",
    VIEW_AS_INFO: "VIEW_AS_INFO",
    VIEW_AS: "VIEW_AS",
    VIEW_BARCODE: "VIEW_BARCODE",
    VIEW_CHECKLISTS_THAT_CANT_BE_UPGRADED: "VIEW_CHECKLISTS_THAT_CANT_BE_UPGRADED",
    VIEW_GOAL: "VIEW_GOAL",
    VIEW_KPI: "VIEW_KPI",
    VIEW_MEASUREMENT: "VIEW_MEASUREMENT",
    VIEW_PHOTO: "VIEW_PHOTO",
    VIEW_PURCHASE_ORDER_DETAILS_SUBTITLE: "VIEW_PURCHASE_ORDER_DETAILS_SUBTITLE",
    VIEW_PURCHASE_ORDER_DETAILS: "VIEW_PURCHASE_ORDER_DETAILS",
    VIEW_SUB_GOAL: "VIEW_SUB_GOAL",
    VIEW: "VIEW",
    VIEWER: "VIEWER",
    VISIT: "VISIT",
    VISUAL_SCHEDULE: "VISUAL_SCHEDULE",
    WAITING_MESSAGE: "WAITING_MESSAGE",
    WANT_TO_CLOSE_THIS_IMPROVEMENT: "WANT_TO_CLOSE_THIS_IMPROVEMENT",
    WEBHOOK_ADDED_MESSAGE: "WEBHOOK_ADDED_MESSAGE",
    WEBHOOK_DELETED: "WEBHOOK_DELETED",
    WEBHOOK_DETAILS: "WEBHOOK_DETAILS",
    WEBHOOK_DISABLED_MESSAGE: "WEBHOOK_DISABLED_MESSAGE",
    WEBHOOK_ENABLED_MESSAGE: "WEBHOOK_ENABLED_MESSAGE",
    WEBHOOK_ENABLED: "WEBHOOK_ENABLED",
    WEBHOOK_EVENT_PAGE_SUBTITLE: "WEBHOOK_EVENT_PAGE_SUBTITLE",
    WEBHOOK_EVENT_PAGE_TITLE: "WEBHOOK_EVENT_PAGE_TITLE",
    WEBHOOK_EVENTS: "WEBHOOK_EVENTS",
    WEBHOOK_FEATURE_DESCRIPTION: "WEBHOOK_FEATURE_DESCRIPTION",
    WEBHOOK_PAGE_SUBTITLE: "WEBHOOK_PAGE_SUBTITLE",
    WEBHOOK_PAGE_TITLE: "WEBHOOK_PAGE_TITLE",
    WEBHOOK_TITLE: "WEBHOOK_TITLE",
    WEBHOOK_UPDATED_MESSAGE: "WEBHOOK_UPDATED_MESSAGE",
    WEBHOOKS_FOR_THIS_STEP: "WEBHOOKS_FOR_THIS_STEP",
    WEBSITE: "WEBSITE",
    WEDNESDAY: "WEDNESDAY",
    WEEK_S: "WEEK_S",
    WEEKLY: "WEEKLY",
    WEEKS_: "WEEKS_",
    WELCOME_BACK: "WELCOME_BACK",
    WELL_BEING: "WELL_BEING",
    WHAT_IF: "WHAT_IF",
    WHAT_IS_THE_IMPACT_FOR: "WHAT_IS_THE_IMPACT_FOR",
    WHAT: "WHAT",
    WHEN_A_NEW_SUPPLIER_IS_CREATED_SELECTED_ITEMS_WILL_RESET_MESSAGE: "WHEN_A_NEW_SUPPLIER_IS_CREATED_SELECTED_ITEMS_WILL_RESET_MESSAGE",
    WHEN_YOU_UN_ARCHIVE_A_EQUIPMENT_IT_WILL_BE_RESTORED_AND_SHOWN_UP_FOR_USER: "WHEN_YOU_UN_ARCHIVE_A_EQUIPMENT_IT_WILL_BE_RESTORED_AND_SHOWN_UP_FOR_USER",
    WHEN_YOU_UN_ARCHIVE_A_TEAM_IT_WILL_BE_RESTORED_AND_SHOW_UP_FOR_USERS: "WHEN_YOU_UN_ARCHIVE_A_TEAM_IT_WILL_BE_RESTORED_AND_SHOW_UP_FOR_USERS",
    WHICH_ARE_SCHEDULED_AND_HAVE_NOT_STARTED_YET: "WHICH_ARE_SCHEDULED_AND_HAVE_NOT_STARTED_YET",
    WHO: "WHO",
    WHOLE_NUMBER: "WHOLE_NUMBER",
    WHY_GOAL_AT_RISK: "WHY_GOAL_AT_RISK",
    WHY_GOAL_BLOCKED: "WHY_GOAL_BLOCKED",
    WHY_MEASUREMENT_AT_RISK: "WHY_MEASUREMENT_AT_RISK",
    WHY_MEASUREMENT_BLOCKED: "WHY_MEASUREMENT_BLOCKED",
    WHY_NOT_ADD_SOME: "WHY_NOT_ADD_SOME",
    WHY: "WHY",
    WILL_BE_CREATED_OR_UNARCHIVED_WITH_FOLLOWING_BREAKDOWN_OF_RUN_STATUS: "WILL_BE_CREATED_OR_UNARCHIVED_WITH_FOLLOWING_BREAKDOWN_OF_RUN_STATUS",
    WILL_BE_REMOVED_OR_ARCHIVED_WITH_FOLLOWING_BREAKDOWN_OF_RUN_STATUS: " WILL_BE_REMOVED_OR_ARCHIVED_WITH_FOLLOWING_BREAKDOWN_OF_RUN_STATUS",
    WILL_MAKE_THE_SCHEDULE_CONTAIN: "WILL_MAKE_THE_SCHEDULE_CONTAIN",
    WORKDAY: "WORKDAY",
    WOULD_YOU_LIKE_TO_PROCEED_WITH_THE_UPGRADE: "WOULD_YOU_LIKE_TO_PROCEED_WITH_THE_UPGRADE",
    WRITE_A_COMMENT_THAT_IS_AS_LONG_AS_YOU_WANT_IT_TO_BE: "WRITE_A_COMMENT_THAT_IS_AS_LONG_AS_YOU_WANT_IT_TO_BE",
    WRITE_A_DESCRIPTION_FOR_THIS_IMPROVEMENT_FORM: "WRITE_A_DESCRIPTION_FOR_THIS_IMPROVEMENT_FORM",
    WRITE_A_DESCRIPTION_FOR_THIS_STEP: "WRITE_A_DESCRIPTION_FOR_THIS_STEP",
    WRITE_A_SUMMARY_FOR_TEMPLATE: "WRITE_A_SUMMARY_FOR_TEMPLATE",
    WWW_AMPLIFLOW_COM: "WWW_AMPLIFLOW_COM",
    YEAR_S_: "YEAR_S_",
    YEAR_S: "YEAR_S",
    YEAR: "YEAR",
    YEARLY: "YEARLY",
    YELLOW: "YELLOW",
    YES_CONTINUE: "YES_CONTINUE",
    YES: "YES",
    YESTERDAY: "YESTERDAY",
    YOU_ARE_NOT_ABLE_TO_COPY_UNTIL_PUBLISH: "YOU_ARE_NOT_ABLE_TO_COPY_UNTIL_PUBLISH",
    YOU_CAN_ADD_STATIC_OR_TASK_LIST_ACTION_PLAN: "YOU_CAN_ADD_STATIC_OR_TASK_LIST_ACTION_PLAN",
    YOU_CAN_ADD_SUBGOALS_FOR_ANY_GOAL: "YOU_CAN_ADD_SUBGOALS_FOR_ANY_GOAL",
    YOU_CAN_ADD_TWO_TYPES_OF_ACTION_PLAN: "YOU_CAN_ADD_TWO_TYPES_OF_ACTION_PLAN",
    YOU_SHOULD_BE_PART_OF_THIS_TEAM_TO_SEE_THE_CHECKLISTS: "YOU_SHOULD_BE_PART_OF_THIS_TEAM_TO_SEE_THE_CHECKLISTS",
    YOUR_ROLES: "YOUR_ROLES",
    YOUR_TEAMS: "YOUR_TEAMS",
};
