import "./MenuButton.scss";

import React, { useMemo, useRef } from "react";

import { Dropdown } from "react-overlays";
import { DropDirection } from "react-overlays/DropdownContext";

import classNames from "classnames";

import { IMenuProps } from "./components/IMenuProps";
import MenuList from "./components/MenuList";
import Toggle from "./components/Toggle";

interface IProps extends IMenuProps {
    id?: string;
    disabled?: boolean;
    alignEnd?: boolean;
    drop?: DropDirection;
    className?: string;
    label?: React.ReactNode;
    icon?: React.ReactNode;
    background?: "dark" | "light";
    onToggle?: (nextShow: boolean) => void;
}

const MenuButton: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const {
        id,
        testId,
        options,
        className,
        label,
        icon,
        header,
        multiSelect,
        showCheckbox,
        disabled,
        alignEnd,
        drop,
        background,
    } = props;

    const wrapperRef = useRef<HTMLDivElement>(null);

    const handleDropdownToggle = (nextShow: boolean, e?: any) => {
        if (props.onToggle) {
            props.onToggle(nextShow);
        }
    };

    const wrapperClassName = classNames("menu-button", className);

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const toggleClassName = classNames({
        "toggle-with-label": label,
        "toggle-without-label icon-bordered": icon && !label,
        [`background-${background}`]: background,
    });

    return (
        <div
            className={wrapperClassName}
            onClick={handleClick}
            ref={wrapperRef}
            data-testid={testId}
        >
            <Dropdown
                onToggle={handleDropdownToggle}
                alignEnd={alignEnd ?? true}
                drop={drop}
            >
                <Toggle
                    id={id}
                    testId={testId}
                    disabled={disabled}
                    className={toggleClassName}
                    wrapperRef={wrapperRef}
                    label={label}
                    icon={icon}
                />

                <MenuList
                    testId={testId}
                    header={header}
                    multiSelect={multiSelect}
                    showCheckbox={showCheckbox}
                    options={options}
                    onSelect={props.onSelect}
                />
            </Dropdown>
        </div>
    );
};

export default MenuButton;
