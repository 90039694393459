import "./MeatballsIcon.scss";

import { PropsWithChildren } from "react";

import classNames from "classnames";

import Icon from "./assets/meatballs-icon.svg";

interface IProps {
    variant?: "white" | "blue" | "dark-blue";
}

const MeatballsIcon = (props: PropsWithChildren<IProps>) => {
    const { variant } = props;

    const className = classNames("meatballs-icon", {
        [`meatballs-icon--${variant}`]: variant,
    });

    return <Icon className={className} />;
};

export default MeatballsIcon;
