import "./ProcessStepNodeIcon.scss";

import React from "react";

import Icon from "./assets/process-step-node-icon.svg";

interface IProps {}

const ProcessStepNodeIcon: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    return <Icon />;
};

export default ProcessStepNodeIcon;
