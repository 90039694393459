import "./FullScreenIcon.scss";

import React from "react";
import Icon from "./assets/full-screen-icon.svg";

interface IProps {
    fitPath?: boolean;
}

const FullScreenIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    const { fitPath } = props;
    if (fitPath) {
        return <Icon className="full-screen-icon" viewBox="2 2 20 20" />;
    }
    return <Icon className="full-screen-icon" />;
};

export default FullScreenIcon;
