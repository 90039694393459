import "./TripleDotsIcon.scss";

import React, { PropsWithChildren } from "react";

import classNames from "classnames";

import Icon from "./assets/triple-dots-icon.svg";

interface IProps {
    variant?: "white" | "blue" | "dark-blue";
}

const TripleDotsIcon = (props: PropsWithChildren<IProps>) => {
    const { variant } = props;

    const className = classNames("triple-dots-icon", {
        [`triple-dots-icon--${variant}`]: variant,
    });

    return <Icon className={className} />;
};

export default TripleDotsIcon;
