import "./ProcessNodeIcon.scss";

import React from "react";

import Icon from "./assets/process-node-icon.svg";

interface IProps {}

const ProcessNodeIcon: React.FC<React.PropsWithChildren<IProps>> = (props) => {
    return <Icon />;
};

export default ProcessNodeIcon;
