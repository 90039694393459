import "./MenuList.scss";

import { Spinner } from "react-bootstrap";
import { useDropdownMenu } from "react-overlays";

import { IMenuProps } from "./IMenuProps";
import { CheckmarkIcon } from "common/components/icons";

const MenuList = (props: IMenuProps) => {
    const { header, options, multiSelect, showCheckbox, testId } = props;

    const menuOptions = useDropdownMenu({
        flip: true,
        offset: [0, 5],
    });

    const handleMenuItemSelect = (e: any) => {
        if (!multiSelect) {
            if (menuOptions[1].toggle) {
                menuOptions[1].toggle(false, e);
            }
        }

        if (props.onSelect) {
            const selectedId: string = e.target.dataset.id;

            props.onSelect(selectedId ?? "");
        }
    };

    const listItemWithCheckbox = multiSelect || showCheckbox;

    return (
        <ul
            {...menuOptions[0]}
            className={
                menuOptions[1].show ? "menu-button--menu-list" : "d-none"
            }
            data-testid={testId + "-list"}
        >
            {header && (
                <li className="disabled">
                    <small>{header}</small>
                </li>
            )}

            {options?.map((x) =>
                x.disabled ? (
                    <li
                        key={x.id}
                        data-testid={x.testId ?? x.id}
                        className="disabled"
                    >
                        {x.label}
                    </li>
                ) : (
                    <li
                        key={x.id}
                        data-testid={x.testId ?? x.id}
                        className={
                            listItemWithCheckbox ? "with-checkbox" : undefined
                        }
                        data-id={x.id}
                        onClick={handleMenuItemSelect}
                    >
                        {x.label}

                        {x.isBusy ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="menu-icon"
                            />
                        ) : (
                            x.selected && <CheckmarkIcon />
                        )}
                    </li>
                ),
            )}
        </ul>
    );
};

export default MenuList;
