import "./PageIcon.scss";

import { IoDocument } from "react-icons/io5";

import classNames from "classnames";

interface IPageIconProps {
    className?: string;
}

const PageIcon = (props: IPageIconProps) => {
    const className = classNames("page-icon", props.className);
    return <IoDocument className={className} />;
};

export default PageIcon;
