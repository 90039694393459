import "./SubProcessNodeIcon.scss";

import React from "react";

import Icon from "./assets/sub-process-node-icon.svg";

interface IProps {}

const SubProcessNodeIcon: React.FC<React.PropsWithChildren<IProps>> = (
    props,
) => {
    return <Icon />;
};

export default SubProcessNodeIcon;
