import "./StarIcon.scss";

import React from "react";
import { FaStar } from "react-icons/fa";

const StarIcon: React.FC = (props) => {
    return <FaStar className="icon-star" />;
};

export default StarIcon;
