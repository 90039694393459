import "./ActivityIcon.scss";

import React from "react";

import Icon from "./assets/activity-icon.svg";

const ActivityIcon: React.FC = () => {
    return <Icon className="activity-icon" />;
};

export default ActivityIcon;
