import "./FolderIcon.scss";

import { FaFolder, FaFolderOpen, FaFolderPlus } from "react-icons/fa";

import classNames from "classnames";

interface IFolderIconProps {
    variant?: "normal" | "opened" | "add";
    className?: string;
}

const FolderIcon = (props: IFolderIconProps) => {
    const { variant = "normal" } = props;
    const className = classNames("folder-icon", props.className);

    switch (variant) {
        case "opened":
            return <FaFolderOpen className={className} />;
        case "add":
            return <FaFolderPlus className={className} />;
        case "normal":
        default:
            return <FaFolder className={className} />;
    }
};

export default FolderIcon;
