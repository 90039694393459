import "./ResizePCIcon.scss";

import React from "react";
import Icon from "./assets/resize-pc-icon.svg";

const ResizePCIcon: React.FC = (props) => {
    return <Icon className="resize-pc-icon" />;
};

export default ResizePCIcon;
