import "./ArrowUpIcon.scss";

import Icon from "./assets/arrow-up-icon.svg";

interface IArrowUpIconProps {
    className?: string;
}

const ArrowUpIcon = ({ className }: IArrowUpIconProps) => {
    return (
        <Icon className={`arrow-up-icon${className ? " " + className : ""}`} />
    );
};

export default ArrowUpIcon;
